// IMPORT PACKAGE REFERENCES

import { combineReducers } from 'redux';


// IMPORT REDUCERS

import {UserReducer} from './User/reducer';

// EXPORT APP REDUCER

export const AppReducer = combineReducers({
    user: UserReducer,
});