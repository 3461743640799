// IMPORT PACKAGE REFERENCES

import { createStore } from 'redux';
import { LoadState } from './LocalStorage';
import { AppReducer } from './reducers/AppReducer';

const persistedState = LoadState();


export const createAppStore = () => {
    // return createStore(AppReducer, applyMiddleware(thunk, promiseMiddleware()));
    return createStore(AppReducer, persistedState);
};