import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBDatatable,
    MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalHeader, MDBModalTitle,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";




export function Page({user}) {

    const [optSmModal, setOptSmModal] = useState(false);
    const [selectedTable, setSelectedTable] = useState(false);

    const toggleShow = () => setOptSmModal(!optSmModal);


    function showEdit(table)
    {
        setSelectedTable(table);
        setOptSmModal(true);
    }

    const tableData = [
        {
            name:<span onClick={()=>showEdit("packages")}>packages</span>,
            description:"A table to hold the packages configured for an Entity",
            version: "1",
            type: "Entity Specific",

        },
        {
            name: "clearway_drug",
            description:"A table to represent the clearway master filter table ",
            version: "1",
            type: "Global",
        },
        {
            name: "entities",
            description:"A table to hold all the entities",
            version: "1",
            type: "Global",
        },
        {
            name: "stage_pharmacy",
            description:"A table to hold the staged pharmacy parsed data",
            version: "1",
            type: "Entity Staging",
        },
        {
            name: "stage_department",
            description:"A table to hold the staged department information ",
            version: "1",
            type: "Entity Specific",
        },
        {
            name: "stage_provider",
            description:"A table to ",
            version: "1",
            type: "Entity Staging",
        },
        {
            name: "stage_patient",
            description:"A table to ",
            version: "1",
            type: "Entity Staging",
        },
        {
            name: "stage_prescription",
            description:"A table to ",
            version: "1",
            type: "Entity Staging",
        },
        {
            name: "package_dq_check",
            description:"A table to ",
            version: "1",
            type: "Entity Specific",
        },
        {
            name: "data_quality_check",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "npi_downloads",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "covered_entity_340b",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "lookup_group_name",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "lookup_group_value",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "price_drug",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "drug",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "class_pharmacy",
            description:"A table to ",
            version: "1",
            type: "Entity Classing",
        },
        {
            name: "insurance",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "stage_insurance",
            description:"A table to ",
            version: "1",
            type: "Entity Staging",
        },
        {
            name: "covered_entity_pharmacy_340b",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "class_department",
            description:"A table to ",
            version: "1",
            type: "Entity Classing",
        },
        {
            name: "insurance_common_name",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "stage_drug",
            description:"A table to ",
            version: "1",
            type: "Entity Staging",
        },
        {
            name: "package_review",
            description:"A table to ",
            version: "1",
            type: "Entity Specific",
        },
        {
            name: "file_processor",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "hrsa_covered_entity",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "organizations",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "taxonomy_downloads",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "files",
            description:"A table to ",
            version: "1",
            type: "Entity Specific",
        },
        {
            name: "file_data",
            description:"A table to ",
            version: "1",
            type: "Entity Staging",
        },
        {
            name: "npi_registry",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "npi_taxonomy",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "taxonomy",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "class_insurance",
            description:"A table to ",
            version: "1",
            type: "Entity Classing",
        },
        {
            name: "class_drug",
            description:"A table to ",
            version: "1",
            type: "Entity Classing",
        },
        {
            name: "contract_entity_340b",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "contract_entity_pharmacy_340b",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "OpenIddictApplications",
            description:"A table to ",
            version: "1",
            type: "Global",
        },
        {
            name: "field_mapping",
            description:"A table to ",
            version: "1",
            type: "Entity Specific",
        },

    ];


    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Table Name',
            },
            {
                accessorKey: 'type',
                header: 'Type',
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },

            {
                accessorKey: 'version',
                header: 'Version',
            },
        ],
    );

    //optionally access the underlying virtualizer instance
    const rowVirtualizerInstanceRef = useRef(null);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState([]);



    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);


    let checks = [
        {id:1, description: "Patient MRN must not be blank", threshold: 95},
        {id:2, description: "Patient Primary Insurance must not be blank", threshold: 95},
        {id:3, description: "Patient Primary Insurance should have common names", threshold: 30},
        {id:4, description: "Drug name must be under 100 characters", threshold: 85},
        {id:6, description: "NDC must be 11-digits", threshold: 85},
        {id:7, description: "Quantity prescribed must not be blank", threshold: 85},
        {id:8, description: "Prescriber must not be blank", threshold: 85},
        {id:9, description: "Prescriber NPI must start with a 1 and must be 10-digits", threshold: 85},
        {id:10, description: "Department name must not be blank", threshold: 85},
        {id:11, description: "Prescription Order Time date format is incorrect", threshold: 85},
        {id:12, description: "Prescription Order Time range is not around a year", threshold: 85},
    ];



    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol lg={10} offsetLg={1}  md={8} offsetSm={2} className=" pt-4" >
                    <h1>Data Quality Rules</h1>

                </MDBCol>
                <MDBCol lg={10} offsetLg={1}  md={8} offsetSm={2} className=" pt-4" >
                    <MDBTable className="table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Description</th>
                            <th>Threshold</th>
                        </tr>
                        </thead>
                        <tbody>
                        {checks.map( (row,id) => {
                            return(
                                <tr key={id}>
                                    <td>{row.id}</td>
                                    <td>{row.description}</td>
                                    <td>{row.threshold}</td>
                                </tr>
                            )
                        })}


                        </tbody>
                    </MDBTable>
                </MDBCol>




            </MDBRow>


        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
