import React, {useEffect, useState} from 'react';
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn, MDBCard, MDBCardBody, MDBCardHeader,
    MDBCol,
    MDBContainer, MDBDatatable, MDBIcon, MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog, MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane,
    MDBTypography
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import eventBus from "../../../Context/EventBus";



const GET_ACCESS = gql`
  query Get($id: Long!) {
    entityAccess(entityId: $id) {
      entityId
      email
      canRead
      canWrite
      account {
          first_name
          last_name
          
          email
          is_clearway
          is_admin
          is_client
        }
    }
  
  }
`;





const REMOVE_ACCESS = gql`
mutation Remove($entitlement: EntitlementInput!)
{
    removeEntityAccess(entitlement: $entitlement) {
        successful
    }
}
`;




export  function Page({user}) {
    let navigate = useNavigate();
    const params= useParams()
    const [datatable, setDatatable] = useState({ columns:[{label:"Email", field:"email"},{label:"Name", field: "name"} , {label: "Access", field: "canRead"}, {label:"Notifications", field:"canWrite"},{label:"Type", field:"type"}, {label:"Action", field: "action"}], rows:[]})

    const [pageLoading, setPageLoading] = useState(false);

    const [getAccess, { loading, error, data }] = useLazyQuery(GET_ACCESS);

    eventBus.on("entity.access", (data) =>
        loadData()
    );


    useEffect(()=> {
        loadData();

    }, [user]);


    function loadData()
    {
        getAccess({ variables:{id: Number(params.entity_id)}, context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                setDatatable({
                    columns: datatable.columns,
                    rows: [].concat(result?.data?.entityAccess)
                        .map((row) => {

                            return {
                                ...row,
                                action: (<MDBBtn size={"sm"} className="btn-danger" onClick={()=>{ RemoveAccess(row)}}><i className="fas fa-trash "  /></MDBBtn>),
                                name: row.account?.first_name + " " + row.account?.last_name,
                                canRead: row.canRead ? "Yes": "No",
                                canWrite: row.canWrite ? "Yes": "No",
                                type: row.account.is_admin ? "Admin":   row.account.is_clearway ? "Clearway":  row.account.is_client ? "Client":"Client"
                            }
                        })
                });
            })
    }


    const [removeUser] = useMutation(REMOVE_ACCESS, {context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}});


    function RemoveAccess(row) {
        setPageLoading(true);
        row = {...row}
        delete row.__typename;
        delete row.account;


        removeUser({ variables: { entitlement: row } })
            .then( (result) => {
                setPageLoading(false);

            if(result.data.removeEntityAccess?.successful)
            {
                NotificationManager.success("Success", 'Removed Access');
                loadData();
            }
            else
            {
                NotificationManager.error("Error! Please try again", 'Removed Access');
            }

        })
            .catch((error) => {
                NotificationManager.error("Api Error");

            })
    }






    if(loading || pageLoading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <div >
            <MDBRow>
                <MDBCol>
                    <div className='shadow-4 rounded-5 overflow-hidden pt-3'>

                        <MDBDatatable

                            loading={loading}

                            data={datatable}

                            search={true}
                            pagination={true}
                            advancedData={true}
                        />


                    </div>
                </MDBCol>
            </MDBRow>





        </div>
    );
}

const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
