import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    MDBBadge, MDBBtn, MDBCol,
    MDBContainer,
    MDBIcon, MDBInput,
    MDBModal, MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalHeader, MDBModalTitle, MDBRow, MDBSpinner,
    MDBSwitch, MDBTable, MDBTableBody,
} from "mdb-react-ui-kit";
import {Link, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {Button, MenuItem, Typography} from "@mui/material";
import eventBus from "../../../../Context/EventBus";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";


const GET_DEPARTMENTS = gql`
  query Get( $skip: Int!, $take: Int!, $entityId: Long!) {
    classDepartments(entityId: $entityId skip: $skip, take: $take) {
      totalCount
      items
      {
        id
        name
        is_340b
        id_340b
        address_1
        address_2
        city
        state
        zip_code
        zip_region
        status {
            value
        }
      }
      
    }
  }
`;


const SET_340_STATUS = gql`
mutation set( $entityId: Long!, $id: Long!, $is340b: Boolean!)
{
    setClassDepartmentIs340b(entityId: $entityId, id: $id, is340b: $is340b) {
        successful
    }
}
`;
const SET_340_ID = gql`
mutation set( $entityId: Long!, $id: Long!, $id340b: String!)
{
    setClassDepartment340bId(entityId: $entityId, id: $id, id340b: $id340b) {
       successful
    }
} 
`;


const SEARCH_HRSA = gql`
query search( $term: String!)
{
    searchHrsaCoveredEntity(search: $term)
  {
      id_340b
      covered_entity_id
     entity_name
     division_name
    
  }
} 
`;


const CLASSING_CLEANUP = gql`
mutation clean( $entityId: Long!)
{
    cleanDepartmentClass(entityId: $entityId)
  {
      successful
    
  }
} 
`;




const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const rowVirtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const params= useParams()



    const fetchSize = 5;
    const [totalRows, setTotalRows] = useState(0);

    const [TableData, setTableData] = useState([]);
    const [hrsaSelectedRow, setHrsaSelectedRow] = useState({});


    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getDepts,{error, loading: drugLoading, data: drugData}] = useLazyQuery(GET_DEPARTMENTS);
    const [SetDept340bStatus, {loading: is340bLoading}] = useMutation(SET_340_STATUS);
    const [SetDept340bId ,{loading: id340bLoading}] = useMutation(SET_340_ID);

    const [searchHrsaTerm, setSearchHrsaTerm] = useState("");
    const [hrsaResults, setHrsaResults] = useState([]);


    const [dbSearchHrsa,{loading: searchHrsaLoading, data: searchHrsaData}] = useLazyQuery(SEARCH_HRSA);


    const [cleanupClassing] = useMutation(CLASSING_CLEANUP,{
        variables: {
            entityId: Number(params.entity_id)
        },
        context:{
            headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
        }
    })


    //SEARCH_HRSA

    eventBus.on("department.class.update_row", (row) =>{
        let index = TableData.findIndex((line) => line.id === row.id);
        var tempTableData = [...TableData];
        tempTableData[index] = row;
        setTableData(tempTableData);
    });




    const columns = [
        // {
        //     accessorKey: 'id',
        //     header: 'ID',
        //     size: 10,
        //     editable: 'never',
        //     enableEditing: false,
        //     Cell: ({ cell }) => (
        //         <Link to={'/admin/entity/'+params.entity_id+'/class/department/'+cell.getValue()}>{cell?.getValue()}</Link>
        //     ),
        // },
        {
            accessorKey: 'name',
            header: 'Department Name',
            enableEditing: false,
            size: 500
        },
        {
            accessorKey: 'is_340b',
            header: '340B',

            Cell: ({cell}) => (<div ><MDBSwitch onChange={()=>set340bStatus(cell.row, !cell.getValue())}   checked={cell.getValue()}  /></div>),

// onChange={() => { setApprovalStatus(cell.row, !cell.getValue()) ;}}
            // Cell: ({ cell }) => (
            //     <MDBBadge pill >{cell?.getValue() ? "Yes" : "No"}</MDBBadge>
            // ),

            muiTableBodyCellEditTextFieldProps: {
                select: true, //change to select for a dropdown
                children: [{label:"Yes", value: true}, {label:"No", value: false}].map((state) => (
                    <MenuItem key={state.label} value={state.value}>
                        {state.label}
                    </MenuItem>
                )),
            },

            size: 100,

        },

        {
            accessorKey: 'id_340b',
            header: '340B ID',
            size: 200,
            Cell: ({cell}) => (<span><MDBIcon far icon="edit" style={{cursor:"pointer"}} onClick={() => showHrsaSearch(cell.row)} /> {cell.getValue() } </span>),
        },

        {
            accessorKey: 'address_1',
            header: 'Address 1',
            enableEditing: false,
        },
        {
            accessorKey: 'address_2',
            header: 'Address 2',
            enableEditing: false,
        },
        {
            accessorKey: 'city',
            header: 'City',
            enableEditing: false,
        },
        {
            accessorKey: 'state',
            header: 'State',
            enableEditing: false,
        },
        {
            accessorKey: 'status.value',
            header: 'Classing Status',
            enableEditing: false,
        },
    ];




    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } = useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {

                if(totalRows > 0 && pageParam * fetchSize > totalRows ) return[];

                let dd = await getDepts({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        entityId: Number(params.entity_id)
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then()


                //console.log(dd.data.classDepartments.totalCount);



                setTotalRows(dd.data.classDepartments.totalCount ?? 0)


                setTableData(TableData =>Array.from(new Set([...TableData, ...dd.data.classDepartments.items])));


                //setTableData(Array.from(new Set([...TableData, ...dd.data.classDepartments.items])));
                //setTableData([...TableData, ...dd.data.classDepartments.items]);


                //  setEmployees([...employees, ...arr]);

                return [];

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: false,
            refetchOnWindowFocus: false,

        });



    function set340bStatus(row, is340b)
    {
        let rowid = row.original.id;

        SetDept340bStatus({
            variables: {
                is340b: is340b,
                id: Number(rowid),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Set 340B Status.");

                let values = row.original;
                values.is_340b = is340b;
                eventBus.dispatch("department.class.update_row", values);
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }

    function set340bId(row, id)
    {
        var original = row.original;
        let rowid = row.original.id;
        SetDept340bId({
            variables: {
                id340b: id,
                id: Number(rowid),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Saved 340B ID.");

                let values = row.original;
                values.id_340b = id;

                eventBus.dispatch("department.class.update_row", values);
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }





    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(

        async (containerRefElement) => {
          //  console.log("Loading More Data");

            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;

              //  console.log( scrollHeight , scrollTop , clientHeight, isFetching,  TableData.length , totalRows )

                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    TableData.length < totalRows
                ){

                    await fetchNextPage();
                }


            }
        },
        [fetchNextPage, isFetching, totalRows, TableData],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {if (virtualizerInstanceRef.current) {virtualizerInstanceRef.current.scrollToIndex(0);}}, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {fetchMoreOnBottomReached(tableContainerRef.current).then();}, [fetchMoreOnBottomReached]);


    const [modalCleanup, setModalCleanup] = useState(false);

    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShow = () => setOptSmModal(!optSmModal);

    function showHrsaSearch(row)
    {
        setHrsaSelectedRow(row);
        setOptSmModal(true)
    }

    function searchHrsa(term)
    {
        setSearchHrsaTerm(term);

        if(term.length>2)
        {
            //go do the search here


            dbSearchHrsa({
                variables: {
                    term: term
                },
                context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
                .then( (result) => {
                    setHrsaResults(result?.data?.searchHrsaCoveredEntity);
                })
                .catch( (error) => {
                    NotificationManager.error("Api Error");
                })



            //dbSearchHrsa
        }
    }

    function confirmClassCleanup()
    {
        setModalCleanup(false);
        cleanupClassing().then(r => {
            NotificationManager.success("Classing Cleanup Submitted!");
        }).catch((error) =>{
            NotificationManager.error("Api Error");
        })

    }


    return (
        <div >
            <MaterialReactTable
                columns={columns}
                data={TableData}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableGlobalFilter={true}
                enableFilters={false}
                enableRowVirtualization={true} //optional, but recommended if it is likely going to be more than 100 rows
                enableColumnVirtualization={true}
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '700px' }, //give the table a max height
                    onScroll: (event) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={isError ? { color: 'error',  children: 'Error loading data',} : undefined}
                // onColumnFiltersChange={setColumnFilters}
                // onGlobalFilterChange={setGlobalFilter}
                // onSortingChange={setSorting}
                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {TableData.length.toLocaleString("en-US")} of {totalRows.toLocaleString("en-US")} total rows.
                    </Typography>
                )}

                renderTopToolbarCustomActions={() => <Typography variant="h5" className="m-3">Department Classing <span><Button color={"secondary"} size={"small"} onClick={()=> setModalCleanup(true)}>Cleanup Classing</Button></span></Typography>}


                enableDensityToggle={false}
                initialState={{ density: 'compact' }}


                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                rowVirtualizerProps={{ overscan: 10 }}
                rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance

            />


            <MDBModal open={optSmModal} tabIndex='-1' onClose={()=>setOptSmModal(false)}>
                <MDBModalDialog size='xl'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Search HRSA</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}/>
                        </MDBModalHeader>
                        <MDBModalBody>




                            <MDBRow >
                                <MDBCol md={12}>
                                    <MDBTable>

                                        <MDBTableBody>
                                            <tr>
                                                <th scope='row' className="fw-bold">Class ID</th>
                                                <td>{hrsaSelectedRow?.original?.id}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'  className="fw-bold">Name</th>
                                                <td>{hrsaSelectedRow?.original?.name}</td>
                                            </tr>

                                            <tr>
                                                <th scope='row'  className="fw-bold">Address 1</th>
                                                <td>{hrsaSelectedRow?.original?.address_1}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'  className="fw-bold">Address 2</th>
                                                <td>{hrsaSelectedRow?.original?.address_2}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'   className="fw-bold">City</th>
                                                <td>{hrsaSelectedRow?.original?.city}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'  className="fw-bold">Zip</th>
                                                <td>{hrsaSelectedRow?.original?.zip_code}</td>
                                            </tr>
                                        </MDBTableBody>


                                    </MDBTable>
                                </MDBCol>

                            </MDBRow>




                            <MDBInput label='Search Entity, Division or ID' id='formControlDefault' type='text'
                                    value={searchHrsaTerm}
                                    onChange={(e) =>searchHrsa(e.target.value)}
                            />


                            {searchHrsaLoading && <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>}

                            {!searchHrsaLoading &&

                            <MDBTable>
                                <thead>
                                <tr>
                                    <th>340B ID</th>
                                    <th>Entity</th>
                                    <th>Division</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>



                                { hrsaResults?.map( (row,id) => {
                                    return(
                                        <tr key={id}>
                                            <td>{row.id_340b}</td>
                                            <td>{row.entity_name}</td>
                                            <td>{row.division_name}</td>
                                            <td><MDBBtn onClick={() => {
                                                set340bId(hrsaSelectedRow, row.id_340b);
                                                toggleShow();
                                                searchHrsa("");
                                                setHrsaResults([]);

                                            }}>Select</MDBBtn></td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </MDBTable>


                            }


                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal open={modalCleanup} tabIndex='-1' onClose={()=>setModalCleanup(false)}>
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Cleanup Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={()=>setModalCleanup(false)}/>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <Typography fontSize={20}>
                                Are you sure you want to clean up the classing records?
                            </Typography>
                            <p>
                                This will go through all the classing records and delete any that are no longer connected to raw data files.
                            </p>

                            <Button onClick={()=>confirmClassCleanup()}>Confirm Classing Cleanup</Button>
                        </MDBModalBody>

                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


        </div>
    );
};








const queryClient = new QueryClient();




export function Page({user}) {

    return (

        <MDBContainer >
            <QueryClientProvider client={queryClient}>
                <DrugTable user={user} />
            </QueryClientProvider>


        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
