import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import humandata from "../../Images/main.jpeg"
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../Context/reducers/User/actions";
import {connect, useDispatch} from "react-redux";
import {MDBContainer, MDBSpinner} from "mdb-react-ui-kit";
import {useAuth0} from "@auth0/auth0-react";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";


const GET_JWT = gql`
query GetToken($token: String!)
{
    exchangeAuth0Token(token: $token) {
      token
    }
}
`;


export function Page({cwuser}) {

    const { user, isAuthenticated, getAccessTokenSilently , getIdTokenClaims, logout, loginWithRedirect } = useAuth0()
    const dispatch = useDispatch()
    let navigate = useNavigate();

    const [getToken, { loading, error, data }] = useLazyQuery(GET_JWT);


    useEffect(()=> {


        if(user?.email)
        {
            if(!user.email_verified)
            {
                setTimeout( function() {
                    logout({returnTo: window.location.origin})
                }, 2000);

            }

            //no we can request a token
            getAccessTokenSilently().then((result) => {

                getIdTokenClaims().then((result) => {


                    getToken({variables:{token: result.__raw}}).then( (result) => {
                        dispatch(setUser(result.data.exchangeAuth0Token.token));
                    }).catch(() => {
                         navigate('/');
                    })

                })
            }).catch(() => {
                navigate('/');
            });

        }

    },[user])


    useEffect(() => {

        if(cwuser.isAuthenticated)
        {
            if(cwuser.isAdmin)
            {
                navigate('/admin/home');
            }
        }
    }, [user, cwuser])


    return (  <MDBContainer style={{padding:'20px'}} ><div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div></MDBContainer>);

}


const mapStateToProps = state => {
    return {
        cwuser: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
