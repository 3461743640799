import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import humandata from "../../Images/main.jpeg"
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../Context/reducers/User/actions";
import {connect, useDispatch} from "react-redux";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCol,
    MDBContainer, MDBIcon,
    MDBRow,
    MDBSpinner, MDBTypography
} from "mdb-react-ui-kit";
import {useAuth0} from "@auth0/auth0-react";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import {default as EntityListing} from "./Components/EntityListing";
import Moment from "react-moment";
import CountUp from "react-countup";
import {default as AddOrganization} from "./Components/AddOrganizationButton";



export function Page({cwuser}) {

    let navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();

    const [metrics, setMetrics] = useState({})



    if(!cwuser.isAdmin)
    {
        NotificationManager.error("Not Authorized")
        navigate('/')
    }



    const GET_METRICS = gql`
          query Get {
            entityMetrics {
              count
            }
            organizationMetrics {
              count
            }
            accountMetrics {
              count
            }
            fileProcessors
            {
                entity_id
                file_id
            } 
          }
        `;
    const [getMetrics, { loading, error, data }] = useLazyQuery(GET_METRICS);

    useEffect(()=> {
        getMetrics({ fetchPolicy:"cache-first", context:{headers:{"Authorization":cwuser.isAuthenticated ? 'Bearer ' +cwuser.token : ''}}})
            .then( (result) => {
                setMetrics(result.data);
            })

    }, [cwuser]);





    if(false) return (  <MDBContainer style={{padding:'20px'}} ><div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div></MDBContainer>);
    return (

        <MDBContainer fluid>
            <MDBRow className=' text-center p-4' >
                <MDBCol>

                    <MDBTypography tag='h1'>Clearway Health Dashboard</MDBTypography>

                </MDBCol>

            </MDBRow>
            <MDBRow className='justify-content-center'>
                <MDBCol md='10'>
                    <section>
                        <MDBRow>
                            {/*<MDBCol md='4' className='mb-md-0'>*/}
                            {/*    <MDBCard>*/}
                            {/*        <MDBCardBody>*/}
                            {/*            <div className='d-flex align-items-center'>*/}
                            {/*                <div className='flex-shrink-0'>*/}
                            {/*                    <div className='p-3 bg-primary rounded-4 shadow-2-strong'>*/}
                            {/*                        <MDBIcon icon='sitemap' size='lg' className='text-white fa-fw' />*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className='flex-grow-1 ms-4'>*/}
                            {/*                    <p className='text-muted mb-1'>Organizations</p>*/}
                            {/*                    <h2><CountUp end={metrics.organizationMetrics?.count ?? 0} /></h2>*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </MDBCardBody>*/}
                            {/*        <MDBCardFooter background='light' className='border-0 p-2'>*/}
                            {/*            <Link to={"/admin/organization"}>*/}
                            {/*                <MDBBtn color='none' className='ms-3' tag='a' href='#'>*/}
                            {/*                    View more <MDBIcon icon='arrow-right' className='ms-2' />*/}
                            {/*                </MDBBtn>*/}
                            {/*            </Link>*/}

                            {/*        </MDBCardFooter>*/}
                            {/*    </MDBCard>*/}
                            {/*</MDBCol>*/}

                            <MDBCol sm={12} md={6} lg={4} className='mb-md-0 p-3'>
                                <MDBCard>
                                    <MDBCardBody>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <div className='p-3 bg-primary rounded-4 shadow-2-strong'>
                                                    <MDBIcon icon='chart-pie' size='lg' className='text-white fa-fw' />
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-4'>
                                                <p className='text-muted mb-1'>Entities</p>
                                                <h2><CountUp end={metrics.entityMetrics?.count ?? 0} /></h2>

                                            </div>
                                        </div>
                                    </MDBCardBody>
                                    <MDBCardFooter background='light' className='border-0 p-2'>
                                        <Link to={"/admin/entity"}>
                                            <MDBBtn color='none' className='ms-3' tag='a' href='#'>
                                                View more <MDBIcon icon='arrow-right' className='ms-2' />
                                            </MDBBtn>
                                        </Link>
                                    </MDBCardFooter>
                                </MDBCard>
                            </MDBCol>

                            <MDBCol sm={12} md={6} lg={4} className='mb-md-0 p-3'>
                                <MDBCard>
                                    <MDBCardBody>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <div className='p-3 bg-primary rounded-4 shadow-2-strong'>
                                                    <MDBIcon icon='users' size='lg' className='text-white fa-fw' />
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-4'>
                                                <p className='text-muted mb-1'>Users</p>
                                                <h2><CountUp end={metrics.accountMetrics?.count ?? 0} /></h2>
                                            </div>
                                        </div>
                                    </MDBCardBody>
                                    <MDBCardFooter background='light' className='border-0 p-2'>
                                        <Link to={"/admin/user"}>
                                            <MDBBtn color='none' className='ms-3' tag='a' href='#'>
                                                View more <MDBIcon icon='arrow-right' className='ms-2' />
                                            </MDBBtn>
                                        </Link>
                                    </MDBCardFooter>
                                </MDBCard>
                            </MDBCol>

                            <MDBCol sm={12} md={6} lg={4} className='mb-md-0 p-3'>
                                <MDBCard>
                                    <MDBCardBody>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <div className='p-3 bg-primary rounded-4 shadow-2-strong'>
                                                    <MDBIcon icon='file-medical' size='lg' className='text-white fa-fw ' />
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-4'>
                                                <p className='text-muted mb-1'>Queued</p>
                                                <h2><CountUp end={metrics.fileProcessors?.length ?? 0} /></h2>
                                            </div>
                                        </div>
                                    </MDBCardBody>
                                    <MDBCardFooter background='light' className='border-0 p-2'>
                                        <span className="ms-3 text-muted">Files processing</span>
                                        {/*<Link to={"/admin/user"}>*/}
                                        {/*    <MDBBtn color='none' className='ms-3' tag='a' href='#'>*/}
                                        {/*        View more <MDBIcon icon='arrow-right' className='ms-2' />*/}
                                        {/*    </MDBBtn>*/}
                                        {/*</Link>*/}
                                    </MDBCardFooter>
                                </MDBCard>
                            </MDBCol>


                        </MDBRow>
                    </section>
                </MDBCol>
            </MDBRow>
            <MDBRow className='justify-content-center pt-4' >
                <MDBCol sm={10}>
                    <EntityListing />
                </MDBCol>

            </MDBRow>
        </MDBContainer>
    );
}


const mapStateToProps = state => {
    return {
        cwuser: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
