import React, {useState} from 'react';



export default function Page() {


    return (

        <div >

            <h3>Overview</h3>

            <p>
                Welcome to the Clearway Health client portal. You will securely share data files so that Clearway Health can evaluate and create the specialty pharmacy opportunity analysis for your organization.
            </p>


            <h3>Access</h3>

            <p>
                This access is specific to your email address. If you need to add other team members from your organization, please reach out to your Clearway Health Client Development Executive.
            </p>

            <h3>Required Files</h3>
            <p>
                The required file type is a CSV file with headers conforming to RFC 4180 and no larger than 2gb. If a file is larger than 2gb, please split the file into smaller files and complete multiple file uploads. The files will be combined upon upload.
            </p>
        </div>
    );
}