import React from 'react';
import ReactDOM from 'react-dom/client';
import {default as App} from './App';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import "./index.css"
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import { Provider } from 'react-redux'
import 'react-notifications/lib/notifications.css';


//@mui/material

import { createAppStore } from './Context/Store';
import {SaveState} from './Context/LocalStorage';
import {DefaultOptions} from "@apollo/client";

import CssBaseline from '@mui/material/CssBaseline';


const root = ReactDOM.createRoot(document.getElementById('root'));





var store = createAppStore();

store.subscribe(()=>{
    SaveState(store.getState());
});


const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
    },
}

function getBaseURL()
{
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
        return "https://pre-data.clearwayhealth.com/api/graphql/"; // "https://clearwayhealth.dev/api/graphql/";
    else
        return "/api/graphql/";
}



const client = new ApolloClient({
    uri:getBaseURL(),
    cache: new InMemoryCache({
        resultCaching:false,
        addTypename: false,

    }),
    defaultOptions: defaultOptions,
});


root.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <Auth0Provider
                domain="auth.clearwayhealth.dev"
                clientId="UNHPdimnM0cHaa782HuJqlhCKuB8SiOd"
                redirectUri={window.location.origin+'/admin'}
            >
                <CssBaseline />
                <App />
            </Auth0Provider>
        </ApolloProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
