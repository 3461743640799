import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBCardFooter, MDBBtn,
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import CountUp from 'react-countup';

import {default as Entity340B} from "../../Components/Entity340B_ID";
import EntityAddAccess from "../../Components/EntityAddAccess";
import EntityAccessListing from "../../Components/EntityAccessListing";
import EntityFieldMapping from "../../Components/EntityFieldMapping";
import eventBus from "../../../../Context/EventBus";

const GetEntity = gql`
  query Get($id: Long!) {
    entity(entityId: $id) {
      id
      name
      created
      updated
    }
   
  }
`;

const SET_ENTITY_NAME = gql`
mutation set( $entityId: Long!, $name: String!)
{
    changeEntityName(entityId: $entityId, name: $name) {
       successful
    }
} 
`;





export function Page({user}) {
    let navigate = useNavigate();
    const params= useParams()
    const [SaveEntityName, {loading: SaveEntityNameLoading}] = useMutation(SET_ENTITY_NAME);
    const [EntityName, setEntityName] = useState("");



    const { loading, error, data, refetch } = useQuery(GetEntity, {
        variables:{
            id: Number(params.entity_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });


    useEffect(() =>
    {
        setEntityName(data?.entity?.name);
    }, [data]);


    function UpdateEntityName(name)
    {
        SaveEntityName({
            variables: {
                name: name,
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Name Updated");
                eventBus.dispatch("entity.update", null);
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }


    const [fillActive, setFillActive] = useState('overview');

    const handleFillClick = (value: string) => {
        if (value === fillActive) {
            return;
        }

        setFillActive(value);
    };





    if(false) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);

    else return (

        <MDBContainer >
            <MDBBreadcrumb >
                <MDBBreadcrumbItem>
                    <Link to={"/admin/home"}>Admin</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entity</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem active>
                    Settings
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>

            <MDBTabs fill className='mb-3'>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleFillClick('overview')} active={fillActive === 'overview'}>
                        Overview
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleFillClick('access')} active={fillActive === 'access'}>
                        Access
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleFillClick('340B')} active={fillActive === '340B'}>
                        340B
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>

                <MDBTabsPane open={fillActive === 'overview'}>
                    <MDBTable>
                        <MDBTableBody>
                            <tr>
                                <th scope='row' className="fw-bold">Entity Name</th>
                                <td >
                                    <MDBInputGroup size={"sm"} >
                                        <MDBInput label='Entity Name' id='form1' type='text' wrapperClass={"w-75"} value={EntityName ?? "Unknown"}
                                            onChange={(event => {
                                                setEntityName(event.target.value);
                                            })}
                                        />
                                        <MDBBtn onClick={()=>UpdateEntityName(EntityName)} outline>Save</MDBBtn>
                                    </MDBInputGroup>
                                </td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                    <EntityFieldMapping />

                </MDBTabsPane>
                <MDBTabsPane open={fillActive === 'access'}>
                    <MDBRow>
                        <MDBCol>
                            <MDBTypography tag='h2'>User Access <span className="float-end"><EntityAddAccess /></span></MDBTypography>

                            <EntityAccessListing />
                        </MDBCol>
                    </MDBRow>

                </MDBTabsPane>
                <MDBTabsPane open={fillActive === '340B'}>
                    <Entity340B />

                </MDBTabsPane>
            </MDBTabsContent>













        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
