import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBContainer,
    MDBSpinner,
    MDBRow,
    MDBCol,
    MDBTypography,
    MDBCardFooter, MDBBtn,
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import EntityAccessListing from "../../Components/EntityAccessListing";
import EntityAddAccess from "../../Components/EntityAddAccess";



export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()





    return (

        <MDBContainer >
            <MDBRow>
                <MDBCol>
                    <MDBTypography tag='h2'>User Access <span className="float-end"><EntityAddAccess /></span></MDBTypography>
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol>
                    <EntityAccessListing />
                </MDBCol>
            </MDBRow>



        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
