import React, {useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer, MDBIcon,
    MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalFooter, MDBModalHeader, MDBModalTitle,
    MDBRow, MDBSpinner, MDBTable, MDBTableBody,
    MDBTypography, MDBModalDialog
} from "mdb-react-ui-kit";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import eventBus from "../../../Context/EventBus";


const GET_340B_IDs = gql`
query get($entityId: Long!)
{
    entity340BIds( entityId: $entityId) {
      id_340b
      active
      created_ts
      CoveredEntity{
        entity_name
        division_name
      }
    }
}
`;

const SEARCH_HRSA = gql`
query search( $term: String!)
{
    searchHrsaCoveredEntity(search: $term)
  {
      id_340b
      covered_entity_id
     entity_name
     division_name
    
  }
} 
`;



const ADD_340B_ID = gql`
mutation add($entityId: Long!, $id340b: String!)
{
    addEntity340BId(entityId: $entityId, id_340b: $id340b)
  {
     successful
    
  }
} 
`;


const REMOVE_340B_ID = gql`
mutation add($entityId: Long!, $id340b: String!)
{
    removeEntity340BId(entityId: $entityId, id_340b: $id340b)
  {
     successful
    
  }
} 
`;



export function Page({user}) {

    let navigate = useNavigate();
    const params= useParams()


    if(user.isAuthenticated === false || user.isAdmin ===false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    //const [addPackage, { data, loading, error }] = useMutation(CREATE_PACKAGE, {});

    //Delete Confirmation Modal Items
    const [show340BIdRemovalConfirmation, setShow340BIdRemovalConfirmation] = useState(false);
    const [toRemove340BId, setToRemove340BId] = useState("");
    const toggleShow340BIdRemovalConfirmation = () => setShow340BIdRemovalConfirmation(!show340BIdRemovalConfirmation);



    //Search HRSA Modal
    const [searchHrsaTerm, setSearchHrsaTerm] = useState("");
    const [hrsaResults, setHrsaResults] = useState([]);
    const [dbSearchHrsa,{loading: searchHrsaLoading, data: searchHrsaData}] = useLazyQuery(SEARCH_HRSA);
    const [isLoading, setIsLoading] = useState(false)
    const [dbAdd340BId] = useMutation(ADD_340B_ID, {});
    const [dbRemove340BId] = useMutation(REMOVE_340B_ID, {});



    const [showHrsaSearchModal, setShowHrsaSearchModal] = useState(false);
    const toggleShow = () => setShowHrsaSearchModal(!showHrsaSearchModal);


    const { loading, error, data, refetch } = useQuery(GET_340B_IDs, {
        variables:{
            entityId: Number(params.entity_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });

    function searchHrsa(term)
    {
        setSearchHrsaTerm(term);

        if(term.length>2)
        {
            dbSearchHrsa({
                variables: {
                    term: term
                },
                context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
                .then( (result) => {
                    setHrsaResults(result?.data?.searchHrsaCoveredEntity);
                })
                .catch( (error) => {
                    NotificationManager.error("Api Error");
                })
        }
    }


    function Select340BId(id340b)
    {
        dbAdd340BId({
            variables: {
                id340b: id340b,
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Saved 340B ID.");
                refetch().then();

            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }

    function Remove340BId(id340b)
    {
        dbRemove340BId({
            variables: {
                id340b: id340b,
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Removed 340B ID.");
                refetch().then();

            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }




    function request()
    {
        // setIsLoading(true);
        // addPackage({
        //     variables: {
        //         name: packageName,
        //         entityId: Number(params.entity_id)
        //     },
        //     context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}
        // })
        //     .then( (result) => {
        //         NotificationManager.success("Created Package!")
        //         let id = result.data.createPackage.id;
        //
        //         eventBus.dispatch("package.create", { packageId: id });
        //
        //         setShowReviewRequest(false);
        //         setIsLoading(false);
        //     })
        //     .catch((error) => {
        //         let details =JSON.parse(JSON.stringify(error));
        //         if(details.graphQLErrors) details.graphQLErrors.map( (row) => {NotificationManager.error(row.message)})
        //         setIsLoading(false);
        //     })

    }


    if(isLoading) return( <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>)

    return (

        <div >
            <MDBTable>
                <thead>
                <tr>
                    <th>340B ID</th>
                    <th>Entity</th>
                    <th>Division</th>
                    <th><span className="float-end"><MDBBtn onClick={()=>setShowHrsaSearchModal(true)} size={"sm"} >Add</MDBBtn></span></th>

                </tr>

                </thead>
                <tbody>



                {data?.entity340BIds.map( (row,id) => {
                    return(
                        <tr key={id}>
                            <td>{row.id_340b}</td>
                            <td>{row.CoveredEntity?.entity_name}</td>
                            <td>{row.CoveredEntity?.division_name}</td>
                            <td><span className="float-end"><MDBBtn onClick={() =>
                            {
                                setShow340BIdRemovalConfirmation(true);
                                setToRemove340BId(row.id_340b);

                            }} color={"danger"}><MDBIcon fas icon="trash" /></MDBBtn></span></td>

                        </tr>
                    )
                })}


                {/*{ hrsaResults?.map( (row,id) => {*/}
                {/*    return(*/}
                {/*        <tr key={id}>*/}
                {/*            <td>{row.id_340b}</td>*/}
                {/*            <td>{row.entity_name}</td>*/}
                {/*            <td>{row.division_name}</td>*/}
                {/*            <td><MDBBtn onClick={() => {*/}
                {/*                set340bId(hrsaSelectedRow, row.id_340b);*/}
                {/*                toggleShow();*/}
                {/*                searchHrsa("");*/}
                {/*                setHrsaResults([]);*/}

                {/*            }}>Select</MDBBtn></td>*/}
                {/*        </tr>*/}
                {/*    )*/}
                {/*})}*/}

                </tbody>
            </MDBTable>








            <MDBModal open={showHrsaSearchModal}  closeOnEsc={true} onClose={()=>setShowHrsaSearchModal(false)} >
                <MDBModalDialog size='xl'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Search HRSA</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}/>
                        </MDBModalHeader>
                        <MDBModalBody>


                            <MDBInput label='Search Entity, Division or ID' id='formControlDefault' type='text'
                                      value={searchHrsaTerm}
                                      onChange={(e) =>searchHrsa(e.target.value)}
                            />


                            {searchHrsaLoading && <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>}

                            {!searchHrsaLoading &&

                            <MDBTable>
                                <thead>
                                <tr>
                                    <th>340B ID</th>
                                    <th>Entity</th>
                                    <th>Division</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>



                                { hrsaResults?.map( (row,id) => {
                                    return(
                                        <tr key={id}>
                                            <td>{row.id_340b}</td>
                                            <td>{row.entity_name}</td>
                                            <td>{row.division_name}</td>
                                            <td><MDBBtn onClick={() => {
                                                Select340BId(row.id_340b);
                                                toggleShow();
                                                searchHrsa("");
                                                setHrsaResults([]);

                                            }}>Select</MDBBtn></td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </MDBTable>


                            }


                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>



            <MDBModal tabIndex='-1' open={show340BIdRemovalConfirmation} onClose={()=>setShow340BIdRemovalConfirmation(false)}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Removal Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow340BIdRemovalConfirmation}/>
                        </MDBModalHeader>
                        <MDBModalBody >
                           Are you sure you want to remove the 340B ID {toRemove340BId} from this entity?

                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={()=>{toggleShow340BIdRemovalConfirmation()}}>Cancel</MDBBtn>
                            <MDBBtn onClick={() => {
                                Remove340BId(toRemove340BId);
                                toggleShow340BIdRemovalConfirmation();
                            }}>Remove</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>





    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
