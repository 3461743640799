import React, {useEffect, useState} from 'react';
import {
    MDBBtn, MDBCard, MDBCardBody, MDBCardHeader,
    MDBCol,
    MDBContainer, MDBDatatable, MDBIcon, MDBInput, MDBInputGroup,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog, MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
    MDBTableHead, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane,
    MDBTypography
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import eventBus from "../../../Context/EventBus";

const CREATE_USER_ORG = gql`
mutation Add($email: String!, $firstName: String!, $lastName: String!, $entityId: Long!)
{
    createEntityAccount(email: $email, firstName: $firstName, lastName: $lastName, entityId: $entityId) {
      successful
    }
}
`;

const ADD_ACCESS = gql`
mutation Add($entitlement: EntitlementInput!)
{
    addOrganizationAccess(entitlement: $entitlement) {
        successful
    }
}
`;




const SEARCH_ACCOUNTS = gql`
query search($term: String!){
  accountSearch(term: $term){
    email
    first_name
    last_name
   }
}
`


export  function Page({user}) {
    let navigate = useNavigate();

    const params= useParams()
    const [newUser, setNewUser] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [searchUserResults, setSearchUserResults] = useState([]);
    const [showNewUser, setShowNewUser] = useState(false);
    const toggleShow = () => setShowNewUser(!showNewUser);
    const [searchUsers, {loading: searchLoading}] = useLazyQuery(SEARCH_ACCOUNTS);






    const [createUserOrg] = useMutation(CREATE_USER_ORG, {
        variables: {
            entitlement: {
                canRead: true,
                canWrite: true,
                organizationId: parseInt(params.id),
                email: newUser
            },
        },
        context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}

    });

    const [orgAddUser] = useMutation(ADD_ACCESS, {context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}});

    const [dtClientSearch, setDTClientSearch] = React.useState({
        columns: [
            {
                label: "Email",
                field: "email",
            },
            {
                label: "First Name",
                field: "first_name",
            },

            {
                label: "Last Name",
                field: "last_name",
            },
            {
                label: "Action",
                field: "action",
                sort: "disabled",
            }],
        rows: []
    });
    const [iconsActive, setIconsActive] = useState('tab1');

    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const handleIconsClick = (value: string) => {
        if (value === iconsActive) {
            return;
        }

        setIconsActive(value);
    };


    function AddAccess(email) {
        toggleShow();
        setTableLoading(true)
        orgAddUser({ variables: { entitlement: {
                    canRead: true,
                    canWrite: true,
                    entityId: Number(params.entity_id),
                    email: email
                }
            }})
            .then( (result) => {
                setShowNewUser(false);
                if(result.data.addOrganizationAccess?.successful)
                {
                    NotificationManager.success("Success", 'Added Access');
                    eventBus.dispatch("entity.access", { message: "Added Access" });
                    setTableLoading(false)
                }
                else
                {
                    NotificationManager.error("Error! Please try again", 'Added Access');
                    setTableLoading(false)
                }
            })
            .catch((error) => {
                NotificationManager.error("Error! Please try again", 'Added Access');
                setTableLoading(false)
            })
    }

    function SearchClients(term) {
        if(term.length<2) return;

        searchUsers({
            context:{
                headers:{
                    "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''
                }
            },
            variables:
                {
                    term:term
                }
        }).then( (result) => {

            let rows = [].concat(result?.data?.accountSearch)
                .map((row) => {
                    return {
                        ...row,
                        action: (<MDBBtn size={"sm"} onClick={() => AddAccess(row.email)}>Add</MDBBtn>)
                    }
                });

            setSearchUserResults(rows);

            let newdtClientSearch = dtClientSearch;
            newdtClientSearch.rows = rows;
            setDTClientSearch(newdtClientSearch);

        })
    }

    function CreateUserForOrg()
    {

        createUserOrg({
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'}},
            variables:{
                "email":email,
                "firstName":firstName,
                "lastName":lastName,
                "entityId":parseInt(params.entity_id)
            }
        }).then( (result) => {
            NotificationManager.success("Created Account for Entity")
            eventBus.dispatch("entity.access", { message: "Added Access" });
            setShowNewUser(false)
            setTableLoading(false)
            setLastName("");
            setFirstName("");
            setEmail("");
        }).catch((error) => {
            setTableLoading(false)
            setLastName("");
            setFirstName("");
            setEmail("");
            let details =JSON.parse(JSON.stringify(error));
            if(details.graphQLErrors) details.graphQLErrors.map( (row) => {
                if(row.message.indexOf("regular expression")>0)
                {
                    NotificationManager.error("Do not use special characters")
                }
                else{
                    NotificationManager.error(row.message)
                }
            })
        })
    }







    if( tableLoading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <div>
            <MDBBtn className="btn btn-primary" onClick={()=>setShowNewUser(true)} >Add User</MDBBtn>

            <MDBModal open={showNewUser} onClose={()=>setShowNewUser(false)} >
                    <MDBModalDialog size={"lg"}>

                        <div className="modal-content" >

                            <MDBModalBody >
                                <MDBTabs className="p-4" >
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
                                            <MDBIcon fas icon='chart-pie' className='me-2' /> Search Users
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
                                            <MDBIcon fas icon='cogs' className='me-2' /> Add User
                                        </MDBTabsLink>
                                    </MDBTabsItem>

                                </MDBTabs>

                                {iconsActive === 'tab1' &&
                                <div id={'tab1'}>
                                    <MDBInput label='Search Users' type='text' size={"sm"} value={newUser} onChange={(e) => {setNewUser(e.target.value); SearchClients(e.target.value)}} />

                                    {searchLoading && <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>}

                                    {dtClientSearch.rows.length>0 &&
                                    <MDBDatatable
                                        className="pt-4"
                                        advancedData={true}
                                        data={dtClientSearch}
                                        hover
                                        sm
                                    />
                                    }

                                </div>
                                }

                                {iconsActive === 'tab2' &&
                                <div id={'tab2'}>

                                    <MDBInput  autoFocus={true} size={"sm"} id={"email"} label='Email address' type='email' value={email} onChange={(e) => setEmail(e.target.value)}  />
                                    <br/>
                                    <MDBInput tabIndex={2}  label='First name' id={"first_name"} size={"sm"} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    <br/>
                                    <MDBInput label='Last name' id={"last_name"} size={"sm"} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    <br/>
                                    <MDBTypography tag='h6' className="text-center">
                                        An email will be sent to the user with instructions on accessing the portal.
                                    </MDBTypography>

                                    <center><MDBBtn onClick={CreateUserForOrg}>Add User</MDBBtn></center>
                                </div>
                                }


                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={()=>{toggleShow()}}>Cancel</MDBBtn>
                            </MDBModalFooter>
                        </div>
                    </MDBModalDialog>
            </MDBModal>



        </div>
    );
}

const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
