import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalFooter, MDBModalHeader, MDBModalTitle,
    MDBRow, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead,
    MDBTypography, MDBModalDialog
} from "mdb-react-ui-kit";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {NotificationManager} from "react-notifications";



const GET_DRUGS = gql`
  query Get( $skip: Int!, $take: Int!, $search: String!) {
    drugs( skip: $skip, take: $take, search: $search) {
      totalCount
      items
      {
        ndc
        gpi
        name
        strength
        strength_unit_of_measure
        dosage_form
        clearwayDrug {
            ndc
            classification_id
            is_orphaned
            classification {
             value
            }
        }
      }
      
    }
  }
`;




export function Page({user, selected}) {


    const [showAdd, setShowAdd] = useState(false);
    const toggleShow = () => setShowAdd(!showAdd);
    const [term, setTerm] = useState("");
    const [drugResults, setDrugResults] = useState([]);

    const [getDrugs,{error, loading: drugLoading, data: drugData}] = useLazyQuery(GET_DRUGS);


    function search(term)
    {
        getDrugs({
            variables: {
                skip: 0,
                take: 20,
                search: term ?? ""
            },
            context:{
                headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
            }
        }).then( (result) => {
            setDrugResults(result.data.drugs.items)
        })

    }
    function rowSelection(row)
    {
        setShowAdd(false);
        selected(row);

    }

    // function request()
    // {
    //     setIsLoading(true);
    //     addOrganization({
    //         variables: {name: orgName,},
    //         context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}
    //     })
    //         .then( (result) => {
    //             NotificationManager.success("Created Organization, Redirecting you.")
    //             let id = result.data.createOrganization.id;
    //             setTimeout( function() {navigate("/admin/organization/"+id);}, 1000);
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             let details =JSON.parse(JSON.stringify(error));
    //             if(details.graphQLErrors) details.graphQLErrors.map( (row) => {NotificationManager.error(row.message)})
    //             setIsLoading(false);
    //         })
    //
    // }


    if(error) return( <div className="text-center" style={{paddingTop:"50px"}}>Someting went wrong, please try again.</div>)
    if(false) return( <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>)

    return (

        <div >
            <MDBBtn onClick={toggleShow} size={"sm"}>Add Drug</MDBBtn>



            <MDBModal tabIndex='-1' open={showAdd}  onClose={()=>setShowAdd(false)}>
                <MDBModalDialog  size={"xl"}>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Drug Classification</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}/>
                        </MDBModalHeader>
                        <MDBModalBody >
                            <MDBRow style={{padding:'5px'}}>
                                <MDBCol>
                                    <MDBInput id='name' label='Search Drugs' type='text' size={"sm"}  value={term} onChange={(e) => {

                                        let term = e.target.value;
                                        setTerm(term);

                                        if(term.length>3) search(term);
                                    }} />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow style={{padding:'5px'}}>
                                <MDBCol>
                                    {drugLoading && <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>}

                                    {!drugLoading &&
                                    <MDBTable>
                                        <MDBTableHead>
                                            <tr>
                                                <th scope='col'>NDC</th>
                                                <th scope='col'>Drug</th>
                                                <th scope='col'>Form</th>
                                                <th scope='col'>Strength</th>
                                                <th scope='col'>Action</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {drugResults.map( (row, id) => {
                                                return( <tr key={id}>
                                                    <th scope='row'>{row.ndc}</th>
                                                    <td>{row.name}</td>
                                                    <td>{row.dosage_form}</td>
                                                    <td>{row.strength}</td>
                                                    <td><MDBBtn size={"sm"} onClick={() =>rowSelection(row)}>Add</MDBBtn></td>
                                                </tr>)
                                            })}

                                        </MDBTableBody>
                                    </MDBTable>

                                    }

                                </MDBCol>
                            </MDBRow>

                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={()=>{toggleShow()}}>Cancel</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>





    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
