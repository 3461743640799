import React, {useEffect, useState} from 'react';
import {
    MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardText, MDBCardTitle, MDBCol, MDBContainer,
    MDBIcon, MDBRow, MDBSpinner,
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";




export function Page({user}) {
    let navigate = useNavigate();
    const [packages, setPackages] = useState([]);
    const params= useParams()

    const GET_PACKAGES = gql`
         query Get($id: Long!) {
            packages(entityId: $id){
                id
                name
                is_submitted
                created_by
                created
                is_submitted
            }
          }
        `;

    const [getPackages, { loading, error, data }] = useLazyQuery(GET_PACKAGES);




    useEffect(()=> {
        //let's get accounts

        getPackages({
            variables:{id: Number(params.entity_id)},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {




                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }

                let rows = [].concat(result?.data?.packages)
                    .map((row) => {
                        return {
                            ...row,
                            createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                            action: (<Link to={"/client/entity/" + params.entity_id + '/package/' + row.id}><MDBBtn>View</MDBBtn></Link>)
                        }
                    });

                setPackages(rows);

            })

    }, [user]);

    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);

    return (

        <MDBContainer>
            <MDBRow className="m-4">
                <MDBCol className="text-center">
                    <h3>Packages</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                {packages.length === 0 && <span>No Packages Found</span>}

                {packages.map( (row,id) => {
                    return(
                        <MDBCol sm={4} key={id} className="h-100 p-2 ">
                            <MDBCard alignment='center' className="hover-shadow border ">
                                <MDBCardHeader>{row.name}</MDBCardHeader>
                                <MDBCardBody>
                                    <MDBCardText>Status: {row.is_submitted ? "Submitted": "Not Submitted"}</MDBCardText>
                                    {row.action}
                                </MDBCardBody>
                                <MDBCardFooter className='text-muted'>Created: {row.createdTsMoment}</MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    )
                })}
            </MDBRow>
        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
