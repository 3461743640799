import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBBtn, MDBDatatable, MDBTable, MDBTableBody, MDBTableHead, MDBTypography} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import eventBus from "../../../Context/EventBus";



export function Page({user}) {
    let navigate = useNavigate();
    const [datatable, setDatatable] = useState({ columns:[{label:"NDC", field:"ndc"},{label:"Name", field: "name"} , {label: "Created", field: "createdTsMoment"}, {label:"Action", field: "action"}], rows:[]})
    const GET_DRUGS = gql`
          query Get($skip: Int!, $take: Int!) {
            clearwayDrugs(skip: $skip, take: $take) {
              totalCount
              items
              {
                ndc
              }
              
            }
          }
        `;
    const [loadDrugs, { loading, error, data }] = useLazyQuery(GET_DRUGS);
    const [pageSize, setPageSize] = useState(20);
    const [skip, setSkip] = useState(0);



    useEffect(()=> {

        loadData();
    }, [user]);


    function loadData() {
        loadDrugs({
            variables:{
              skip: skip,
              take: pageSize
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }
                setDatatable({
                    columns: datatable.columns,
                    rows: [].concat(result?.data?.clearwayDrugs.items)
                        .map((row) => {

                            return {
                                ...row,
                                // createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                                // action: (<Link to={"/admin/entity/" + row.id}><MDBBtn size={"sm"}>View</MDBBtn></Link>)
                            }
                        })
                });
            })
    }



    return (

        <div >

            <MDBDatatable

                loading={loading}

                data={datatable}

                search={true}
                pagination={true}
                advancedData={true}
            />

        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
