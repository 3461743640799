import React, {useEffect, useState} from 'react';
import instructions from "../../../Images/data-portal-instructions-headline.png";
import {
    MDBAccordion,
    MDBAccordionItem,
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBDatatable,
    MDBIcon,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTypography
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {default as AddPackage} from "./AddEntityPackage";
import {default as AddDrug} from "./AddNDCDrug";
import eventBus from "../../../Context/EventBus";


function MDBrow(props: { children: ReactNode }) {
    return null;
}

export function Page({user}) {
    let navigate = useNavigate();
    const params= useParams()
    const [datatable, setDatatable] = useState({
        columns:[
            {label:"ID", field:"id"},
            {label:"Name", field: "name"} ,
            {label:"Client Submitted",
                field: "status",
                sort: false
            } ,
            {label: "Created", field: "createdTsMoment", sort: false},
            {label:"Action", field: "action", sort: false}
        ], rows:[]})
    const GET_PACKAGES = gql`
         query Get($id: Long!) {
            packages(entityId: $id){
                id
                is_submitted
                created_by
                created
                name
                is_submitted
            }
          }
        `;
    const [getPackages, { loading, error, data }] = useLazyQuery(GET_PACKAGES);




    eventBus.on("package.create", (data) =>
        loadData()
    );

    useEffect(()=> {
        loadData();

    }, [user]);

    function loadData()
    {
        getPackages({
            variables:{id: Number(params.entity_id)},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }

                setDatatable({
                    columns: datatable.columns,
                    rows: [].concat(result?.data?.packages)
                        .map((row) => {

                            return {
                                ...row,
                                createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                                action: (<Link to={"/admin/entity/" + params.entity_id + '/package/' + row.id}><MDBBtn size={"sm"}>View</MDBBtn></Link>),
                                status: (row.is_submitted ?  <h6><MDBBadge pill color={"success"} className='ms-2' >Yes</MDBBadge></h6>:   <h6><MDBBadge color='info' light pill className='ms-2' >No</MDBBadge></h6>)
                            }
                        })
                });



            })
    }

    return (

        <MDBContainer >


            <MDBRow>

                <MDBCol>
                    <MDBTypography tag='h2'>Packages
                        <span className="float-end"><AddPackage/></span>
                    </MDBTypography>
                    <div className='shadow-4 rounded-5 overflow-hidden'>
                        <MDBDatatable
                            loading={loading}
                            data={datatable}
                            search={true}
                            pagination={true}
                            advancedData={true}
                        />

                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
