import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBSwitch,
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import CountUp from 'react-countup';

import {default as DataQuality} from "../../Components/PackageDataQuality";
import {default as RunChecks} from "../../Components/RunPackageDataQualityChecksBtn";
import {default as FileListing} from "../../Components/PackageFileListing";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {MenuItem, Typography} from "@mui/material";
import eventBus from "../../../../Context/EventBus";




const GET_FILES = gql`
  query Get($entityId: Long!, $skip: Int!, $take: Int!, $search: String!) {
    files(entityId: $entityId, skip: $skip, take: $take, search: $search) {
      totalCount
      items
      {
        id
        name
        package_id
        size
        uploaded_by
        uploaded_ts
        extension
        mime_type
        row_count
        approved
        status
      }
      
    }
  }
`;


const SET_APPROVAL_STATUS = gql`
mutation set($entityId: Long!, $fileId: String!, $approved: Boolean!)
{
    setFileApprovalStatus( entityId: $entityId, fileId: $fileId, approved: $approved) {
        successful
    }
}
`;


const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()
    const fetchSize = 25;
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getFiles,{error, loading}] = useLazyQuery(GET_FILES);
    const [setDbApprovalStatus] = useMutation(SET_APPROVAL_STATUS);

    const [totalRows, setTotalRows] = useState(0);
    const [TableData, setTableData] = useState([]);


    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            size: '70',
            Cell: ({ cell }) => (
                <Link to={'/admin/entity/'+params.entity_id+'/file/'+cell.getValue()}>{cell?.getValue()}</Link>
            ),
        },

        {
            accessorKey: 'status',
            header: 'Processing Stage',
            size: '80',
            Cell: ({ cell }) => {
                if(cell.getValue() === "error") return (<MDBBadge pill className='mx-2' color='danger' light>Error</MDBBadge>)
                if(cell.getValue() === "complete") return (<MDBBadge pill className='mx-2' color='success' light>Complete</MDBBadge>)
                if(cell.getValue() === "unprocessable") return (<MDBBadge pill className='mx-2' color='success' light>Unprocessable</MDBBadge>)
                if(cell.getValue() === "processing") return (<MDBBadge pill className='mx-2'  light>Processing</MDBBadge>)
                if(cell.getValue() === "queued") return (<MDBBadge pill className='mx-2'  light>Queued</MDBBadge>)
                if(cell.getValue() === "finalizing") return (<MDBBadge pill className='mx-2'  light>Finalizing</MDBBadge>)
                if(cell.getValue() === "deleted") return (<MDBBadge pill className='mx-2'  light>Deleted</MDBBadge>)
                if(cell.getValue() === "delete requested") return (<MDBBadge pill className='mx-2'  light>Deleting</MDBBadge>)

                else return <span>{cell.getValue()}</span>
            }
        },
        {
            accessorKey: 'approved',
            header: 'Approved',
            size: '80',

            Cell: ({cell}) => (<div ><MDBSwitch   onChange={() => { setApprovalStatus(cell.row, !cell.getValue()) ;}}  checked={cell.getValue()}  /></div>)

           // Cell: ({ cell }) => (<span>{cell.getValue()? <MDBBadge pill className='mx-3'  color='success' light>Yes</MDBBadge>:<MDBBadge pill className='mx-3'  light>No</MDBBadge>}</span>),
        },
        {
            accessorKey: 'row_count',
            header: 'Rows',
            size: '70',
            Cell: ({ cell }) => (cell.getValue().toLocaleString("en-US")),
        },
        {
            accessorKey: 'uploaded_ts',
            header: 'Uploaded',
            size: '100',

            Cell: ({ cell }) => (
                <Moment utc local format="MM/DD/YYYY ">{cell?.getValue()}</Moment>
            ),
        },
        {
            accessorKey: 'name',
            header: 'File Name',
            minSize: '200',
        },





    ];



    eventBus.on("entity.file_listing.update_row", (row) =>{
        let index = TableData.findIndex((line) => line.id === row.id);
        var tempTableData = [...TableData];
        tempTableData[index] = row;
        setTableData(tempTableData);
    });


    function setApprovalStatus(row, approved)
    {
        let fileId = row.original.id;


        setDbApprovalStatus({
            variables: {
                fileId: fileId,
                approved: approved,
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Updated File " + fileId);

                let values = row.original;
                values.approved = approved;

                eventBus.dispatch("entity.file_listing.update_row", values);
            })

    }


    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {

                //protections from constant query
                if(pageParam * fetchSize > totalRows) return [];

                let dd = await getFiles({
                    variables: {
                        entityId: Number(params.entity_id),
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        search: globalFilter ?? ""
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then((results)=> {
                    setTotalRows(results.data.files.totalCount ?? 0)
                    setTableData(Array.from(new Set([...TableData, ...results.data?.files?.items])));
                })
                return [];
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: false,
            refetchOnWindowFocus: false,

        });


    //
    //
    // const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
    //     useInfiniteQuery({
    //         queryKey: ['table-data', columnFilters, globalFilter, sorting],
    //         queryFn: async ({ pageParam = 0 }) => {
    //
    //
    //             let fd = await getFiles({
    //                 variables: {
    //                     entityId: Number(params.entity_id),
    //                     skip: pageParam * fetchSize,
    //                     take: fetchSize,
    //                     search: globalFilter ?? ""
    //                 },
    //                 context:{
    //                     headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
    //                 }
    //             })
    //                 .then();
    //
    //
    //
    //
    //             setTotalRows(fd.data.files.totalCount ?? 0)
    //
    //
    //             return fd?.data?.files?.items;
    //
    //
    //         },
    //         getNextPageParam: (_lastGroup, groups) => groups.length,
    //         keepPreviousData: false,
    //         refetchOnWindowFocus: false,
    //     });
    //
    //



    const fetchMoreOnBottomReached = useCallback(

        async (containerRefElement) => {
            //  console.log("Loading More Data");

            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;

                //  console.log( scrollHeight , scrollTop , clientHeight, isFetching,  TableData.length , totalRows )

                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    TableData.length < totalRows
                ){
                    console.log("fetching more data");
                    await fetchNextPage();
                }


            }
        },
        [fetchNextPage, isFetching, totalRows, TableData],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {if (virtualizerInstanceRef.current) {virtualizerInstanceRef.current.scrollToIndex(0);}}, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {fetchMoreOnBottomReached(tableContainerRef.current).then();}, [fetchMoreOnBottomReached]);




    return (
        <>

            <MaterialReactTable
                columns={columns}
                data={TableData}

                enableColumnFilters={false}
                enablePagination={false}
                enableGlobalFilter={true}
                enableFilters={true}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}

                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {totalRows.toLocaleString("en-US")} total rows.
                    </Typography>
                )}


                renderTopToolbarCustomActions={() => <Typography variant="h5" className="m-3">Files</Typography>}


                enableDensityToggle={false}
                initialState={{ density: 'compact' }}



                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                //rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
                rowVirtualizerProps={{ overscan: 2 }}
            />
        </>
    );
};






const queryClient = new QueryClient();


export function Page({user}) {
    const params= useParams()

    return (

        <MDBContainer >

            <MDBBreadcrumb >
                <MDBBreadcrumbItem>
                    <Link to={"/admin/home"}>Admin</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entity</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem active>
                    Files
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>


            <QueryClientProvider client={queryClient}>
                <DrugTable user={user} />
            </QueryClientProvider>

        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
