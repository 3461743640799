import React, {useEffect, useState} from 'react';
import instructions from "../../../Images/data-portal-instructions-headline.png";
import {
    MDBIcon, MDBRow, MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";



export function Page({user}) {
    return (

        <>

            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Quality Check</th>
                        <th scope='col'>Total Records</th>
                        <th scope='col'>To Review Records</th>

                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    <tr>
                        <td>Patient Checks</td>
                        <td><MDBSpinner animation="grow"  size={"sm"} variant="primary" /></td>
                        <td><MDBSpinner animation="grow" size={"sm"} variant="primary" /></td>
                    </tr>
                    <tr>
                        <td>Insurance Checks</td>
                        <td>100</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Drug Checks</td>
                        <td>100</td>
                        <td><MDBIcon far size={"2xl"} color={"success"}  icon="check-circle" /></td>
                    </tr>
                    <tr>
                        <td>Prescriber Checks</td>
                        <td>100</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Department Checks</td>
                        <td>100</td>
                        <td>100</td>
                    </tr>
                </MDBTableBody>
            </MDBTable>


        </>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
