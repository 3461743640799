import React, {useEffect, useState} from 'react';
import instructions from "../../../Images/data-portal-instructions-headline.png";
import {
    MDBAccordion,
    MDBAccordionItem,
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBDatatable,
    MDBIcon,
    MDBRow,
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";



export function Page({user}) {
    let navigate = useNavigate();
    const params= useParams()

    const [datatable, setDatatable] = useState(
        {
            columns:[
                {label:"Indicator", field: "passed_icon", sort: false},
                {label:"Name", field: "name", width:500} ,
                {label:"Failed", field: "percent_fancy", sort: false},
                {label:"Threshold", field: "check_percent_threshold", sort: false},

                {label:"Status", field: "passed", isVisible:false, width: 20, hidden: true, visible: false}

            ], rows:[]})
    const GET_DQCHECK_RESULTS = gql`
          query Get($entityId: Long!, $packageId: Long!) {
            packageDataQuality(entityId: $entityId, packageId: $packageId) {
           
              
              check_id
              checked_ts
              failed_rows
              total_rows
              passed
              check {
                title
                percent_threshold
              }
              
            }
          }
        `;
    const [getDqCheckResults, { loading, error, data }] = useLazyQuery(GET_DQCHECK_RESULTS);


    useEffect(()=> {
        getDqCheckResults({
            variables: {
                entityId: Number(params.entity_id),
                packageId: Number(params.package_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {



                setDatatable({
                    columns: datatable.columns,
                    rows: [].concat(result?.data?.packageDataQuality)
                        .map((row) => {

                            return {
                                ...row,
                                name: row.check?.title ?? "Unknown",
                                checkedTsMoment: (<Moment utc format={"MM/DD/YYYY hh:mm a"} local>{row.checked_ts}</Moment>),
                                percent: row.failed_rows > 0 ?  Number((row.failed_rows / row.total_rows)* 100).toLocaleString("en-US")  : Number(0) ,
                                percent_fancy: row.failed_rows > 0 ?  <span>{Math.round(Number((row.failed_rows / row.total_rows)* 100).toLocaleString("en-US",2))}%</span> : <span>{Number(0)}%</span> ,


                                check_percent_threshold: <span>{row.check.percent_threshold}%</span>,
                                passed: row.passed ? "passed":"failed",
                                passed_icon: row.passed ? <MDBIcon far size={"2xl"} color={"success"}  icon="check-circle"  />:<MDBIcon fas size={"2xl"} color={"danger"}  icon="exclamation-circle" />
                            }
                        })
                });
            })

    }, [user]);





    return (

        <>
            <MDBDatatable
                sortField='Status'
                sortOrder={"asc"}
                loading={loading}
                data={datatable}
                fullPagination={false}
                search={false}
                paging={false}
                style={{wordBreak:"break-all"}}
                entries={25}

                advancedData={true}
                sm={true}


            />

        </>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
