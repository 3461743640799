import React, {useCallback, useEffect, useRef, useState} from 'react';
import {MDBBadge, MDBBtn, MDBDatatable, MDBTable, MDBTableBody, MDBTableHead, MDBTypography} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import eventBus from "../../../Context/EventBus";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {Box, Button, MenuItem, Typography} from "@mui/material";



const GET_DRUGS = gql`
          query Get($skip: Int!, $take: Int!, $search: String!) {
            drugs(skip: $skip, take: $take, search: $search) {
              totalCount
              items
              {
                ndc
                gpi
                dosage_form
                name
                strength_unit_of_measure
                strength
                imported_ts
              }
              
            }
          }
        `;


const columns = [
    {
        accessorKey: 'ndc',
        header: 'NDC',
        Cell: ({ cell }) => (
            <Link to={'/admin/drug/'+cell.getValue()}>
                {cell?.getValue()}
            </Link>
        ),
    },
    {
        accessorKey: 'name',
        header: 'Drug Name',
    }

];

const fetchSize = 25;

const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()

    const [pagedata, setPagedata] = useState([]);

    const [totalRows, setTotalRows] = useState(0);


    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);

    const [getDrugs,{error}] = useLazyQuery(GET_DRUGS);




    const { data, fetchNextPage, isError, isFetching, isLoading } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {


                getDrugs({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        search: globalFilter ?? ""
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                })
                    .then( (result) => {



                        setPagedata(Array.from(new Set(pagedata.concat(result?.data?.drugs.items))))
                        setTotalRows(result?.data?.drugs.totalCount ?? 0)
                    })

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: false,
            refetchOnWindowFocus: true,
        });





    const totalDBRowCount = totalRows ?? 0;
    const totalFetched = pagedata?.length ?? 0;

    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 300 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    return (
        <>

            <MaterialReactTable
                columns={columns}
                data={pagedata}
                enableColumnFilters={false}
                enablePagination={false}
                enableGlobalFilter={true}
                enableFilters={true}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                // enableRowActions
                // renderRowActionMenuItems={({ row }) => [
                //     <MenuItem key="edit" onClick={() => console.info('Edit')}>
                //         Edit
                //     </MenuItem>,
                //     <MenuItem key="delete" onClick={() => console.info('Delete')}>
                //         Delete
                //     </MenuItem>,
                // ]}


                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                // renderBottomToolbarCustomActions={() => (
                //     <Typography>
                //         Fetched {totalFetched} of {totalDBRowCount} total rows.
                //     </Typography>
                // )}

                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {totalDBRowCount} total rows.
                    </Typography>
                )}

                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                //rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
                rowVirtualizerProps={{ overscan: 2 }}
            />
        </>
    );
};






const queryClient = new QueryClient();


export function Page({user}) {
    return (

        <div >
            <QueryClientProvider client={queryClient}>
                <DrugTable user={user} />
            </QueryClientProvider>

        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
