import React, { useState, useCallback, useEffect } from 'react';
import {

    MDBIcon,
    MDBContainer,

    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBBreadcrumbItem, MDBBreadcrumb, MDBDatatable, MDBBtn, MDBTypography,
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";

const GET_ORGANIZATION = gql`
  query Get($id: Long!) {
    organization(organizationId: $id) {
      id
      name
      created
      updated
    }
    entities(organizationId: $id){
        id
        name
        active
        created
    
    }
   
  }
`;



export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()




    const { loading, error, data, refetch } = useQuery(GET_ORGANIZATION, {
        variables:{
            id: Number(params.organization_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });








    const [verticalActive, setVerticalActive] = useState('entities');

    const handleVerticalClick = (value: string) => {
        if (value === verticalActive) {
            return;
        }

        setVerticalActive(value);
    };

    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <MDBContainer >
            <MDBRow className='justify-content-center'>
                <MDBCol sm={10} >

                    <MDBBreadcrumb className='p-1 '>
                        <MDBBreadcrumbItem>
                            <Link to={"/admin/home"}>Admin</Link>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem>
                            <Link to={"/admin/organization"}>Organization</Link>
                        </MDBBreadcrumbItem>

                        <MDBBreadcrumbItem active>{data?.organization.name} </MDBBreadcrumbItem>
                    </MDBBreadcrumb>

                    <MDBRow>
                        <MDBCol className="text-center p-2">
                            <MDBTypography tag='h2'>{data?.organization.name}</MDBTypography>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow >
                        <MDBTabs className='mb-3' >

                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleVerticalClick('entities')} active={verticalActive === 'entities'}>
                                    <MDBIcon far icon='hospital' className='me-2' /> Entities
                                </MDBTabsLink>
                            </MDBTabsItem>
                            {/*<MDBTabsItem>*/}
                            {/*    <MDBTabsLink onClick={() => handleVerticalClick('npi')} active={verticalActive === 'npi'}>*/}
                            {/*        <MDBIcon fas icon='table' className='me-2' /> NPI*/}
                            {/*    </MDBTabsLink>*/}
                            {/*</MDBTabsItem>*/}

                        </MDBTabs>



                        <MDBTabsContent >
                            <MDBTabsPane open={verticalActive === 'entities'} >

                                <MDBDatatable

                                    loading={loading}

                                    data={{columns: [{label:"Entity Id", field:"id"},{label:"Name", field: "name"} , {label: "Created", field: "createdTsMoment"}, {label:"Action", field: "action"}]
                                        , rows:data.entities.map( (row) => {
                                            return {
                                                ...row,
                                                createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                                                action: (<Link to={"/admin/entity/" + row.id}><MDBBtn size={"sm"}>View</MDBBtn></Link>)
                                            }
                                        })}}

                                    search={true}
                                    pagination={true}
                                    advancedData={true}
                                />

                            </MDBTabsPane>

                            <MDBTabsPane open={verticalActive === 'npi'}>Coming Soon</MDBTabsPane>
                        </MDBTabsContent>


                    </MDBRow>


                </MDBCol>


            </MDBRow>


        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
