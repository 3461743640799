
import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBBtn, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";



export function Page({user, redirect}) {
    let navigate = useNavigate();
    const [entities, setEntities] = useState([]);


    const GET_ENTITIES = gql`
          query Get {
            entities {
              id
              name
              created
              updated
            }
          }
        `;

    const [getEntities, { loading, error, data }] = useLazyQuery(GET_ENTITIES);




    useEffect(()=> {
        //let's get accounts

        getEntities({ context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }

                if(redirect && result.data.entities.length === 1)
                {
                    navigate("/client/entity/"+result.data.entities[0].id)
                }


                let rows = [].concat(result?.data?.entities)
                    .map((row) => {
                        return {
                            ...row,
                            createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                            action: (<Link to={"/client/entity/" + row.id}><MDBBtn>View</MDBBtn></Link>)
                        }
                    });

                setEntities(rows);
            })
            .catch((error) => {
                NotificationManager.error("Api Error")
            })

    }, [user]);


    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    return (

        <div >

            <MDBTable className="table-hover ">
                <MDBTableHead style={{backgroundColor:"#8776ec", color:'white'}}>
                    <tr>
                        <th scope='col'>Entity Name</th>
                        {/*<th scope='col'>Status</th>*/}
                        <th scope='col'>Created</th>
                        <th scope='col'>Actions</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {entities.map( (row,id) => {
                        return(
                            <tr key={id} onClick={() => navigate("/client/entity/" + row.id)}>
                                <td><span>{row.name}</span></td>


                                {/*<td>*/}
                                {/*    /!*{row.status === "Built" &&  <MDBBadge color='success' pill>{row.status}</MDBBadge>}*!/*/}
                                {/*    /!*{row.status === "Building" &&  <MDBBadge color='warning' pill>{row.status}</MDBBadge>}*!/*/}
                                {/*    /!*{row.status === "Not Built" &&  <MDBBadge color='danger' pill>{row.status}</MDBBadge>}*!/*/}
                                {/*    TBD*/}
                                {/*</td>*/}
                                <td>{row.createdTsMoment}</td>
                                <td>{row.action}</td>
                            </tr>
                        )
                    })}



                </MDBTableBody>
            </MDBTable>


        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
