import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import MaterialReactTable from "material-react-table";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBDatatable,
    MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalHeader, MDBModalTitle,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBModalDialog
} from "mdb-react-ui-kit";
import {Button} from "@mui/material";


export function Page({user}) {

    const [ optSmModal, setOptSmModal ] = useState(false);
    const [ selectedTable, setSelectedTable ] = useState(false);
    const [ selectedTableColumns, setSelectedTableColumns ] = useState([]);

    const toggleShow = () => setOptSmModal(!optSmModal);


    function showEdit(table, columns) {
        setSelectedTable(table);
        setSelectedTableColumns(columns);
        setOptSmModal(true);
    }

    //    <th scope='row' >{row.name}</th>
    //                                             <td>{row.type}</td>
    //                                             <td>{row.description}</td>

    const tableData = [
        {
            name: "packages",
            description: "A table to hold the packages configured for an Entity",
            version: "1",
            type: "Entity Specific",
            columns: [
                {
                    name: "id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "created",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "updated",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "submitted_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "is_submitted",
                    type:"bit",
                    description: ""
                },
                {
                    name: "created_by",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "name",
                    type:"varchar",
                    description: ""
                }

            ]


        },
        {
            name: "clearway_drug",
            description: "A table to represent the clearway master filter table ",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "ndc",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "classification_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "is_orphaned",
                    type:"bit",
                    description: ""
                },
                {
                    name: "created_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "updated_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "drug_group_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "tier_id",
                    type:"int",
                    description: ""
                }
            ]
        },
        {
            name: "entities",
            description: "A table to hold all the entities",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "name",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "created",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "updated",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "organization_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "npi_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "active",
                    type:"bit",
                    description: ""
                },
            ]
        },
        {
            name: "stage_pharmacy",
            description: "Holds the staging of pharmacy informatino from raw rows",
            version: "1",
            type: "Entity Staging",
            columns: [
                {
                    name: "file_id",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "row_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "pharmacy_id",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "imported_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "address_1",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "address_2",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "npi",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "city",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "state",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "zip_code",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "zip_region",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "failed_validation",
                    type:"bit",
                    description: ""
                },
                {
                    name: "package_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "ch_pharmacy_id",
                    type:"bigint",
                    description: ""
                },
            ]
        },
        {
            name: "stage_department",
            description: "Holds the staging of department information from raw rows. ",
            version: "1",
            type: "Entity Specific",
            columns: [
                {
                    name: "department_id",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "file_id",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "row_id",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "imported_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "address_1",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "address_2",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "city",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "state",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "zip_code",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "zip_region",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "failed_validation",
                    type:"bit",
                    description: ""
                },
                {
                    name: "participating_340b_start_date",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "package_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "is_340b",
                    type:"bit",
                    description: ""
                },
                {
                    name: "id_340b",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "ch_department_id",
                    type:"bigint",
                    description: ""
                },
            ]
        },
        {
            name: "stage_provider",
            description: "Holds the staging of provider information from raw rows.",
            version: "1",
            type: "Entity Staging",
            columns: [
                {
                    name: "file_id",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "row_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "first_name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "imported_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "npi",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "last_name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "failed_validation",
                    type:"bit",
                    description: ""
                },
                {
                    name: "package_id",
                    type:"bigint",
                    description: ""
                },
            ]
        },
        {
            name: "stage_patient",
            description: "Holds the staging of patient information from raw rows.",
            version: "1",
            type: "Entity Staging",
            columns: [
                //file_id	nvarchar
                // row_id	bigint
                // patient_id	nvarchar
                // first_name	nvarchar
                // last_name	nvarchar
                // middle_name	nvarchar
                // date_of_birth	nvarchar
                // phone_number	nvarchar
                // address_1	nvarchar
                // address_2	nvarchar
                // city	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // sex	nvarchar
                // gender	nvarchar
                // imported_ts	datetime
                // zip_region	nvarchar
                // failed_validation	bit
                // ch_patient_id	nvarchar
                // package_id	bigint
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "stage_prescription",
            description: "Holds the staging of prescription information from raw rows.",
            version: "1",
            type: "Entity Staging",
            columns: [
                //file_id	nvarchar
                // row_id	bigint
                // prescription_id	nvarchar
                // quantity	nvarchar
                // refill	nvarchar
                // method	nvarchar
                // status	nvarchar
                // rx_sent_ts	nvarchar
                // imported_ts	datetime
                // failed_validation	bit
                // prescribed_form	nvarchar
                // ch_dupe_id	nvarchar
                // active	bit
                // ch_refill	int
                // package_id	bigint
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "package_dq_check",
            description: "Holds the saved DQ check results",
            version: "1",
            type: "Entity Specific",
            columns: [
                {
                    name: "package_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "check_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "total_rows",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "failed_rows",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "passed",
                    type:"bit",
                    description: ""
                },
                {
                    name: "checked_ts",
                    type:"datetime",
                    description: ""
                },
            ]
        },
        {
            name: "data_quality_check",
            description: "Holds a listing of the data quality checks and the thresholds",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "title",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "percent_threshold",
                    type:"int",
                    description: ""
                },
                {
                    name: "service",
                    type:"varchar",
                    description: ""
                },
            ]
        },
        {
            name: "npi_downloads",
            description: "Holds a list of all the NPI auto downloads",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "year",
                    type:"int",
                    description: ""
                },
                {
                    name: "month",
                    type:"int",
                    description: ""
                },
                {
                    name: "download_url",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "records",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "synced",
                    type:"bit",
                    description: ""
                },
            ]
        },
        {
            name: "covered_entity_340b",
            description: "Holds all covered entities from 340b",
            version: "1",
            type: "Global",
            columns: [
                // {
                //     name: "test",
                //     type:"test",
                //     description: "desc"
                // }
            ]
        },
        {
            name: "lookup_group_name",
            description: "Holds a group of lookup values.",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "group_name",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "active",
                    type:"bit",
                    description: ""
                },
                {
                    name: "created_by",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "created_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "updated_by",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "updated_ts",
                    type:"datetime",
                    description: ""
                },
            ]
        },
        {
            name: "lookup_group_value",
            description: "Values in lookup groups to ensure consistency. ",
            version: "1",
            type: "Global",
            columns: [
                //id	bigint
                // active	bit
                // created_by	varchar
                // created_ts	datetime
                // group_name	varchar
                // updated_by	varchar
                // updated_ts	datetime
                // value	varchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "price_drug",
            description: "Drug pricing table for revenue analysis.",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "created_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "updated_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "ndc",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "price",
                    type:"decimal",
                    description: ""
                },
            ]
        },
        {
            name: "drug",
            description: "Global drug listing from NDC",
            version: "1",
            type: "Global",
            columns: [
                //generic_name	nvarchar
                // ndc	nvarchar
                // name	nvarchar
                // gpi	nvarchar
                // dosage_form	nvarchar
                // strength	nvarchar
                // strength_unit_of_measure	nvarchar
                // imported_ts	datetime
                // combined_name_1	nvarchar
                // combined_name_2	nvarchar
                // combined_name_3	nvarchar
                // combined_name_4	nvarchar
                // classification	nvarchar
                // route_of_administration	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "class_pharmacy",
            description: "Holds the pharmacy classing information.",
            version: "1",
            type: "Entity Classing",
            columns: [
                //address_2	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // npi	nvarchar
                // type_id	bigint
                // updated_ts	datetime
                // id	bigint
                // name	nvarchar
                // status_id	bigint
                // city	nvarchar
                // address_1	nvarchar
                // zip_region	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "insurance",
            description: "Holds the global insurance listing",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "type_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "updated_ts",
                    type:"datetime",
                    description: ""
                },
            ]
        },
        {
            name: "stage_insurance",
            description: "Holds the insurance staging information found from the raw rows.",
            version: "1",
            type: "Entity Staging",
            columns: [
                //secondary_insurance_id	nvarchar
                // secondary_insurance_name	nvarchar
                // ch_insurance_id	bigint
                // client_insurance_id	nvarchar
                // package_id	bigint
                // file_id	nvarchar
                // row_id	bigint
                // name	nvarchar
                // client_type	nvarchar
                // imported_ts	datetime
                // failed_validation	bit
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "covered_entity_pharmacy_340b",
            description: "A table to hold covered entity pharmacy from 340b ",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "class_department",
            description: "Holds the department classing information.",
            version: "1",
            type: "Entity Classing",
            columns: [
                //id	bigint
                // address_1	nvarchar
                // address_2	nvarchar
                // city	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // zip_region	nvarchar
                // name	nvarchar
                // id_340b	nvarchar
                // is_340b	bit
                // updated_ts	datetime
                // status_id	bigint
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "insurance_common_name",
            description: "Holds the common names for insurance companies.",
            version: "1",
            type: "Global",
            columns: [
                //id	bigint
                // name	nvarchar
                // active	bit
                // created_ts	datetime
                // updated_ts	datetime
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "stage_drug",
            description: "Holds the drug data found within the uploaded data row",
            version: "1",
            type: "Entity Staging",
            columns: [
                //failed_validation	bit
                // file_id	nvarchar
                // row_id	bigint
                // package_id	bigint
                // name	nvarchar
                // ndc	nvarchar
                // imported_ts	datetime
                // ch_drug_id	bigint
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "package_review",
            description: "Holds the client entered information for a review request.",
            version: "1",
            type: "Entity Specific",
            columns: [
                {
                    name: "id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "submitted_by",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "submitted_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "comments",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "package_id",
                    type:"bigint",
                    description: ""
                },
            ]
        },
        {
            name: "file_processor",
            description: "Manages the file processing queue.",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "server_id",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "locked_till",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "entity_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "file_id",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "attempts",
                    type:"int",
                    description: ""
                },
            ]
        },
        {
            name: "hrsa_covered_entity",
            description: "Holds HRSA covered entity information",
            version: "1",
            type: "Global",
            columns: [
                //covered_entity_id	bigint
                // pharmacy_name	nvarchar
                // entity_name	nvarchar
                // division_name	nvarchar
                // participating	bit
                // pharmacy_address_1	nvarchar
                // pharmacy_address_2	nvarchar
                // pharmacy_city	nvarchar
                // pharmacy_state	nvarchar
                // pharmacy_zip_code	nvarchar
                // pharmacy_zip_region	nvarchar
                // npi_list	nvarchar
                // last_edited	datetime
                // id_340b	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "organizations",
            description: "Holds the organizations",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "created",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "datetime",
                    type:"test",
                    description: ""
                },
                {
                    name: "slug",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "is_created",
                    type:"bit",
                    description: ""
                },
            ]
        },
        {
            name: "taxonomy_downloads",
            description: "Manages the taxonomy auto download and update capability.",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "download_url",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "month",
                    type:"int",
                    description: ""
                },
                {
                    name: "records",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "synced",
                    type:"bit",
                    description: ""
                },
                {
                    name: "year",
                    type:"int",
                    description: ""
                },
            ]
        },
        {
            name: "files",
            description: "Holds a list of all the files uploaded.",
            version: "1",
            type: "Entity Specific",
            columns: [
                //package_id	bigint
                // id	nvarchar
                // mime_type	nvarchar
                // name	nvarchar
                // extension	nvarchar
                // size	bigint
                // uploaded_ts	datetime
                // uploaded_by	nvarchar
                // row_count	bigint
                // time_to_process_seconds	bigint
                // approved	bit
                // status	nvarchar
                // process_ts	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "file_data",
            description: "Holds the raw file data from the client",
            version: "1",
            type: "Entity Staging",
            columns: [
                //file_id	nvarchar
                // row_id	bigint
                // data	nvarchar
                // imported_ts	datetime
                // file_id	nvarchar
                // column_name	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "npi_registry",
            description: "Holds all the npi registry",
            version: "1",
            type: "Global",
            columns: [
                //npi	nvarchar
                // entity_type_code	char
                // organization_name	nvarchar
                // organization_other_name	nvarchar
                // parent_organization	nvarchar
                // first_name	nvarchar
                // last_name	nvarchar
                // middle_name	nvarchar
                // name_prefix	nvarchar
                // name_suffix	nvarchar
                // credential	nvarchar
                // address_1	nvarchar
                // address_2	nvarchar
                // city	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // phone_number	nvarchar
                // provider_enumeration_date	datetime
                // deactivation_date	datetime
                // reactivation_date	datetime
                // certification_date	datetime
                // updated_ts	datetime
                // created_ts	datetime
                // zip_region	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "npi_taxonomy",
            description: "Holds npi taxonomy mapping ",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "npi",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "sequence",
                    type:"int",
                    description: ""
                },
                {
                    name: "taxonomy_code",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "taxonomy_switch",
                    type:"bit",
                    description: ""
                },
                {
                    name: "updated_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "created_ts",
                    type:"datetime",
                    description: ""
                },
            ]
        },
        {
            name: "taxonomy",
            description: "Holds taxonomy information.",
            version: "1",
            type: "Global",
            columns: [
                {
                    name: "taxonomy_code",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "grouping",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "classification",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "specialization",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "definition",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "notes",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "display_name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "section",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "updated_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "created_ts",
                    type:"datetime",
                    description: ""
                },
            ]
        },
        {
            name: "class_insurance",
            description: "Holds the insurance classing information ",
            version: "1",
            type: "Entity Classing",
            columns: [
                {
                    name: "client_type",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "global_insurance_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "type_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "updated_ts",
                    type:"datetime",
                    description: ""
                },
                {
                    name: "status_id",
                    type:"bigint",
                    description: ""
                },
            ]
        },
        {
            name: "class_drug",
            description: "Holds the drug classing information",
            version: "1",
            type: "Entity Classing",
            columns: [
                {
                    name: "id",
                    type:"bigint",
                    description: "desc"
                },
                {
                    name: "name",
                    type:"nvarchar",
                    description: "desc"
                },
                {
                    name: "ndc",
                    type:"nvarchar",
                    description: "desc"
                },
                {
                    name: "ldd_classification_id",
                    type:"test",
                    description: "bigint"
                },
            ]
        },
        {
            name: "contract_entity_340b",
            description: "A table to hold the 340b entities",
            version: "1",
            type: "Global",
            columns: [
                // {
                //     name: "test",
                //     type:"test",
                //     description: "desc"
                // }
            ]
        },
        {
            name: "contract_entity_pharmacy_340b",
            description: "A table to hold the 340b entities ",
            version: "1",
            type: "Global",
            columns: [
                // {
                //     name: "test",
                //     type:"test",
                //     description: "desc"
                // }
            ]
        },
        {
            name: "OpenIddictApplications",
            description: "A table to handle the authentication jwt signing",
            version: "1",
            type: "Global",
            columns: [
                //Id	nvarchar
                // ClientId	nvarchar
                // ClientSecret	nvarchar
                // ConcurrencyToken	nvarchar
                // ConsentType	nvarchar
                // DisplayName	nvarchar
                // DisplayNames	nvarchar
                // Permissions	nvarchar
                // PostLogoutRedirectUris	nvarchar
                // Properties	nvarchar
                // RedirectUris	nvarchar
                // Requirements	nvarchar
                // Type	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: "desc"
                }
            ]
        },
        {
            name: "field_mapping",
            description: "A table to hold a mapping of custom fields to the internal schemas",
            version: "1",
            type: "Entity Specific",
            columns: [
                {
                    name: "field_lookup_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "custom_field",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "priority",
                    type:"int",
                    description: ""
                },
            ]
        },
        {
            name:"v_template",
            description: "",
            version: "1",
            type: "Entity view",
            columns: [
                //ch_patient_id	nvarchar
                // patient_id	nvarchar
                // insurance_name	nvarchar
                // insurance_class	varchar
                // prescription_id	nvarchar
                // ndc	nvarchar
                // drug_name	nvarchar
                // revenue_per_drug	decimal
                // avg_rev_per_rx	decimal
                // gpi	nvarchar
                // ch_drug_id	bigint
                // drug_group	varchar
                // tier	varchar
                // classification	nvarchar
                // LDD	varchar
                // quantity	nvarchar
                // refill	nvarchar
                // ch_refill	int
                // prescriber	nvarchar
                // prescriber_npi	nvarchar
                // department_id	nvarchar
                // department_name	nvarchar
                // dept_340b_eligible	bit
                // method	nvarchar
                // status	nvarchar
                // rx_sent_ts	datetime
                // pharmacy_name	nvarchar
                // pharmacy_class	varchar
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
            ]
        },
        {
            name:"v_provider",
            description: "",
            version: "1",
            type: "Entity view",
            columns: [
                {
                    name: "first_name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "last_name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "npi",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "failed_validation",
                    type:"bit",
                    description: ""
                },
            ]
        },
        {
            name:"v_prescription",
            description: "",
            version: "1",
            type: "Entity view",
            columns: [
                //prescription_id	nvarchar
                // ch_patient_id	nvarchar
                // patient_id	nvarchar
                // ndc	nvarchar
                // drug_name	nvarchar
                // quantity	nvarchar
                // refill	nvarchar
                // ch_refill	int
                // method	nvarchar
                // status	nvarchar
                // rx_sent_ts	date
                // department_id	nvarchar
                // dept_name	nvarchar
                // prescriber_first_name	nvarchar
                // prescriber_last_name	nvarchar
                // pharmacy_name	nvarchar
                // failed_validation	bit
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
            ]
        },
        {
            name:"v_patient",
            description: "",
            version: "1",
            type: "Entity view",
            columns: [
                //ch_patient_id	nvarchar
                // insurance_name	nvarchar
                // patient_id	nvarchar
                // first_name	nvarchar
                // last_name	nvarchar
                // middle_name	nvarchar
                // date_of_birth	nvarchar
                // phone_number	nvarchar
                // address_1	nvarchar
                // address_2	nvarchar
                // city	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // zip_region	nvarchar
                // sex	nvarchar
                // gender	nvarchar
                // failed_validation	bit

                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
            ]
        },
        {
            name:"v_insurance",
            description: "",
            version: "1",
            type: "Entity view",
            columns: [
                {
                    name: "name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "type",
                    type:"varchar",
                    description: ""
                },
                {
                    name: "failed_validation",
                    type:"bit",
                    description: ""
                },
            ]
        },
        {
            name:"v_drug",
            description: "",
            version: "1",
            type: "Entity view",
            columns: [
                //name	nvarchar
                // ndc	nvarchar
                // gpi	nvarchar
                // dosage_form	nvarchar
                // strength	nvarchar
                // strength_unit_of_measure	nvarchar
                // classification	nvarchar
                // is_orphaned	bit
                // LDD	varchar
                // tier	varchar
                // drug_group	varchar
                // failed_validation	bit
                // specialty_drug	varchar
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
            ]
        },
        {
            name:"v_department",
            description: "",
            version: "1",
            type: "Entity view",
            columns: [
                //department_id	nvarchar
                // name	nvarchar
                // address_1	nvarchar
                // address_2	nvarchar
                // city	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // zip_region	nvarchar
                // failed_validation	bit
                // is_340b	bit
                // id_340b	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
            ]
        },
        {
            name:"v_provider",
            description: "",
            version: "1",
            type: "Global View",
            columns: [
                //npi	nvarchar
                // first_name	nvarchar
                // last_name	nvarchar
                // middle_name	nvarchar
                // address_1	nvarchar
                // address_2	nvarchar
                // city	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // zip_region	nvarchar
                // phone_number	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
            ]
        },
        {
            name:"v_pharmacy",
            description: "",
            version: "1",
            type: "Global View",
            columns: [
                //npi	nvarchar
                // name	nvarchar
                // other_name	nvarchar
                // address_1	nvarchar
                // address_2	nvarchar
                // city	nvarchar
                // state	nvarchar
                // zip_code	nvarchar
                // zip_region	nvarchar
                // taxonomy_id	nvarchar
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
                {
                    name: "test",
                    type:"test",
                    description: ""
                },
            ]
        },
        {
            name:"v_hrsa_340b_pharmacy",
            description: "",
            version: "1",
            type: "Global View",
            columns: [
                {
                    name: "id_340b",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "covered_entity_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "pharmacy_name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "pharmacy_address_1",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "pharmacy_address_2",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "pharmacy_city",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "pharmacy_state",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "pharmacy_zip_code",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "pharmacy_zip_region",
                    type:"nvarchar",
                    description: ""
                },

            ]
        },
        {
            name:"v_hrsa_340b_entity",
            description: "",
            version: "1",
            type: "Global View",
            columns: [
                {
                    name: "id_340b",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "covered_entity_id",
                    type:"bigint",
                    description: ""
                },
                {
                    name: "entity_name",
                    type:"nvarchar",
                    description: ""
                },
                {
                    name: "division_name",
                    type:"nvarchar",
                    description: ""
                },
            ]
        }

    ];


    const columns = useMemo(
        () => [
            {
                accessorKey: 'name_fancy',
                header: 'Table Name',
                size: 100,
            },


            {
                accessorKey: 'type',
                header: 'Type',
                size: 80,
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },


        ],
    );

    //optionally access the underlying virtualizer instance
    const rowVirtualizerInstanceRef = useRef(null);

    const [ data, setData ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ sorting, setSorting ] = useState([]);


    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [ sorting ]);


    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol>
                    <h1>Data Dictionary</h1>

                </MDBCol>

            </MDBRow>
            <MDBRow>
                <MDBCol>

                    <MaterialReactTable
                        columns={columns}
                        data={tableData.map((row) => {
                            return {
                                ...row,
                                name_fancy: <Button className={"btn-flat"}
                                              onClick={() => showEdit(row.name, row.columns)}>{row.name}</Button>,
                            }
                        })} //10,000 rows
                        enableBottomToolbar={false}
                        enableColumnResizing
                        enableColumnVirtualization
                        enableGlobalFilterModes
                        enablePagination={false}
                        enablePinning


                        enableRowVirtualization
                        onSortingChange={setSorting}
                        state={{isLoading, sorting}}
                        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
                        rowVirtualizerProps={{overscan: 5}} //optionally customize the row virtualizer
                        columnVirtualizerProps={{overscan: 2}} //optionally customize the column virtualizer
                        muiToolbarAlertBannerChipProps={{color: 'primary'}}
                        muiTableContainerProps={{sx: {maxHeight: 700}}}
                        initialState={{
                            density: 'compact',
                            expanded: true, //expand all groups by default
                            grouping: [ 'type' ], //an array of columns to group by by default (can be multiple)
                            pagination: {pageIndex: 0, pageSize: 50},
                            sorting: [ {id: 'Type'} ], //sort by state by default
                        }}
                    />

                    {/*<MDBTable small hover className='caption-top'>*/}
                    {/*    <caption>List of Tables</caption>*/}
                    {/*    <MDBTableHead>*/}
                    {/*        <tr>*/}
                    {/*            <th scope='col'>Table Name</th>*/}
                    {/*            <th scope='col'>Type</th>*/}
                    {/*            <th scope='col'>Description</th>*/}
                    {/*            <th scope='col'>Current Version</th>*/}
                    {/*        </tr>*/}
                    {/*    </MDBTableHead>*/}
                    {/*    <MDBTableBody>*/}
                    {/*        {tableData.map( (row, id) => {*/}
                    {/*            return(<tr key={id} onClick={()=>showEdit(row.name)}>*/}
                    {/*                <th scope='row' >{row.name}</th>*/}
                    {/*                <td>{row.type}</td>*/}
                    {/*                <td>{row.description}</td>*/}
                    {/*                <td>{row.version}</td>*/}
                    {/*            </tr>)*/}
                    {/*        })}*/}
                    {/*    </MDBTableBody>*/}
                    {/*</MDBTable>*/}
                </MDBCol>

            </MDBRow>


            <MDBModal open={optSmModal} tabIndex='-1' onClose={()=>setOptSmModal(false)}>
                <MDBModalDialog size='xl'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{selectedTable} Attributes</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}/>
                        </MDBModalHeader>
                        <MDBModalBody>

                            <MDBTable small hover className='caption-top'>
                                <caption>Columns</caption>
                                <MDBTableHead>
                                    <tr>
                                        <th scope='col'>Column Name</th>
                                        <th scope='col'>Type</th>
                                        <th scope='col'>Description</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {selectedTableColumns
                                        .map((row, id) => {
                                            return (<tr key={id}>
                                                <th scope='row'>{row.name}</th>
                                                <td>{row.type}</td>
                                                <td>{row.description}</td>
                                            </tr>)
                                        })}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
