import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {
    Button,
    MenuItem,
    Typography
} from "@mui/material";
import eventBus from "../../../../Context/EventBus";
import {
    MDBBtn, MDBCol,
    MDBIcon, MDBInput,
    MDBModal, MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalHeader,
    MDBModalTitle, MDBRow, MDBSpinner, MDBTable, MDBTableBody
} from "mdb-react-ui-kit";

const GET_LOOKUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;

const GET_PHARMACIES = gql`
  query Get( $skip: Int!, $take: Int!, $entityId: Long!) {
    classPharmacies(entityId: $entityId skip: $skip, take: $take) {
      totalCount
      items
      {
        id
        name
        npi
        type_id
        type{
            value
        }
        address_1
        address_2
        city
        state
        zip_code
        zip_region
         status {
            value
        }
      }
      
    }
  }
`;



const SET_PHARMACY_TYPE_CLASSIFICATION = gql`
mutation set($entityId: Long!, $id: Long!, $typeId: Long!)
{
    setPharmacyClassification(entityId: $entityId, id: $id, typeId: $typeId) {
        successful
    }
}
`;
const GET_NPI_PHARMACIES = gql`
  query Get( $skip: Int!, $take: Int!, $search: String!) {
    pharmacies(search: $search, skip: $skip, take: $take) {
      totalCount
      items
      {
        npi
        name
        other_name
        address_1
        address_2
        city
        state
        zip_code
        type_id
        taxonomy_id
      }
      
    }
  }
`;


const SET_PHARMACY_NPI = gql`
mutation set($entityId: Long!, $id: Long!, $npi: String!)
{
    setPharmacyNpi(entityId: $entityId, id: $id, npi: $npi) {
        successful
    }
}
`;

const CLASSING_CLEANUP = gql`
mutation clean( $entityId: Long!)
{
    cleanPharmacyClass(entityId: $entityId)
  {
      successful
    
  }
} 
`;



const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()
    const fetchSize = 5;
    const [npiModal, setNpiModal] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getPharmacies,{error, loading}] = useLazyQuery(GET_PHARMACIES);
    const [getLookup,{error: getLookupError, loading: getLookupLoading}] = useLazyQuery(GET_LOOKUP);
    const [pharmacyClassingOptions, setPharmacyClassingOptions] = useState([]);
    const [setPharmacyTypeClassification] = useMutation(SET_PHARMACY_TYPE_CLASSIFICATION);

    const [TableData, setTableData] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});
    const[NpiSearchTerm, setNpiSearchTerm] = useState("");
    const[NpiSearchResults, setNpiSearchResults] = useState([]);

    const [dbSearchNpi,{loading: searchNpiLoading}] = useLazyQuery(GET_NPI_PHARMACIES);
    const [setPharmacyNpi] = useMutation(SET_PHARMACY_NPI);
    const [modalCleanup, setModalCleanup] = useState(false);



    const [cleanupClassing] = useMutation(CLASSING_CLEANUP,{
        variables: {
            entityId: Number(params.entity_id)
        },
        context:{
            headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
        }
    })

    const toggleNpiModal = () => setNpiModal(!npiModal);

    function showNpiModal(row)
    {
        setSelectedRow(row);
        setNpiModal(true)
    }

    const columns = [
        // {
        //     accessorKey: 'id',
        //     header: 'ID',
        //     size: 100,
        //     Cell: ({ cell }) => (
        //         <Link to={'/admin/entity/'+params.entity_id+'/class/pharmacy/'+cell.getValue()}>{cell?.getValue()}</Link>
        //     ),
        //     enableEditing: false,
        // },
        {
            accessorKey: 'name',
            header: 'Pharmacy Name',
            enableEditing: false,
            size: 500,
        },

        {
            accessorKey: 'type.value',
            header: 'Type',
            muiTableBodyCellEditTextFieldProps: {
                select: true, //change to select for a dropdown
                children: pharmacyClassingOptions.map((row, id) => (
                    <MenuItem key={id} value={row.text}>
                        {row.text}
                    </MenuItem>
                )),
            },
            size: 200,
            Cell: ({cell}) => (<span><MDBIcon far icon="edit" style={{cursor:"pointer"}}/> {cell.getValue() } </span>),

        },

        {
            accessorKey: 'npi',
            header: 'NPI',
            enableEditing: false,
            Cell: ({cell}) => (<span><MDBIcon far icon="edit" style={{cursor:"pointer"}} onClick={() => showNpiModal(cell.row)} /> {cell.getValue() } </span>),
        },

        {
            accessorKey: 'address_1',
            header: 'Address 1',
            enableEditing: false,
        },
        {
            accessorKey: 'address_2',
            header: 'Address 2',
            enableEditing: false,
        },
        {
            accessorKey: 'city',
            header: 'City',
            enableEditing: false,
        },
        {
            accessorKey: 'state',
            header: 'State',
            enableEditing: false,
        },
        {
            accessorKey: 'zip_code',
            header: 'Zip Code',
            enableEditing: false,
        },
        {
            accessorKey: 'zip_region',
            header: 'Zip Region',
            enableEditing: false,
        },
        {
            accessorKey: 'status.value',
            header: 'Classing Status',
            enableEditing: false,
        },

    ];



    function updatePharmacyNpi(row, npiRow)
    {
        setPharmacyNpi({
            variables: {
                npi: npiRow.npi,
                id: Number(row.original.id),
                entityId: Number(params.entity_id),
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");

                let values = row.original;
                values.npi =npiRow.npi;

                eventBus.dispatch("pharmacy.class.update_row", values);

            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }



    function SearchNpi(term)
    {
        setNpiSearchTerm(term);

        if(term.length>2)
        {
            //go do the search here


            dbSearchNpi({
                variables: {
                    search: term,
                    skip: 0,
                    take: 20
                },
                context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
                .then( (result) => {
                    setNpiSearchResults(result?.data?.pharmacies.items);
                })
                .catch( (error) => {
                    NotificationManager.error("Api Error");
                })



            //dbSearchHrsa
        }
    }


    function GetTypeOptions()
    {
        getLookup({
            variables: {
                id: 'pharmacy_classing',
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {

                let data = result.data.groupLookupValues.map( (row) => {
                    return {text:row.value, value:row.id}
                })

                setPharmacyClassingOptions(data)
            })
    }

    useEffect(()=> {GetTypeOptions();}, [user]);





    function updatePharmacyTypeClassification(row, type, typeId)
    {
        setPharmacyTypeClassification({
            variables: {
                id: Number(row.original.id),
                entityId: Number(params.entity_id),
                typeId: Number(typeId),
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");


                let values = row.original;
                values.type.value =type;

                eventBus.dispatch("pharmacy.class.update_row", values);

            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })

    }



    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {


                let dd = await getPharmacies({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        entityId: Number(params.entity_id)
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then()
                setTotalRows(dd.data.classPharmacies.totalCount ?? 0)


                setTableData(TableData =>Array.from(new Set([...TableData, ...dd.data.classPharmacies.items])));

                return [];

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: false,
            refetchOnWindowFocus: false,

        });



    const totalDBRowCount = totalRows ?? 0;
    const totalFetched = TableData.length;


    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        async (containerRefElement) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ){
                    // console.log('fetchMoreOnBottomReached: Fetching', true)

                    await fetchNextPage().then();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {if (virtualizerInstanceRef.current) {virtualizerInstanceRef.current.scrollToIndex(0);}}, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {fetchMoreOnBottomReached(tableContainerRef.current).then();}, [fetchMoreOnBottomReached]);




    eventBus.on("pharmacy.class.update_row", (row) =>{



        let index = TableData.findIndex((line) => line.id === row.id);
        var tempTableData = [...TableData];
        tempTableData[index] = row;
        setTableData(tempTableData);
    });

    function confirmClassCleanup()
    {
        setModalCleanup(false);
        cleanupClassing().then(r => {
            NotificationManager.success("Classing Cleanup Submitted!");
        }).catch((error) =>{
            NotificationManager.error("Api Error");
        })

    }




    return (
        <div >
            <MaterialReactTable
                columns={columns}

                editingMode="cell"
                enableEditing
                muiTableBodyCellEditTextFieldProps={({ cell, row }) => ({
                    //onBlur is more efficient, but could use onChange instead
                    onBlur: (event) => {
                        // console.log(cell, event.target.value);
                       // console.log(row); //original

                        var newValue = event.target.value;


                        if(cell.column.id === "type.value")
                        {
                            let type = pharmacyClassingOptions.find((element) => {return element.text === newValue})
                            updatePharmacyTypeClassification(row,event.target.value, type.value);





                        }


                    },
                })}

                data={TableData}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableGlobalFilter={true}
                enableFilters={false}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={isError ? { color: 'error',  children: 'Error loading data',} : undefined}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}


                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {TableData.length.toLocaleString("en-US")} of {totalRows.toLocaleString("en-US")} total rows.
                    </Typography>
                )}

                renderTopToolbarCustomActions={() => <Typography variant="h5" className="m-3">Pharmacy Classing <span><Button color={"secondary"} size={"small"} onClick={()=> setModalCleanup(true)}>Cleanup Classing</Button></span></Typography>}
                enableDensityToggle={false}
                initialState={{ density: 'compact' }}

                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                rowVirtualizerProps={{ overscan: 10 }}
            />




            <MDBModal open={npiModal} tabIndex='-1' onClose={()=>setNpiModal(false)}>
                <MDBModalDialog size='xl'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Search Pharmacy NPI</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleNpiModal}/>
                        </MDBModalHeader>
                        <MDBModalBody>

                            <MDBRow >
                                <MDBCol sm={12} md={12} lg={6}>
                                    <MDBTable small={true}>

                                        <MDBTableBody>
                                            <tr>
                                                <th scope='row' className="fw-bold">Class ID</th>
                                                <td>{selectedRow?.original?.id}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'  className="fw-bold">Name</th>
                                                <td>{selectedRow?.original?.name}</td>
                                            </tr>

                                            <tr>
                                                <th scope='row'  className="fw-bold">Address 1</th>
                                                <td>{selectedRow?.original?.address_1}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'  className="fw-bold">Address 2</th>
                                                <td>{selectedRow?.original?.address_2}</td>
                                            </tr>


                                        </MDBTableBody>


                                    </MDBTable>
                                </MDBCol>

                                <MDBCol sm={12} md={12} lg={6}>
                                    <MDBTable small={true}>

                                        <MDBTableBody>
                                            <tr>
                                                <th scope='row'   className="fw-bold">City</th>
                                                <td>{selectedRow?.original?.city}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'   className="fw-bold">State</th>
                                                <td>{selectedRow?.original?.state}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'  className="fw-bold">Zip</th>
                                                <td>{selectedRow?.original?.zip_code}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row' className="fw-bold">Type</th>
                                                <td>{selectedRow?.original?.type.value}</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'  className="fw-bold">NPI</th>
                                                <td>{selectedRow?.original?.npi}</td>
                                            </tr>

                                            <tr>
                                                <th scope='row'  className="fw-bold">NPI Name</th>
                                                <td>{selectedRow?.original?.pharmacy?.name}</td>
                                            </tr>

                                        </MDBTableBody>


                                    </MDBTable>
                                </MDBCol>

                            </MDBRow>




                            <MDBInput label='Search NPI' id='formControlDefault' type='text'
                                      value={NpiSearchTerm}
                                      onChange={(e) => SearchNpi(e.target.value)}
                            />


                            {searchNpiLoading && <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>}

                            {!searchNpiLoading &&

                            <MDBTable small>
                                <thead>
                                <tr>
                                    <th>NPI</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>



                                { NpiSearchResults?.map( (row,id) => {
                                    return(
                                        <tr key={id}>
                                            <td>{row?.npi}</td>
                                            <td>{row?.name}</td>
                                            <td>{row?.address_1}</td>
                                            <td>{row?.city}</td>
                                            <td>{row?.state}</td>
                                            <td><MDBBtn  size={"sm"} onClick={() => {
                                                // set340bId(hrsaSelectedRow, row.id_340b);
                                                // toggleShow();
                                                // searchHrsa("");
                                                // setHrsaResults([]);

                                                //just need to set it now
                                                updatePharmacyNpi(selectedRow, row);
                                                toggleNpiModal();
                                                setNpiSearchResults([]);
                                                setNpiSearchTerm("");

                                            }}>Select</MDBBtn></td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </MDBTable>


                            }


                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>



            <MDBModal open={modalCleanup} tabIndex='-1' onClose={() => setModalCleanup(false)}>
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Cleanup Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={()=>setModalCleanup(false)}/>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <Typography fontSize={20}>
                                Are you sure you want to clean up the classing records?
                            </Typography>
                            <p>
                                This will go through all the classing records and delete any that are no longer connected to raw data files.
                            </p>

                            <Button onClick={()=>confirmClassCleanup()}>Confirm Classing Cleanup</Button>
                        </MDBModalBody>

                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>
    );
};





const queryClient = new QueryClient();



export function Page({user}) {

    return (

        <QueryClientProvider client={queryClient}>
            <DrugTable user={user} />
        </QueryClientProvider>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
