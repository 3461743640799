import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBBtn, MDBProgress, MDBProgressBar, MDBDatatable,
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import instructions from "../../../../Images/data-portal-instructions-headline.png";
import {default as DrugListing} from "../../Components/DrugListing";
import {default as ClearwayDrugListing} from "../../Components/ClearwayDrugListing";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import axios from "axios";
import Moment from "react-moment";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemIcon,
    MenuItem, Stack, TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {AccountCircle, Delete, Edit, Send} from "@mui/icons-material";
import {default as AddDrug} from "../../Components/AddNDCDrug";



const GET_DRUGS = gql`
  query Get( $skip: Int!, $take: Int!, $entityId: Long!) {
    classDrug(entityId: $entityId skip: $skip, take: $take) {
      totalCount
      items
      {
        id
        name
        ndc
        ldd_classification_id
        ldd_classification{
            value
        }
        drug {
            name
        }
        
      }
      
    }
  }
`;


const DELETE_DRUG = gql`
  mutation delete($entityId: Long!, $ndc: String!) {
   deleteEntityDrug(entityId: $entityId, ndc: $ndc){
    successful
   }
  }
`;





const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()
    const fetchSize = 25;
    const [pagedata, setPagedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [loadedRows, setLoadedRows] = useState(0);

    const [deleteNdc, setDeleteNdc] = useState("");

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);


    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getDrugs,{error, loading: drugLoading, data: drugData}] = useLazyQuery(GET_DRUGS);



    const [deleteEntityNdc] = useMutation(DELETE_DRUG, { context:{ headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}});



    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 100,
            Cell: ({ cell }) => (
                <Link to={'/admin/entity/'+params.entity_id+'/class/drug/'+cell.getValue()}>{cell?.getValue()}</Link>
            ),
        },
        {
            accessorKey: 'name',
            header: 'Drug Name',
        },





        {
            accessorKey: 'ldd_classification.value',
            header: 'LDD',
            Cell: ({ cell }) => (
                <MDBBadge pill >{cell?.getValue()}</MDBBadge>
            ),
        },



    ];




    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {


                let dd = await getDrugs({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        entityId: Number(params.entity_id)
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then()




                setTotalRows(dd.data.classDrug.totalCount ?? 0)


                return dd.data.classDrug.items;

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: true,

        });


    const flatData = useMemo(
        () => data?.pages?.flatMap((page) => page) ?? [],
        [data],
    );

    const totalDBRowCount = totalRows ?? 0;

    const totalFetched = flatData.length;



    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        async (containerRefElement) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;


                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ){
                    console.log('fetchMoreOnBottomReached: Fetching', true)

                    await fetchNextPage().then();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current).then();
    }, [fetchMoreOnBottomReached]);







    return (
        <div >
            <MaterialReactTable
                columns={columns}

                data={flatData}
                enableColumnFilters={false}
                enablePagination={false}
                enableGlobalFilter={true}
                enableFilters={true}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={isError ? { color: 'error',  children: 'Error loading data',} : undefined}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                renderBottomToolbarCustomActions={() => (<Typography>{totalDBRowCount} total rows.</Typography>)}





                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                rowVirtualizerProps={{ overscan: 4 }}
            />



        </div>
    );
};




export const DeleteVerificationModal = ({ open, ndc, onClose, onSubmit }) => {


    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(ndc);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} >
            <DialogTitle textAlign="center">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you wish to remove NDC {ndc} from this entity?
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button color="error" onClick={handleSubmit} variant="contained">Delete</Button>
                <Button color="info" onClick={onClose} variant="contained">Cancel</Button>

            </DialogActions>
        </Dialog>
    );
};





const queryClient = new QueryClient();



const ADD_ENTITY_DRUG = gql`
  mutation add($entityId: Long!, $ndc: String!) {
   createEntityDrug(entityId: $entityId, ndc: $ndc){
    successful
   }
  }
`;



export function Page({user}) {
    const [createEntityNdc] = useMutation(ADD_ENTITY_DRUG, { context:{ headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}});
    const params= useParams()


    function addNDCToEntity(row)
    {
        createEntityNdc({
            variables:{
                entityId: Number(params.entity_id),
                ndc:row.ndc
            }
        }).then( (result) => {
            NotificationManager.success("Added NDC Drug!")

            queryClient.clear();

            //
            // tableData.splice(row.index, 1);
            // setTableData([...tableData]);
        })
    }

    return (

        <MDBCard style={{paddingLeft:'3rem', paddingRight: '3rem'}}>
            <MDBCardHeader>
                <MDBTypography tag='h2'>Prescription Final</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody>
                <QueryClientProvider client={queryClient}>
                    {/*<DrugTable user={user} />*/}
                    <p>Coming Soon</p>
                </QueryClientProvider>
            </MDBCardBody>


        </MDBCard>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
