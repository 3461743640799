import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBContainer,
    MDBSpinner,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBCardFooter, MDBBtn, MDBSwitch, MDBSelect, MDBInput
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";


const GET_LOOKUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;

const GET_DATA = gql`
  query Get($id: Long!, $entityId: Long!) {
    classPharmacy(id: $id, entityId: $entityId)
    {
      id
      name
      npi
      type_id
      type{
        value
      }
      pharmacy{
        npi
        name
        other_name
        primary_taxonomy_id
        city
        state
        address_1
        postal_code
      }
    }
  }
`;



const SET_PHARMACY_TYPE_CLASSIFICATION = gql`
mutation set($entityId: Long!, $id: Long!, $typeId: Long!)
{
    setPharmacyClassification(entityId: $entityId, id: $id, typeId: $typeId) {
        successful
    }
}
`;


const SET_PHARMACY_NPI = gql`
mutation set($entityId: Long!, $id: Long!, $npi: String!)
{
    setPharmacyNpi(entityId: $entityId, id: $id, npi: $npi) {
        successful
    }
}
`;


export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()
    const [pharmacyData, setPharmacyData] = useState({});
    const [Npi, setNpi] = useState("");

    const [pharmacyClassingOptions, setPharmacyClassingOptions] = useState([]);
    const [drugEdit, setDrugEdit] = useState(false);
    const [getPharmacyData,{error: getDrugDataError, loading: getDrugDataLoading}] = useLazyQuery(GET_DATA);
    const [getLookup,{error: getLookupError, loading: getLookupLoading}] = useLazyQuery(GET_LOOKUP);
    const [setPharmacyTypeClassification] = useMutation(SET_PHARMACY_TYPE_CLASSIFICATION);
    const [setPharmacyNpi] = useMutation(SET_PHARMACY_NPI);

    const [dataClassificationSelect, setDataClassificationSelect] = useState([]);





    function updatePharmacyTypeClassification(event)
    {
        setPharmacyTypeClassification({
            variables: {
                id: Number(params.pharmacy_id),
                entityId: Number(params.entity_id),
                typeId: event.value,
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })

    }


    function saveNpi()
    {
        setPharmacyNpi({
            variables: {
                npi: Npi,
                id: Number(params.pharmacy_id),
                entityId: Number(params.entity_id),
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");
                loadData();
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }

    function loadData()
    {
        getLookup({
            variables: {
                id: 'pharmacy_classing',
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {

                let data = result.data.groupLookupValues.map( (row) => {
                    return {text:row.value, value:row.id}
                })

                setPharmacyClassingOptions(data)
            })

        getPharmacyData({
            variables: {
                id: Number(params.pharmacy_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }


                setPharmacyData(result.data.classPharmacy);
            })
    }


    useEffect(()=> {
       loadData();
    }, [user]);



    useEffect(() => {

        var selectOptions = pharmacyClassingOptions.map( (row) => {
            if(row.value === pharmacyData?.type_id )
                row.defaultSelected = true
            return row;
        })

        setDataClassificationSelect(selectOptions);
    }, [pharmacyClassingOptions, pharmacyData]);
    useEffect(()=> { setNpi(pharmacyData.npi) }, [pharmacyData]);



    if(getDrugDataLoading || getLookupLoading)
        return (
            <MDBContainer >
                <MDBBreadcrumb className='p-1 '>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin"}>Clearway</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity"}>Entity</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <>Classing</>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id + '/class/pharmacy'}>Pharmacy</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                        <>{params.pharmacy_id}</>
                    </MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>
            </MDBContainer>
        );
    else return (

        <MDBContainer>
            <MDBBreadcrumb className='p-1 '>
                <MDBBreadcrumbItem>
                    <Link to={"/admin"}>Clearway</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entity</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <>Classing</>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id + '/class/pharmacy'}>Pharmacy</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                    <>{params.pharmacy_id}</>
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBRow>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>


                    <MDBCard>
                        <MDBCardHeader>
                            {/*<span className="float-end"><> <MDBSwitch wrapperClass='mb-4'  value={drugEdit} label="Edit" onChange={()=>setDrugEdit(!drugEdit)}  /></></span>*/}
                            <MDBTypography tag='h2'>NPI Information</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBTable>
                                <MDBTableBody>
                                    <tr>
                                        <th>NPI</th>
                                        <td>{pharmacyData?.pharmacy?.npi}</td>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <td>{pharmacyData?.pharmacy?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Additional Name</th>
                                        <td>{pharmacyData?.pharmacy?.other_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td>{pharmacyData?.pharmacy?.address_1}</td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>{pharmacyData?.pharmacy?.city}</td>
                                    </tr>
                                    <tr>
                                        <th>State</th>
                                        <td>{pharmacyData?.pharmacy?.state}</td>
                                    </tr>
                                    <tr>
                                        <th>Zip</th>
                                        <td>{pharmacyData?.pharmacy?.postal_code}</td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>
                    <MDBCard>
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Pharmacy Classification</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBInput  label='Pharmacy ID'   value={pharmacyData?.id} disabled />

                            <br/>
                            <MDBInput  label='Pharmacy Name'   value={pharmacyData?.name} disabled />

                            <br/>
                            <MDBSelect
                                label='Type Classification'
                                data={dataClassificationSelect}
                                onValueChange={(e) =>updatePharmacyTypeClassification(e)}
                            />
                            <br/>

                            <MDBInput  label='NPI ID'  style={{width:'auto'}} value={Npi} onChange={(e)=>{ setNpi(e.target.value) }} />

                            {pharmacyData.npi!==Npi && <MDBBtn className="form-control mb-3 mt-1" onClick={saveNpi}  >Save NPI</MDBBtn>}











                        </MDBCardBody>
                    </MDBCard>


                </MDBCol>
            </MDBRow>


        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
