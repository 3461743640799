import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBBtn, MDBDatatable, MDBTable, MDBTableBody, MDBTableHead, MDBTypography} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";



export function Page({user}) {
    let navigate = useNavigate();
    const [datatable, setDatatable] = useState({ columns:[{label:"ID", field:"id"},{label:"Name", field: "name"} , {label: "Created", field: "createdTsMoment"}, {label:"Action", field: "action"}], rows:[]})
    const GET_ORGANIZATIONS = gql`
          query Get {
            organizations {
              id
              name
              created
              updated
            }
          }
        `;


    const [getOrganizations, { loading, error, data }] = useLazyQuery(GET_ORGANIZATIONS);


    useEffect(()=> {
        getOrganizations({ context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }





                setDatatable({
                    columns: datatable.columns,
                    rows: [].concat(result?.data?.organizations)
                        .map((row) => {

                            return {
                                ...row,
                                createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                                action: (<Link to={"/admin/organization/" + row.id}><MDBBtn size={"sm"}>View</MDBBtn></Link>)
                            }
                        })
                });
            })

    }, [user]);


    return (

        <div >

            <MDBDatatable
                loading={loading}
                data={datatable}
                search={true}
                pagination={true}
                advancedData={true}
            />

        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
