import React, {useEffect, useRef, useState} from 'react';
import {
    MDBBreadcrumb, MDBBreadcrumbItem,
    MDBBtn,
    MDBCol,
    MDBContainer, MDBIcon, MDBProgress, MDBProgressBar,
    MDBRow, MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTypography,
    MDBDatatable, MDBInput, MDBCard, MDBCardHeader, MDBCardBody
} from "mdb-react-ui-kit";
import {Link, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import axios from "axios";
import {saveAs} from "file-saver";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import eventBus from "../../../Context/EventBus";
import Papa from "papaparse";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { customAlphabet } from 'nanoid'

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 20)


const GET_PACKAGE_FILES = gql`
  query Get($entityId: Long!, $packageId: Long!) {
    packageFiles(entityId: $entityId, packageId: $packageId)
    {
      id
      name
      uploaded_by
      uploaded_ts
    }
  }
`;




function getBaseURL()
{
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
        return "https://pre-data.clearwayhealth.com/api/";
    else
        return "/api/";
}




export function Page({user}) {
    const params= useParams()
    const [getPackageFiles, { loading, error, data }] = useLazyQuery(GET_PACKAGE_FILES);
    const [datatable, setDatatable] = React.useState({columns:[], rows:[]});
    const [connection, setConnection] = useState(null);
    const [rowsProcessed, setRowsProcessed] = React.useState(0);
    const [rowsSent, setRowsSent] = React.useState(0);

    const [uploadPercent, setUploadPercent] = React.useState(0);


    useEffect(()=>{GetDetails();},[]);

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(getBaseURL() +"file/upload", { accessTokenFactory: () => user.token })
            .withAutomaticReconnect()
            .build();
        setConnection(connect);
    }, []);



    const changeHandler =  async (event) => {
        var file = event.target.files[0];

        connection
            .start()
            .then(() => {
                connection.on("row.received", async (length) => {await setRowsProcessed(previousValue => previousValue+1);  });
            })
            .then( () => {
                let fileId = nanoid(10);

                connection.send("CreateFile", parseInt(params.entity_id), parseInt(params.package_id), fileId, file.name);
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    worker: true,
                    step: async (row) => {
                        await connection.send("SendMessage", parseInt(params.entity_id), fileId, JSON.stringify(row.data));
                        await setRowsSent(previousValue => previousValue+1)

                    },
                    complete:  function () {
                        //NotificationManager.success("File Uploaded, Processing.")


                    },
                });
            })
            .catch((error) => {
                NotificationManager.error("Unable to upload file, please try again.")
                connection.stop();

                //try again
                //changeHandler(event)
            });


    };




    function UploadFiles(event) {
        event.preventDefault()
        setUploadPercent(0);
        let file = event.target.files[0];

        const MAX_FILE_SIZE = 1024 * 1024 * 2 // 250MB
        const fileSizeKiloBytes = file.size / 1024;

        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            NotificationManager.error("File too large, please upload a smaller file.", 'File Upload');
            return
        }

        const formData = new FormData();
        formData.append('upload', file);
        formData.append('fileName', file.name);



        axios.post(getBaseURL() +"file/v3/upload/"+params.entity_id+"/"+params.package_id, formData, {
            headers: {"Content-Type": "multipart/form-data",   "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'},

            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 50;
                setUploadPercent(progress);
            },
            onDownloadProgress: (progressEvent) => {const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50; setUploadPercent(progress);},
        }).then( (result) => {
            NotificationManager.success("Success", 'File Uploaded');
            setUploadPercent(100);
            GetDetails();

            // eventBus.dispatch("couponApply", { message: "coupone applied" });

            setTimeout( () => {setUploadPercent(0);}, 1000)
        }).catch( (result) => {

            setUploadPercent(0);

            NotificationManager.error("Error! Please try again", 'File Upload');
        });



    }
    function GetDetails()
    {

        getPackageFiles({ variables:{entityId: Number(params.entity_id), packageId: Number(params.package_id) }, context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'}}})

            .then( (result) => {

                setDatatable({
                    columns: [
                        {
                            label: "ID",
                            field: "id",
                            width: 100,
                        },
                        {
                            label: "File Name",
                            field: "name",
                        },
                        {
                            label: "Uploaded",
                            field: "createdTsMoment",
                        },
                    ],
                    rows: [].concat(result?.data?.packageFiles)
                        .map( (row) => {

                            return {
                                ...row,
                                createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                            }
                        })
                });





            })
            .catch((error) => {
                console.log("error", error);
            })
    }



    if(rowsSent>0)
    {
        if(rowsSent === rowsProcessed)
        {
            NotificationManager.success("File Uploaded.")

            setTimeout( () => {setRowsSent(0); setRowsProcessed(0); GetDetails() }, 2000)
        }
    }


    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <MDBCard >

            <MDBCardHeader>
                <h3>Files <span style={{fontSize:'12px'}}>(2GB limit)</span>
                    <span className="float-end">
                        {/*{uploadPercent === 0 &&  <label className="btn btn-primary btn-sm" >Upload CSV <input type="file" onChange={UploadFiles} hidden   accept=" text/csv" /> </label> }*/}

                        { rowsSent  === 0 &&  <label className="btn btn-primary btn-sm" >Upload CSV
                            <input
                                type="file"
                                name="file"
                                accept=".csv,.txt,.zip"
                                onChange={UploadFiles}
                                hidden

                            />
                        </label> }
                    </span>
                </h3>
                {/*{ rowsSent>0 && rowsProcessed>0 &&  <MDBProgress><MDBProgressBar width={ (100*(rowsProcessed/rowsSent)) } valuemin={0} valuemax={100} /></MDBProgress>}*/}
                { uploadPercent>0 &&  <MDBProgress><MDBProgressBar width={uploadPercent} valuemin={0} valuemax={100} /></MDBProgress>}


            </MDBCardHeader>
            <MDBCardBody>

                <MDBDatatable

                    sm={true}
                    search={true}
                    loading={loading}
                    data={datatable}
                    pagination={true}
                    advancedData={true}
                    hover={true}

                />




            </MDBCardBody>



        </MDBCard>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
