import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead, MDBTableBody, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem,
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import instructions from "../../../Images/data-portal-instructions-headline.png";
import {default as Instructions} from "../Components/EntityInstructions";
import {default as Review} from "../Components/ReviewRequest";

import {default as FileManager} from "../Components/FileManagement";
import CountUp from 'react-countup';

import {default as Dashboard} from "../Components/PackageDashboard";
import {default as DataQuality} from "../Components/PackageDataQuality";


const GET_ORGANIZATION = gql`
  query Get($id: Long!, $packageId: Long!) {
    entity(entityId: $id) {
      id
      name
      created
      updated
      
    }
    package(entityId: $id, packageId: $packageId){
        id
        is_submitted
    }
     packageFileCount(entityId: $id, packageId: $packageId){
        count
    }
   
  }
`;




export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === true)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()




    const { loading, error, data, refetch } = useQuery(GET_ORGANIZATION, {
        variables:{
            id: Number(params.entity_id),
            packageId: Number(params.package_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });


    if(error)
    {
        //need to redirect.
        // navigate('/client');
    }

    const [verticalActive, setVerticalActive] = useState('tab1');

    const handleVerticalClick = (value: string) => {
        if (value === verticalActive) {
            return;
        }

        setVerticalActive(value);
    };



    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);

    else return (

        <MDBContainer >
            <div className='p-5 bg-light mb-4 file-upload-wrapper' style={{ backgroundImage: `url(${instructions})`, backgroundRepeat:'no-repeat', backgroundSize: "cover" }} >
                <h4 style={{paddingTop:'5rem', paddingLeft:'6rem'}}>Entity: {data?.entity.name} </h4>
            </div>
            <MDBBreadcrumb className='p-1 '>
                <MDBBreadcrumbItem>
                    <Link to={"/"}>Home</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/client"}>Entities</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/client/entity/"+params.entity_id}>{data.entity.name}</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    Package
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>{params.package_id}</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBRow >
                <MDBCol sm={12} md={3}>
                    <MDBTabs className='flex-column '   >
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                                <MDBIcon fas icon="laptop-medical" className='me-2' /> Dashboard
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleVerticalClick('tab2')} active={verticalActive === 'tab2'}>
                                <MDBIcon far icon='file' className='me-2' /> Files
                            </MDBTabsLink>
                        </MDBTabsItem>

                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleVerticalClick('review')} active={verticalActive === 'review'}>
                                <MDBIcon far icon='file' className='me-2' /> Comments
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>
                </MDBCol>
                <MDBCol sm={12} md={9}>
                    <MDBTabsContent >
                        <MDBTabsPane open={verticalActive === 'tab1'} >
                            <MDBRow>
                                <MDBCol sm={4} >
                                    <MDBCard style={{height:'100%'}}>
                                        <MDBCardHeader>Comments Status</MDBCardHeader>
                                        <MDBCardBody className="text-center">
                                            {data?.package?.is_submitted ? <span>Submitted</span> : <span>None Provided</span> }

                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol sm={4} >
                                    <MDBCard style={{height:'100%'}}>
                                        <MDBCardHeader>Package Files</MDBCardHeader>
                                        <MDBCardBody className="text-center">
                                            <h5><CountUp end={data.packageFileCount.count ?? 0} /></h5>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>


                        </MDBTabsPane>
                        <MDBTabsPane open={verticalActive === 'tab2'}><FileManager/></MDBTabsPane>
                        <MDBTabsPane open={verticalActive === 'tab3'}><DataQuality /></MDBTabsPane>
                        <MDBTabsPane open={verticalActive === 'review'}><Review /></MDBTabsPane>
                    </MDBTabsContent>
                </MDBCol>
            </MDBRow>




        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
