import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBCardFooter, MDBBtn,
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import CountUp from 'react-countup';

import {default as DataQuality} from "../../Components/PackageDataQuality";
import {default as RunChecks} from "../../Components/RunPackageDataQualityChecksBtn";
import {default as FileListing} from "../../Components/PackageFileListing";
import {default as EntityPackageListing} from "../../Components/EntityPackageListing";


const GET_METRICS = gql`
  query Get($id: Long!) {
    metricEntityFilesCount(entityId: $id) {
      count
    }
    metricEntityPackageCount(entityId: $id) {
      count
    }
     metricEntityRowCount(entityId: $id) {
      count
    }
  }
`;




export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()


    const { loading, error, data, refetch } = useQuery(GET_METRICS, {
        variables:{
            id: Number(params.entity_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });


    if(false) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);

    else return (

        <MDBContainer >

            <MDBRow>

                <MDBCol sm={12} md={6} lg={4} className='mb-md-0 p-2'>
                    <MDBCard>
                        <MDBCardBody>
                            <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0'>
                                    <div className='p-2 bg-primary rounded-4 shadow-2-strong'>
                                        <MDBIcon icon='chart-pie' size='1x' className='text-white fa-fw' />
                                    </div>
                                </div>
                                <div className='flex-grow-1 ms-4'>
                                    <p className='text-muted mb-1'>Packages</p>
                                    {loading && <div className="text-center" ><MDBSpinner animation="grow"  variant="primary" /></div>}
                                    {!loading && <MDBTypography tag='h5'><CountUp end={Number(data?.metricEntityPackageCount?.count ?? 0)} /></MDBTypography>}

                                </div>
                            </div>
                        </MDBCardBody>

                    </MDBCard>
                </MDBCol>

                <MDBCol sm={12} md={6} lg={4} className='mb-md-0 p-2'>
                    <MDBCard>
                        <MDBCardBody>
                            <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0'>
                                    <div className='p-2 bg-primary rounded-4 shadow-2-strong'>
                                        <MDBIcon icon='sitemap' size='1x' className='text-white fa-fw' />
                                    </div>
                                </div>
                                <div className='flex-grow-1 ms-4'>
                                    <p className='text-muted mb-1'>Files</p>
                                    {loading && <div className="text-center" ><MDBSpinner animation="grow"  variant="primary" /></div>}
                                    {!loading &&  <MDBTypography tag='h5'><CountUp end={Number(data?.metricEntityFilesCount?.count ?? 0)} /></MDBTypography>}


                                </div>
                            </div>
                        </MDBCardBody>

                    </MDBCard>
                </MDBCol>

                <MDBCol sm={12} md={6} lg={4} className='mb-md-0 p-2'>
                    <MDBCard>
                        <MDBCardBody>
                            <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0'>
                                    <div className='p-2 bg-primary rounded-4 shadow-2-strong'>
                                        <MDBIcon icon='users' size={"1x"} className='text-white fa-fw' />
                                    </div>
                                </div>
                                <div className='flex-grow-1 ms-4'>
                                    <p className='text-muted mb-1'>Rows</p>
                                    {loading && <div className="text-center" ><MDBSpinner animation="grow"  variant="primary" /></div>}
                                    {!loading && <MDBTypography tag='h5' ><CountUp end={Number(data?.metricEntityRowCount?.count ?? 0)} /></MDBTypography>}
                                </div>
                            </div>
                        </MDBCardBody>

                    </MDBCard>
                </MDBCol>


            </MDBRow>
            <MDBRow>
                <MDBCol className="pt-5">
                    <EntityPackageListing />
                </MDBCol>
            </MDBRow>


        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
