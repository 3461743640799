import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";


export function Page({user}) {
    let navigate = useNavigate();



    useEffect(() => {
        setInterval(() => {

            if(user.isAuthenticated)
            {
                const exp = new Date(0).setUTCSeconds(user.token_exp);
                let seconds_left =   Math.round((exp- new Date().getTime())/1000 );
                if(seconds_left <= 0)
                {
                    NotificationManager.warning("Session Expired, Logging out.")
                    navigate('/logout');
                }
            }

        }, 5000);
    }, []);




    return (
        <></>
    );

}

const mapStateToProps = state => {

    return {
        user: state.user,
    };
};



export default connect(mapStateToProps)(Page)
