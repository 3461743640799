import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
    Routes,
    Route
} from "react-router-dom";




import {default as HomePage} from "./Pages/Home";
import {default as EntityPage} from "./Pages/Entity.js";
import {default as PackagePage} from "./Pages/Package.js";


export default function PageRoutes() {


    return (
        <div>
            <Routes  >
                <Route path="/entity/:entity_id/package/:package_id"  element={<PackagePage />} />

                <Route path="/entity/:entity_id"  element={<EntityPage />} />
                <Route path="/"  element={<HomePage />} />


            </Routes>
        </div>
    );

}