import React, {useState} from 'react';
import {
    MDBCol,
    MDBContainer, MDBIcon,
    MDBRow,
     MDBTypography
} from "mdb-react-ui-kit";
import {default as OrganizationListing} from "../../Components/OrganizationListing";
import {default as AddOrganization} from "../../Components/AddOrganizationButton";



export default function Page() {
    return (
        <MDBContainer >
            <MDBContainer >
                <MDBRow className=' text-center pt-4' >
                    <MDBCol>
                        <MDBTypography tag='h2'>Organizations</MDBTypography>
                        <span className=""><AddOrganization/></span>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-center pt-4' >
                    <MDBCol sm={10}>
                        <OrganizationListing />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBContainer>
    );
}