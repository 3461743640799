import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBBtn, MDBProgress, MDBProgressBar, MDBDatatable,
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import instructions from "../../../../Images/data-portal-instructions-headline.png";
import {default as DrugListing} from "../../Components/DrugListing";
import {default as ClearwayDrugListing} from "../../Components/ClearwayDrugListing";
import {gql, useLazyQuery} from "@apollo/client";
import axios from "axios";
import Moment from "react-moment";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {Typography} from "@mui/material";


const GET_DRUGS = gql`
  query Get( $skip: Int!, $take: Int!, $search: String!) {
    drugs( skip: $skip, take: $take, search: $search) {
      totalCount
      items
      {
        ndc
        gpi
        name
        strength
        strength_unit_of_measure
        dosage_form
        clearwayDrug {
            ndc
            classification_id
            is_orphaned
            classification {
             value
            }
        }
      }
      
    }
  }
`;



const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()
    const fetchSize = 25;
    const [pagedata, setPagedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [loadedRows, setLoadedRows] = useState(0);


    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getDrugs,{error, loading: drugLoading, data: drugData}] = useLazyQuery(GET_DRUGS);

    const columns = [
        {
            accessorKey: 'ndc',
            header: 'NDC',
            Cell: ({ cell }) => (
                <Link to={'/admin/drug/'+cell.getValue()}>{cell?.getValue()}</Link>
            ),
        },
        {
            accessorKey: 'name',
            header: 'Drug Name',
        },




        {
            accessorKey: 'uploaded_ts',
            header: 'Uploaded',
            Cell: ({ cell }) => (
                <Moment utc local format="MM/DD/YYYY hh:mm a">{cell?.getValue()}</Moment>
            ),
        },


        {
            accessorKey: 'clearwayDrug.is_orphaned',
            header: 'Orphan',
            Cell: ({ cell }) => (
                <span className="text-center">{cell?.getValue() ? <> <MDBBadge pill >Orphan</MDBBadge></>:""}</span>
            ),
        },

        {
            accessorKey: 'clearwayDrug.classification.value',
            header: 'Classification',
            Cell: ({ cell }) => (
                <span >{cell?.getValue() ? <> {cell?.getValue()}</>:""}</span>
            ),
        }

    ];




    const { data, fetchNextPage, isError, isFetching, isLoading } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {


                let dd = await getDrugs({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        search: globalFilter ?? ""
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then()

                console.log(dd);

                // .then( (result) => {
                //
                //    // let data = pagedata.concat(result?.data?.drugs.items);
                //     setTotalRows(result?.data?.drugs.totalCount ?? 0)
                //
                //     console.log('pageParam', pageParam)
                //     console.log('drugs', result?.data?.drugs.items)
                //
                //
                //
                //
                //     //setPagedata(data.concat(result?.data?.drugs.items))
                //
                //     return result?.data?.drugs.items;
                //
                //
                //
                //
                //
                // })

                setTotalRows(dd.data.drugs.totalCount ?? 0)

                console.log('total', dd.data.drugs)

                return dd.data.drugs.items;

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: true,

        });


    const flatData = useMemo(
        () => data?.pages?.flatMap((page) => page) ?? [],
        [data],
    );

    const totalDBRowCount = totalRows ?? 0;

    const totalFetched = flatData.length;



    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        async (containerRefElement) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;

                // console.log('fetchMoreOnBottomReached: height', scrollHeight - scrollTop - clientHeight)
                // console.log('fetchMoreOnBottomReached: isFetching', isFetching)
                // console.log('fetchMoreOnBottomReached: totalFetched', totalFetched)
                // console.log('fetchMoreOnBottomReached: totalDBRowCount', totalDBRowCount)

                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ){
                    console.log('fetchMoreOnBottomReached: Fetching', true)

                    await fetchNextPage().then();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current).then(r => console.log('loaded'));
    }, [fetchMoreOnBottomReached]);



    return (
        <div >



            <MaterialReactTable
                columns={columns}
                data={flatData}

                enableColumnFilters={false}
                enablePagination={false}
                enableGlobalFilter={true}
                enableFilters={true}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }


                // enableRowActions
                // renderRowActionMenuItems={({ row }) => [
                //     <MenuItem key="edit" onClick={() => console.info('Edit')}>
                //         Edit
                //     </MenuItem>,
                //     <MenuItem key="delete" onClick={() => console.info('Delete')}>
                //         Delete
                //     </MenuItem>,
                // ]}


                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                // renderBottomToolbarCustomActions={() => (
                //     <Typography>
                //         Fetched {totalFetched} of {totalDBRowCount} total rows.
                //     </Typography>
                // )}

                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {totalDBRowCount} total rows.
                    </Typography>
                )}



                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                //rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
                rowVirtualizerProps={{ overscan: 4 }}
            />
        </div>
    );
};






const queryClient = new QueryClient();


export function Page({user}) {


    return (

        <MDBCard style={{paddingLeft:'3rem', paddingRight: '3rem'}}>
            <MDBCardHeader>
                <MDBTypography tag='h2'>Drug Final</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody>
                <QueryClientProvider client={queryClient}>
                    {/*<DrugTable user={user} />*/}
                    <p>Coming Soon</p>
                </QueryClientProvider>
            </MDBCardBody>


        </MDBCard>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
