
import {
    SET_USER, RESET_USER
} from './actions';
import jwt_decode from "jwt-decode";



// INITIALIZE STATE

const initialState = {
    email:'',
    token:'',
    isAuthenticated:false,
    isAdmin:false,
    token_exp:null,
};


// REDUCER

export const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_USER:

            state.token = action.token;
            state.isAuthenticated = true;

            delete state?.state?.state;



            // state.token = action.token;
            // state.counter++;
            // state.isAuthenticated = true;
            let jwt = jwt_decode(action.token);

            state.token_exp = jwt['exp'];

            let role = jwt["roles"];

            if(role === "admin") state.isAdmin = true;


            return {
                ...state, state
            };

        case RESET_USER:
            state.token = '';
            state.email = '';
            state.counter = 0;
            state.isAuthenticated = false;
            state.isAdmin = false;
            return {
                ...state, state
            };
        default:
            return state;
    }
};



