import React, {useEffect, useState} from 'react';
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTypography,
    MDBDatatable,
    MDBInput,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBModal,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle, MDBModalBody, MDBModalFooter, MDBBadge,
    MDBModalDialog
} from "mdb-react-ui-kit";
import {Link, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import axios from "axios";
import {saveAs} from "file-saver";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";

const GET_PACKAGE_FILES = gql`
  query Get($entityId: Long!, $packageId: Long!) {
    packageFiles(entityId: $entityId, packageId: $packageId)
    {
       id
        name
        package_id
        size
        uploaded_by
        uploaded_ts
        extension
        mime_type
        row_count
        approved
        status
    }
  }
`;




function getBaseURL()
{
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
        return "https://clearwayhealth.dev/api/";
    else
        return "/api/";
}




export function Page({user}) {
    const params= useParams()
    const [loading, setLoading] = useState(false);

    const [uploadStatus, setUploadStatus] = useState("");



    const [uploadPercent, setUploadPercent] = useState(1);
    const [getPackageFiles, { loading: dataLoading, error, data }] = useLazyQuery(GET_PACKAGE_FILES);
    const [datatable, setDatatable] = React.useState({columns:[], rows:[]});
    useEffect(()=>{GetDetails();},[]);


    function UploadFiles(event) {
        event.preventDefault()
        setBasicModal(true)
        setUploadPercent(1);
        setUploadStatus("Uploading...")
        let file = event.target.files[0];

        const MAX_FILE_SIZE = 1024 * 1024 * 2 // 250MB
        const fileSizeKiloBytes = file.size / 1024;

        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            NotificationManager.error("File too large, please upload a smaller file.", 'File Upload');
            return
        }

        const formData = new FormData();
        formData.append('upload', file);

        formData.append('fileName', file.name);




        axios.post(getBaseURL() +"file/v3/upload/"+params.entity_id+"/"+params.package_id, formData, {
            headers: {"Content-Type": "multipart/form-data",   "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'},
            onUploadProgress: (progressEvent) => {
                const progress = 1.8 * ((progressEvent.loaded / progressEvent.total) * 50);
                setUploadPercent(progress);
            },
            onDownloadProgress: (progressEvent) => {const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50; setUploadPercent(progress);},
        }).then( (result) => {
            NotificationManager.success("Success", 'File Uploaded');
            setUploadPercent(100);
            setUploadStatus("File was successfully uploaded!")
            GetDetails();

        }).catch( (result) => {
            setUploadPercent(0);
            NotificationManager.error("Error! Please try again", 'File Upload');
            setUploadStatus("Something went wrong, Please try again.")

        });



    }
    function GetDetails()
    {

        getPackageFiles({ variables:{entityId: Number(params.entity_id), packageId: Number(params.package_id) }, context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'}}})

            .then( (result) => {

                setDatatable({
                    columns: [
                        {
                            label: "ID",
                            field: "id",
                            width: 100,
                        },
                        {
                            label: "File Name",
                            field: "name",
                        },
                        {
                            label: "Uploaded",
                            field: "createdTsMoment",
                        },

                        {
                            field: 'status',
                            label: 'Processing Stage',
                        },
                        {
                            field: 'ApprovedFlag',
                            label: 'Approved',
                        },
                        {
                            label: "Action",
                            field: "action",
                        },
                    ],
                    rows: [].concat(result?.data?.packageFiles)
                        .map( (row) => {

                            return {
                                ...row,
                                createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.uploaded_ts}</Moment>),
                                ApprovedFlag: ( row.approved ? <MDBBadge pill className='mx-3'  color='success' light>Yes</MDBBadge>:<MDBBadge pill className='mx-3'  light>No</MDBBadge>),

                                action: (<Link to={"/admin/entity/" + params.entity_id + "/file/" + row.id}><MDBBtn size={"sm"}>View</MDBBtn></Link>)
                            }
                        })
                });





            })
            .catch((error) => {
                console.log("error", error);
            })
    }


    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);

    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <MDBCard >

            <MDBCardHeader>
                <h3>Uploads <span style={{fontSize:'12px'}}>(2GB limit)</span>
                    <span className="float-end">
                   <label className="btn btn-primary btn-sm" >Upload CSV<input type="file" onChange={UploadFiles} hidden   accept="text/csv" /> </label>
                    </span>
                </h3>


            </MDBCardHeader>
            <MDBCardBody>
                {dataLoading && <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>}

                {!dataLoading &&
                <MDBDatatable

                    sm={true}
                    search={true}
                    loading={loading}
                    data={datatable}
                    pagination={true}
                    advancedData={true}
                    hover={true}

                />
                }

            </MDBCardBody>


            <MDBModal open={basicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Upload Status</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <h4>{uploadStatus}</h4>
                            {uploadPercent>0 && <MDBProgress className="m-4"><MDBProgressBar width={uploadPercent} valuemin={0} valuemax={100} /></MDBProgress>}

                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn disabled={uploadPercent!==100} onClick={()=>setBasicModal(false)}>Continue</MDBBtn>
                            {uploadPercent ===0 &&  <MDBBtn color={"danger"} onClick={()=>setBasicModal(false)} disabled={uploadPercent===0}>Try Again</MDBBtn>}


                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


        </MDBCard>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
