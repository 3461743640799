import React, {useEffect, useState} from 'react';
import {
    MDBContainer,
    MDBSpinner, MDBTypography
} from 'mdb-react-ui-kit';
import Moment from "react-moment";
import {Link, useNavigate} from "react-router-dom";
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect, useDispatch} from "react-redux";
import {NotificationManager} from 'react-notifications';

import {default as EntityListing} from '../Components/EntityListing';




export  function Page({cuser}) {
    let navigate = useNavigate();
    if(cuser.isAuthenticated === false || cuser.isAdmin === true)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }


    return (
        <MDBContainer >
            <center>
                <MDBTypography tag='div' className='display-3 pt-5 pb-3 mb-3 '>
                Your Entities
                 </MDBTypography>
            </center>
            <EntityListing redirect={true} />
        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        cuser: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
