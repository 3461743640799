import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarItem,
     MDBNavbarBrand
} from 'mdb-react-ui-kit';
import logo from "../../Images/clearway_logo.svg";
import {useAuth0} from "@auth0/auth0-react";
import {connect} from "react-redux";
import AuthWatcher from "./AuthWatcher";
import {Link} from "react-router-dom";


export function Page({user}) {
    const { loginWithRedirect } = useAuth0();

    return (
        <header>
            <AuthWatcher />
            <MDBNavbar expand='sm'  style={{backgroundColor:"#e0d0ff", maxHeight:"5rem"}}>
                <MDBContainer >
                    <MDBNavbarBrand tag={Link} to={'/'} >
                        <img alt={"Clearway Logo"} src={logo} style={{width:'150px', marginRight:'10px'}} />
                    </MDBNavbarBrand>


                    <MDBNavbarNav className={"d-flex flex-row-reverse"}>

                        {user.isAuthenticated &&
                            <MDBNavbarItem className={"p-1"}>
                                <Link to={'/logout'}>
                                    <span className={"nav-link mainlink rounded-5 hover-shadow"}
                                          style={{color: '#8446ff'}}>Logout</span>
                                </Link>
                            </MDBNavbarItem>
                        }



                        {!user.isAuthenticated &&
                            <MDBNavbarItem className={"p-1"}>
                                <Link to={'/login'}>
                                    <span className={"nav-link mainlink rounded-5 hover-shadow"}
                                          style={{color: '#8446ff'}}>Client</span>
                                </Link>
                            </MDBNavbarItem>
                        }



                        {(user.isAuthenticated && !user.isAdmin) &&
                            <MDBNavbarItem className={"p-1"}>
                                <Link to={'/client'}>
                                    <span className={"nav-link mainlink rounded-5 hover-shadow"}
                                          style={{color: '#8446ff'}}>Entities</span>
                                </Link>
                            </MDBNavbarItem>
                        }


                        {(user.isAuthenticated && user.isAdmin) &&
                            <MDBNavbarItem className={"p-1"}>
                                <Link to={'/admin/home'}>
                                    <span className={"nav-link mainlink rounded-5 hover-shadow"}
                                          style={{color: '#8446ff'}}>Admin</span>
                                </Link>
                            </MDBNavbarItem>
                        }




                        {(!user.isAuthenticated) &&
                            <MDBNavbarItem className={"p-1"}>
                                    <span className={"nav-link mainlink rounded-5 hover-shadow"}
                                          style={{color: '#8446ff', cursor: 'pointer'}}
                                          onClick={() =>
                                              loginWithRedirect({redirectUri: window.location.origin + '/admin'}).then()}>Admin</span>
                            </MDBNavbarItem>
                        }









                    </MDBNavbarNav>
                </MDBContainer>
            </MDBNavbar>
        </header>


    );

}

const mapStateToProps = state => {

    return {
        user: state.user,
    };
};



export default connect(mapStateToProps)(Page)
