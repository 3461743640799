import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalFooter, MDBModalHeader, MDBModalTitle,
    MDBRow, MDBSpinner,
    MDBTypography, MDBModalDialog
} from "mdb-react-ui-kit";
import {gql, useMutation} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import eventBus from "../../../Context/EventBus";



const CREATE_ENTITY = gql`
mutation Add($name: String!)
{
    createEntity(name: $name) {
      id
      name
    }
}
`;




export function Page({user}) {

    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin ===false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }
    const [showNewEntity, setShowNewEntity] = useState(false);
    const toggleShow = () => setShowNewEntity(!showNewEntity);



    const [orgName, setOrgName] = useState("")
    const [addOrganization, { data, loading, error }] = useMutation(CREATE_ENTITY, {});



    function request()
    {
        addOrganization({
            variables: {name: orgName,},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}
        })
            .then( (result) => {
                NotificationManager.success("Created Entity, Redirecting you.")
                let id = result.data.createEntity.id;

                eventBus.dispatch("entity.create", { entityId: id });
                setShowNewEntity(false);
                //setTimeout( function() {navigate("/admin/entity/"+id);}, 1000);


            })
            .catch((error) => {
                setShowNewEntity(false);
                let details =JSON.parse(JSON.stringify(error));
                if(details.graphQLErrors) details.graphQLErrors.map( (row) => {NotificationManager.error(row.message)})
            })

    }


    if(loading) return( <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>)
    return (

        <div >
            <MDBBtn onClick={toggleShow} size={"sm"}>Create Entity</MDBBtn>



            <MDBModal tabIndex='-1' open={showNewEntity} onClose={setShowNewEntity}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Entity</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}/>
                        </MDBModalHeader>
                        <MDBModalBody >
                            <MDBRow style={{padding:'5px'}}>
                                <MDBCol>
                                    <MDBInput id='name' label='Entity Name' type='text'  value={orgName} onChange={(e) => {
                                        setOrgName(e.target.value);
                                    }} />
                                </MDBCol>
                            </MDBRow>


                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={()=>{toggleShow()}}>Cancel</MDBBtn>
                            <MDBBtn disabled={loading} onClick={request}>Create</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>





    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
