import React, {useEffect, useState} from 'react';
import {
    MDBContainer,
    MDBSpinner
} from 'mdb-react-ui-kit';

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import {useSelector, useDispatch, connect} from 'react-redux'
import {resetUser, setUser} from '../../Context/reducers/User/actions'
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {NotificationManager} from "react-notifications";

export function Page({cwuser}) {
    const dispatch = useDispatch()
    const { logout } = useAuth0();

    let navigate = useNavigate();

    useEffect(()=>{

        if(cwuser.isAdmin)
        {
            dispatch(resetUser());

            try{
                logout({ returnTo:window.location.origin+'/' });
            }catch (error){ console.log(error); }

           
        }
        else
        {
            dispatch(resetUser());


        }




    }, []) // <-- empty dependency array



    useEffect( () => {
        navigate("/")
    }, [])


    return (
        <MDBContainer style={{padding:'20px'}} >
            <div className="text-center" style={{paddingTop:"50px"}}>
                <MDBSpinner animation="grow"  variant="primary" />
            </div>
        </MDBContainer>
    );

}

const mapStateToProps = state => {

    return {
        cwuser: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
