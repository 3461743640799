import React, {useEffect, useState} from 'react';
import {
    Link
} from "react-router-dom";
import {
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBContainer,
    MDBIcon,
    MDBRow, MDBCol,
    MDBNavbarBrand,

    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse, MDBInput, MDBCheckbox, MDBCardHeader, MDBCardBody, MDBCard, MDBSpinner, MDBCardFooter
} from 'mdb-react-ui-kit';

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';

import {useSelector, useDispatch, connect} from 'react-redux'

import {resetUser, setUser} from '../../Context/reducers/User/actions'
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import loginimg from "../../Images/login.png";

const REQUEST_OTP = gql`
query Request($email: String!)
{
    authenticate(email: $email) {
      success
    }
}
`;


export function Page({cwuser}) {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch()
    let navigate = useNavigate();


    const [requestOtp, { loading, error, data }] = useLazyQuery(REQUEST_OTP);


    useEffect(()=>{
        if(cwuser.isAuthenticated)
        {
            navigate('/organization');

        }

    }, []) // <-- empty dependency array


    function login()
    {

        requestOtp({variables: {email: email}}).then( (result) => {
            if(result.data.authenticate.success === true)
            {
                NotificationManager.success("OTP Requested","Success")
                setEmail("");
                navigate('/login/'+email);
            }
            else
            {
                NotificationManager.error("OTP Request Failed", 'Oh No!');
            }

        })
        .catch( (error) => {
            NotificationManager.error("Api Error", 'Oh No!');
        })




    }





    return (
        <MDBContainer fluid className="p-3 my-5 h-custom">

            <MDBRow>

                <MDBCol sm={6} offsetSm={3} md={4} offsetMd={2} >
                    <img src={loginimg} className="img-fluid" alt="login image"  style={{padding:'15px'}} />
                </MDBCol>

                <MDBCol md={4} center={true}>

                    <MDBCard>
                        <MDBCardHeader>
                            <h3>Client Login</h3>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBInput wrapperClass='mb-4' label='Email address' id='formControlLg' type='email' size="lg" value={email} onChange={(e) => setEmail(e.target.value)}/>


                            <div className={"text-center text-md-start mt-4 pt-2"}>
                                <MDBBtn className="mb-0 px-5" size='lg' onClick={login} disabled={loading}>Login</MDBBtn>

                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <p className={"small fw-bold mt-2 pt-1 mb-2"}>We leverage One time passwords sent to your email for authentication.</p>
                        </MDBCardFooter>
                    </MDBCard>




                </MDBCol>

            </MDBRow>


        </MDBContainer>
    );

}

const mapStateToProps = state => {

    return {
        cwuser: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
