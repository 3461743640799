import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import MaterialReactTable from "material-react-table";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBDatatable,
    MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalHeader, MDBModalTitle,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {useNavigate} from "react-router-dom";



export function Page({user}) {

    let navigate = useNavigate();

    return (
        <MDBContainer >
            <MDBRow>
                <MDBCol sm={12} className=" p-4 text-center" >
                    <h1>Clearway Analysis Tool Help</h1>

                </MDBCol>

            </MDBRow>
            <MDBRow>
                <MDBCol sm={12} md={4}>
                    <MDBCard className="h-100  hover-shadow-soft border"  style={{cursor:"pointer"}} onClick={()=>window.open("https://bostonmedical.box.com/s/uqu8zylqoa3mtwck9oiju3x740d777gk")}>
                        <MDBCardHeader className="text-center">Production Support</MDBCardHeader>
                        <MDBCardBody className="text-center">
                            <MDBIcon fas icon="external-link-alt"  size={"4x"} />

                        </MDBCardBody>
                        <MDBCardFooter className="text-center">Click for Information</MDBCardFooter>

                    </MDBCard>
                </MDBCol>

                <MDBCol sm={12} md={4}>
                    <MDBCard className="h-100  hover-shadow-soft border" style={{cursor:"pointer"}} onClick={()=> navigate("/admin/help/data-spec")}>
                        <MDBCardHeader className="text-center">Client Spec</MDBCardHeader>
                        <MDBCardBody className="text-center">
                            <MDBIcon fab size={"4x"} icon="wpforms" />
                        </MDBCardBody>
                        <MDBCardFooter className="text-center">Click for Information</MDBCardFooter>

                    </MDBCard>
                </MDBCol>


                <MDBCol sm={12} md={4}>
                    <MDBCard className="h-100   hover-shadow  border" style={{cursor:"pointer"}} onClick={()=> navigate("/admin/help/data-dictionary")} >
                        <MDBCardHeader className="text-center">Data Dictionary</MDBCardHeader>
                        <MDBCardBody className="text-center">
                            <MDBIcon fas  size={"4x"} icon="database" />
                        </MDBCardBody>
                        <MDBCardFooter className="text-center">Click for Information</MDBCardFooter>
                    </MDBCard>
                </MDBCol>

            </MDBRow>


        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
