import React, {useEffect, useState} from 'react';
import {
    MDBBadge,
    MDBBtn,
    MDBDatatable,
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTypography
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";



export function Page({user}) {
    let navigate = useNavigate();
    const params= useParams()

    const GET_REVIEWS = gql`
          query Get($entityId: Long!, $packageId: Long!) {
            packageReviews(entityId: $entityId, packageId: $packageId) {
              id
              comments
              submitted_by
              submitted_ts
            }
          }
        `;
    const [getReviews, { loading, error, data }] = useLazyQuery(GET_REVIEWS);

    const [reviews, setReviews] = useState([]);

    useEffect(()=> {loadData();}, [user]);


    function loadData() {
        getReviews({
            variables:{
                entityId: Number(params.entity_id),
                packageId: Number(params.package_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}
        }).then( (result) => {
            setReviews(result.data.packageReviews);
        })
    }


    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    return (

        <div>
            <table className="table table-sm">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>By</th>
                    <th>Comments</th>
                </tr>
                </thead>
                <tbody>
                {reviews.length === 0 && (<tr><td colSpan={3}>No Reviews Found</td></tr>)}
                {reviews.map( (row,id) => {
                    return(<tr key={id}>
                        <td>{row.id}</td>
                        <td>{row.submitted_by}</td>
                        <td>{row.comments}</td>
                    </tr>)
                })}
                </tbody>
            </table>
        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
