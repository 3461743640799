import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownItem as MDBDropdownLink,
    MDBCollapse,
    MDBSpinner,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBNavbarLink, MDBCardHeader,
} from "mdb-react-ui-kit";
import {Link, Route, Routes, useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import {default as EntityPackageListing} from "../../Components/EntityPackageListing";
import {Container} from "@mui/material";
import {default as PackageHome} from "./Package";
import EntityAccessListing from "../../Components/EntityAccessListing";


import {default as Access} from "./Access";
import {default as Settings} from "./Settings";
import {default as FileData} from "./FileData";


import {default as EntityDashboard} from "./Dashboard";
import {default as EntityFiles} from "./Files";

import {default as DrugClass} from "./DrugClass";
import {default as DrugClassDetails} from "./DrugClassDetails";
import {default as DrugFinal} from "./DrugFinal";
import {default as DrugStage} from "./DrugStage";



import {default as DepartmentClass} from "./DepartmentClass";
import {default as DepartmentClassDetails} from "./DepartmentClassDetails";
import {default as DepartmentFinal} from "./DepartmentFinal";
import {default as DepartmentStage} from "./DepartmentStage";



import {default as PatientStage} from "./PatientStage";
import {default as PatientFinal} from "./PatientFinal";


import {default as ProviderFinal} from "./ProviderFinal";
import {default as ProviderStage} from "./ProviderStage";



import {default as PrescriptionStage} from "./PrescriptionStage";
import {default as PrescriptionFinal} from "./PrescriptionFinal";



import {default as InsuranceClass} from "./InsuranceClass";
import {default as InsuranceClassDetails} from "./InsuranceClassDetails";
import {default as InsuranceFinal} from "./InsuranceFinal";
import {default as InsuranceStage} from "./InsuranceStage";


import {default as PharmacyClass} from "./PharmacyClass";
import {default as PharmacyStage} from "./PharmacyStage";
import {default as PharmacyClassDetails} from "./PharmacyClassDetails";
import eventBus from "../../../../Context/EventBus";






const GET_ENTITY = gql`
  query Get($id: Long!) {
    entity(entityId: $id) {
      id
      name
      created
      updated
    }
   
  }
`;






export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()




    const { loading, error, data, refetch } = useQuery(GET_ENTITY, {
        variables:{
            id: Number(params.entity_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });


    eventBus.on("entity.update", () =>{
      refetch().then();
    });



    if(error)
    {
        //need to redirect.
        // navigate('/client');
    }


    const [showNavCentred, setShowNavCentred] = useState(false);

    const [verticalActive, setVerticalActive] = useState('packages');

    const handleVerticalClick = (value: string) => {
        if (value === verticalActive) {
            return;
        }

        setVerticalActive(value);
    };

    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <Container >
            <MDBCard>
                <MDBCardBody>
                    <MDBNavbar expand='sm' light bgColor='light' style={{height:'3rem'}}>
                        <MDBContainer >
                            <MDBNavbarToggler
                                type='button'
                                data-target='#entityNavBar'
                                aria-controls='entityNavBar'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => setShowNavCentred(!showNavCentred)}
                            >
                                <MDBIcon icon='bars' fas /> Menu
                            </MDBNavbarToggler>

                            <MDBCollapse navbar show={showNavCentred}  id='entityNavBar'>
                                <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>


                                    <MDBNavbarItem >
                                        <Link to={'/admin/entity/' + params.entity_id}><span className={"nav-link active "}> {data?.entity.name}</span></Link>

                                    </MDBNavbarItem>



                                    <MDBNavbarItem>
                                        <Link to={'settings'}><span className={"nav-link "}>Settings</span></Link>
                                    </MDBNavbarItem>


                                    {/*<MDBNavbarItem>*/}
                                    {/*    <MDBDropdown>*/}
                                    {/*        <MDBDropdownToggle tag='a' className='nav-link' role='button'>Settings</MDBDropdownToggle>*/}
                                    {/*        <MDBDropdownMenu>*/}

                                    {/*            <Link to={'access'}><MDBDropdownLink >Access</MDBDropdownLink></Link>*/}
                                    {/*            <Link to={'entity'}><MDBDropdownLink >Entity</MDBDropdownLink></Link>*/}

                                    {/*            /!*<MDBDropdownLink style={{cursor:"pointer"}}>340B ID</MDBDropdownLink>*!/*/}
                                    {/*        </MDBDropdownMenu>*/}
                                    {/*    </MDBDropdown>*/}

                                    {/*</MDBNavbarItem>*/}

                                    {/*<MDBNavbarItem>*/}
                                    {/*    <Link to={'package'}><span className={"nav-link "}>Packages</span></Link>*/}
                                    {/*</MDBNavbarItem>*/}

                                    <MDBNavbarItem>
                                        <Link to={'file'}><span className={"nav-link "}>Files</span></Link>
                                    </MDBNavbarItem>




                                    <MDBNavbarItem >
                                        <MDBDropdown>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>Staging</MDBDropdownToggle>
                                            <MDBDropdownMenu>
                                                <MDBDropdownItem link onClick={()=>navigate("stage/department")}>Departments</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("stage/drug")}>Drugs</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("stage/insurance")}>Insurance</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("stage/patient")}>Patients</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("stage/pharmacy")}>Pharmacies</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("stage/prescription")}>Prescriptions</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("stage/provider")}>Providers</MDBDropdownItem>

                                            </MDBDropdownMenu>
                                        </MDBDropdown>

                                    </MDBNavbarItem>



                                    <MDBNavbarItem>
                                        <MDBDropdown>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>Classing</MDBDropdownToggle>
                                            <MDBDropdownMenu>
                                                <MDBDropdownItem link onClick={()=>navigate("class/department")}>Departments</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("class/drug")}>Drugs</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("class/insurance")}>Insurance</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={()=>navigate("class/pharmacy")}>Pharmacies</MDBDropdownItem>
                                            </MDBDropdownMenu>
                                        </MDBDropdown>

                                    </MDBNavbarItem>


                                </MDBNavbarNav>
                            </MDBCollapse>
                        </MDBContainer>
                    </MDBNavbar>
                </MDBCardBody>
                <MDBCardBody>
                    <main>
                        <Routes>
                            <Route path="/"  element={<EntityDashboard />} />
                            <Route path="/package"  element={<EntityPackageListing />} />
                            <Route path="/package/:package_id"  element={<PackageHome />} />
                            <Route path="/access"  element={   <Access />} />
                            <Route path="/file"  element={   <EntityFiles />} />
                            <Route path="/file/:file_id"  element={   <FileData />} />

                            <Route path="/settings"  element={   <Settings />} />

                            {/* Drugs */}
                            <Route path="/class/drug"  element={   <DrugClass />} />
                            <Route path="/class/drug/:drug_id"  element={   <DrugClassDetails />} />
                            <Route path="/final/drug"  element={   <DrugFinal />} />
                            <Route path="/stage/drug"  element={   <DrugStage />} />

                            {/* Departments */}
                            <Route path="/class/department"  element={   <DepartmentClass />} />
                            <Route path="/class/department/:department_id"  element={   <DepartmentClassDetails />} />
                            <Route path="/final/department"  element={   <DepartmentFinal />} />
                            <Route path="/stage/department"  element={   <DepartmentStage />} />

                            {/* Pharmacy */}
                            <Route path="/class/pharmacy"  element={   <PharmacyClass />} />
                            <Route path="/class/pharmacy/:pharmacy_id"  element={   <PharmacyClassDetails />} />
                            <Route path="/stage/pharmacy"  element={   <PharmacyStage />} />
                            <Route path="/final/pharmacy"  element={   <PharmacyClass />} />



                            {/* Insurance */}
                            <Route path="/class/insurance"  element={   <InsuranceClass />} />
                            <Route path="/class/insurance/:insurance_id"  element={   <InsuranceClassDetails />} />
                            <Route path="/final/insurance"  element={   <InsuranceFinal />} />
                            <Route path="/stage/insurance"  element={   <InsuranceStage />} />


                            {/* Patient */}
                            {/*<Route path="/class/patient"  element={   <EntityPharmacy />} />*/}
                            <Route path="/stage/patient"  element={   <PatientStage />} />
                            <Route path="/final/patient"  element={   <PatientFinal />} />


                            {/* Prescription */}
                            {/*<Route path="/class/prescription"  element={   <EntityPharmacy />} />*/}
                            <Route path="/stage/prescription"  element={   <PrescriptionStage />} />
                            <Route path="/final/prescription"  element={   <PrescriptionFinal />} />


                            {/* Provider */}
                            {/*<Route path="/class/prescription"  element={   <EntityPharmacy />} />*/}
                            <Route path="/stage/provider"  element={   <ProviderStage />} />
                            <Route path="/final/provider"  element={   <ProviderFinal />} />






                        </Routes>
                    </main>
                </MDBCardBody>




            </MDBCard>


            <MDBRow className='justify-content-center'>
                <MDBCol sm={10} >



                    {/*<MDBRow >*/}
                    {/*    <MDBCol size='3'>*/}
                    {/*        <MDBTabs className='flex-column '   >*/}
                    {/*            <MDBTabsItem>*/}
                    {/*                <MDBTabsLink onClick={() => handleVerticalClick('packages')} active={verticalActive === 'packages'}>*/}
                    {/*                    <MDBIcon fas icon="laptop-medical" className='me-2' /> Packages*/}
                    {/*                </MDBTabsLink>*/}
                    {/*            </MDBTabsItem>*/}
                    {/*            <MDBTabsItem>*/}
                    {/*                <MDBTabsLink onClick={() => handleVerticalClick('access')} active={verticalActive === 'access'}>*/}
                    {/*                    <MDBIcon far icon='file' className='me-2' /> Access*/}
                    {/*                </MDBTabsLink>*/}
                    {/*            </MDBTabsItem>*/}


                    {/*            <MDBTabsItem>*/}
                    {/*                <MDBTabsLink onClick={() => handleVerticalClick('drug')} active={verticalActive === 'drug'}>*/}
                    {/*                    <MDBIcon far icon='file' className='me-2' /> Entity Drug*/}
                    {/*                </MDBTabsLink>*/}
                    {/*            </MDBTabsItem>*/}

                    {/*            /!*<MDBTabsItem>*!/*/}
                    {/*            /!*    <MDBTabsLink onClick={() => handleVerticalClick('tab3')} active={verticalActive === 'tab3'}>*!/*/}
                    {/*            /!*        <MDBIcon far icon="comments" /> Messages*!/*/}
                    {/*            /!*    </MDBTabsLink>*!/*/}
                    {/*            /!*</MDBTabsItem>*!/*/}
                    {/*        </MDBTabs>*/}
                    {/*    </MDBCol>*/}
                    {/*    <MDBCol size='9'>*/}
                    {/*        <MDBTabsContent  >*/}
                    {/*            <MDBTabsPane show={verticalActive === 'packages'}  >*/}
                    {/*              <span className="text-center m-5">*/}
                    {/*                     <MDBTypography tag='h2'>Packages</MDBTypography>*/}
                    {/*                    <span className=""> TBD </span>*/}
                    {/*                </span>*/}
                    {/*                <EntityPackageListing />*/}

                    {/*            </MDBTabsPane>*/}
                    {/*            <MDBTabsPane show={verticalActive === 'access'}>*/}
                    {/*                <span className="text-center m-5">*/}
                    {/*                     <MDBTypography tag='h2'>Access</MDBTypography>*/}
                    {/*                    <span className=""> <EntityAddAccess /></span>*/}
                    {/*                </span>*/}


                    {/*                <EntityAccessListing />*/}

                    {/*            </MDBTabsPane>*/}
                    {/*            <MDBTabsPane show={verticalActive === 'tab3'}>Messages content</MDBTabsPane>*/}
                    {/*        </MDBTabsContent>*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}


                </MDBCol>


            </MDBRow>



        </Container>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
