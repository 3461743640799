import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead, MDBTableBody, MDBCard, MDBCardBody, MDBCardHeader, MDBBreadcrumb, MDBBreadcrumbItem, MDBTypography,
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import CountUp from 'react-countup';

import {default as DataQuality} from "../../Components/PackageDataQuality";
import {default as RunChecks} from "../../Components/RunPackageDataQualityChecksBtn";
import {default as FileListing} from "../../Components/PackageFileListing";
import {default as PackageReviews} from "../../Components/PackageReviews";


const GET_ORGANIZATION = gql`
  query Get($id: Long!, $packageId: Long!) {
    entity(entityId: $id) {
      id
      name
      created
      updated
      
    }
    package(entityId: $id, packageId: $packageId){
        id
        is_submitted
    }
     packageFileCount(entityId: $id, packageId: $packageId){
        count
    }
   
  }
`;




export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()




    const { loading, error, data, refetch } = useQuery(GET_ORGANIZATION, {
        variables:{
            id: Number(params.entity_id),
            packageId: Number(params.package_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });


    if(error)
    {
        //need to redirect.
        // navigate('/client');
    }

    const [verticalActive, setVerticalActive] = useState('tab1');

    const handleVerticalClick = (value: string) => {
        if (value === verticalActive) {
            return;
        }

        setVerticalActive(value);
    };



    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);

    else return (

        <MDBContainer >

            <MDBBreadcrumb className='p-1 '>
                <MDBBreadcrumbItem>
                    <Link to={"/"}>Home</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entities</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/"+params.entity_id}>{data.entity.name}</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    Package
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>{params.package_id}</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBRow >
                <MDBCol size='12'>
                    <MDBTabs className='mb-3 '   >
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                                <MDBIcon fas icon="laptop-medical" className='me-2' /> Files
                            </MDBTabsLink>
                        </MDBTabsItem>

                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleVerticalClick('review')} active={verticalActive === 'review'}>
                                <MDBIcon far icon='file' className='me-2' /> Review
                            </MDBTabsLink>
                        </MDBTabsItem>

                        <MDBTabsItem >
                            <MDBTabsLink  onClick={() => handleVerticalClick('dataquality')} active={verticalActive === 'dataquality'} >
                                <MDBIcon fas icon="diagnoses" /> Data Quality
                            </MDBTabsLink>
                        </MDBTabsItem>


                        {/*<MDBTabsItem >*/}
                        {/*    <MDBTabsLink  onClick={() => handleVerticalClick('PatientStage')} active={verticalActive === 'PatientStage'} >*/}
                        {/*        <MDBIcon fas icon="diagnoses" /> Patient Stage*/}
                        {/*    </MDBTabsLink>*/}
                        {/*</MDBTabsItem>*/}

                    </MDBTabs>
                </MDBCol>

                <MDBCol size='12'>
                    <MDBTabsContent >
                        <MDBTabsPane open={verticalActive === 'tab1'} >

                            <MDBRow style={{padding:'1rem'}}>
                                <MDBCol >
                                    <FileListing />
                                </MDBCol>
                            </MDBRow>


                        </MDBTabsPane>
                        <MDBTabsPane open={verticalActive === 'review'}>
                            <PackageReviews />
                        </MDBTabsPane>

                        <MDBTabsPane open={verticalActive === 'PatientStage'}>
                             <span className="text-center center m-5">
                                <MDBTypography tag='h2'>Patient Staging</MDBTypography>
                            </span>
                            {/*<PackagePatientStage />*/}
                        </MDBTabsPane>


                        <MDBTabsPane open={verticalActive === 'dataquality'}>
                            <span className="text-center center m-5">
                                <MDBTypography tag='h2'>Data Quality Checks</MDBTypography>
                                <span className=""> <RunChecks /></span>
                            </span>
                            <DataQuality/>
                        </MDBTabsPane>


                    </MDBTabsContent>
                </MDBCol>
            </MDBRow>




        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
