import React, {useState} from 'react';
import {
    MDBCol,
    MDBContainer, MDBIcon,
    MDBRow,
    MDBTableHead, MDBTypography
} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";
import {default as UserListing} from "../../Components/UserListing";



export default function Page() {
    return (
        <MDBContainer >
            <MDBContainer >
                <MDBRow className=' text-center pt-4' >
                    <MDBCol>
                        <MDBTypography tag='h2'>Users</MDBTypography>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-center pt-4' >
                    <MDBCol sm={10}>
                        <UserListing />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBContainer>
    );
}