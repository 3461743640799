import React, { useState, useRef, useEffect } from 'react';

import {Link, Route, Routes} from "react-router-dom";
import {default as Home} from "./Home";
import {default as Login} from "./Login";
import {default as EntityHome} from "./Pages/Entity/Home";
import {default as EntityDetails} from "./Pages/Entity/Details";
import {default as OrganizationHome} from "./Pages/Organization/Home";
import {default as OrganizationDetail} from "./Pages/Organization/Details";
import {default as UserHome} from "./Pages/User/Home";
import {default as DrugHome} from "./Pages/Drug/Home";
import {default as DrugDetails} from "./Pages/Drug/Details";
import {default as PharmacyHome} from "./Pages/Pharmacy/Home";
import {default as InsuranceHome} from "./Pages/Insurance/Home";
import {default as ProviderHome} from "./Pages/Provider/Home";
import {default as HelpDataDictionary} from "./Pages/Help/DataDictionary";
import {default as HelpDataSpec} from "./Pages/Help/DataSpec";
import {default as HelpDataQuality} from "./Pages/Help/DataQuality";
import {default as HelpHome} from "./Pages/Help/Home";
import {default as SystemHome} from "./Pages/System";


export default function App() {
    return (
        <>
            <div style={{marginLeft:'5rem', marginTop:'2rem', marginRight:'5rem'}} >

                <main>
                    <Routes>
                        <Route path="/"  element={<Login />} />
                        <Route path="/home"  element={<Home />} />
                        <Route path="/organization"  element={<OrganizationHome />} />
                        <Route path="/organization/:organization_id"  element={<OrganizationDetail />} />
                        <Route path="/entity"  element={<EntityHome />} />
                        <Route path="/entity/:entity_id/*"  element={<EntityDetails />} />
                        <Route path="/drug"  element={<DrugHome />} />
                        <Route path="/drug/:ndc"  element={<DrugDetails />} />
                        <Route path="/pharmacy"  element={<PharmacyHome />} />
                        <Route path="/insurance"  element={<InsuranceHome />} />
                        <Route path="/provider"  element={<ProviderHome />} />
                        <Route path="/user"  element={<UserHome />} />
                        <Route path="/help"  element={<HelpHome />} />
                        <Route path="/help/data-dictionary"  element={<HelpDataDictionary />} />
                        <Route path="/help/data-spec"  element={<HelpDataSpec />} />
                        <Route path="/help/data-quality"  element={<HelpDataQuality />} />
                        <Route path="/system"  element={<SystemHome />} />
                    </Routes>
                </main>
            </div>
        </>
    );
}