import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBCardFooter, MDBBtn, MDBSwitch,MDBSelect
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";


const GET_LOOKUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;




const GET_DATA = gql`
  query Get($id: Long!, $entityId: Long!) {
    classDrug(entityId: $entityId, id: $id)
    {
        id
        name
        ndc
        ldd_classification_id
        ldd_classification{
            value
        }
       
       drug {
        ndc
        gpi
        name
        strength
        strength_unit_of_measure
        dosage_form
        clearwayDrug {
            ndc
            classification_id
            is_orphaned
            classification {
             value
            }
        }
       }
        
    }
  }
`;



const SET_DRUG_LDD = gql`
mutation set($entityId: Long!, $id: Long!, $lddId: Long! )
{
    setEntityDrugClassLDD(entityId: $entityId, id: $id, ldd_classificatino_id:$lddId) {
        successful
    }
}
`;


const SET_DRUG_NDC = gql`
mutation set($entityId: Long!, $id: Long!, $ndc: String!)
{
    setEntityDrugClassNdc(ndc: $ndc, entityId: $entityId, id: $id) {
        successful
    }
}
`;



export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }
    const [ndcChanged, setNdcChanged] = useState(false);

    const [ndc, setNdc] = useState("");
    const [timer, setTimer] = useState(null);

    const params= useParams()
    const [drugData, setDrugData] = useState({});
    const [drugClassing, setDrugClassing] = useState([]);
    const [drugEdit, setDrugEdit] = useState(false);
    const [getDrugData,{error: getDrugDataError, loading: getDrugDataLoading}] = useLazyQuery(GET_DATA);
    const [getLookup,{error: getLookupError, loading: getLookupLoading}] = useLazyQuery(GET_LOOKUP);
    const [setDrugLdd] = useMutation(SET_DRUG_LDD);
    const [setNDC] = useMutation(SET_DRUG_NDC);

    const [dataClassificationSelect, setDataClassificationSelect] = useState([]);



    function updateLddClassification(event)
    {
        setDrugLdd({
            variables: {
                lddId: event.value,
                id: Number(params.drug_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })


    }






    function saveNDC()
    {
        setNDC({
            variables: {
                ndc: ndc,
                id: Number(params.drug_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");
                loadData();
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })

    }



    useEffect(()=> {loadData(); }, [user]);

    function loadData()
    {
        getLookup({
            variables: {
                id: 'drug_ldd_classing',

            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {

                let data = result.data.groupLookupValues.map( (row) => {
                    return {text:row.value, value:row.id}
                })

                setDrugClassing(data)
            })

        getDrugData({
            variables: {
                id: Number(params.drug_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }


                setDrugData(result.data.classDrug);
            })
    }


    useEffect(() => {

        var selectOptions = drugClassing.map( (row) => {
            if(row.value === drugData?.ldd_classification_id )
                row.defaultSelected = true
            return row;
        })

        setDataClassificationSelect(selectOptions);
    }, [drugClassing, drugData]);



    useEffect(()=> { setNdc(drugData.ndc) }, [drugData]);



    if(getDrugDataLoading || getLookupLoading)
        return (
            <MDBContainer>
                <MDBBreadcrumb className='p-1 '>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin"}>Clearway</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity"}>Entity</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <>Classing</>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id + '/class/drug'}>Drug</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                        <>{params.drug_id}</>
                    </MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>
            </MDBContainer>
        );
    else return (

        <MDBContainer >
            <MDBBreadcrumb className='p-1 '>
                <MDBBreadcrumbItem>
                    <Link to={"/admin"}>Clearway</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entity</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <>Classing</>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id + '/class/drug'}>Drug</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                    <>{params.drug_id}</>
                </MDBBreadcrumbItem>


            </MDBBreadcrumb>
            <MDBRow>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>


                    <MDBCard>
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Drug Information </MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div>
                                <MDBTable>
                                    <MDBTableBody>
                                        <tr>
                                            <th>NDC</th>
                                            <td>{drugData?.ndc}</td>
                                        </tr>
                                        <tr>
                                            <th>Drug Name</th>
                                            <td>{drugData?.drug?.name ?? "Unknown"}</td>
                                        </tr>
                                        <tr>
                                            <th>GPI</th>
                                            <td>{drugData?.drug?.gpi  ?? "Unknown"}</td>
                                        </tr>
                                        <tr>
                                            <th>Form</th>
                                            <td>{drugData?.drug?.dosage_form  ?? "Unknown"}</td>
                                        </tr>
                                        <tr>
                                            <th>Strength</th>
                                            <td>{drugData?.drug?.strength  ?? "Unknown"}</td>
                                        </tr>
                                        <tr>
                                            <th>Strength Units</th>
                                            <td>{drugData?.drug?.strength_unit_of_measure  ?? "Unknown"}</td>
                                        </tr>

                                        <tr>
                                            <th>Clearway Classification</th>
                                            <td>{drugData?.drug?.clearwayDrug?.classification?.value  ?? "Unknown"}</td>
                                        </tr>
                                        <tr>
                                            <th>Orphan</th>
                                            <td>{drugData?.drug?.clearwayDrug?.is_orphaned ? "Yes" : "No"  ?? "Unknown"}</td>
                                        </tr>
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>
                    <MDBCard>
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Drug Classification</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody >
                            <MDBContainer>

                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label='ID'  type='text' value={drugData.id} disabled />
                                        <br/>
                                        <MDBInput label='Drug Name'  type='text' value={drugData.name} disabled />
                                        <br/>


                                        <MDBInput  label='NDC'  style={{width:'auto'}} value={ndc} onChange={(e)=>{setNdc(e.target.value); setNdcChanged(true)  }} />

                                        {ndc !== drugData.ndc && <MDBBtn className="form-control mb-3 mt-1" disabled={!ndcChanged} onClick={() => saveNDC()} >Save NDC</MDBBtn>}



                                        <br />
                                        <MDBSelect
                                            label='LDD Classification'

                                            search
                                            data={dataClassificationSelect}
                                            onValueChange={(e) =>updateLddClassification(e)}
                                        />
                                        <br />

                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>







                        </MDBCardBody>
                    </MDBCard>



                </MDBCol>
            </MDBRow>


        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
