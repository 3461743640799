// FETCH ZIPCODES ACTION NAMES

export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';




export const setUser = (token) => ({
    type: SET_USER,
    token: token
});


export const resetUser = () => ({
    type: RESET_USER
});



