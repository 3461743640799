import React from 'react';
import {Link} from "react-router-dom";
import humandata from "../../Images/data-portal-hero-banner.png"
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../Context/reducers/User/actions";
import {connect} from "react-redux";

export function Page({user}) {



    return (

        <div >

            <div className="bg" style={{ backgroundImage: `url(${humandata})  `}} />
            {/*<div id='intro-example'  className=' text-center ' style={{ backgroundImage: `url(${humandata}) `}} >*/}
            {/*    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>*/}
            {/*        <div className='d-flex justify-content-center align-items-center h-100'>*/}
            {/*            <div className='text-white'>*/}

            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
