import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBContainer,
    MDBSpinner,
    MDBRow,
    MDBCol,
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import instructions from "../../../Images/data-portal-instructions-headline.png";
import {default as Instructions} from "../Components/EntityInstructions";
import {default as Packages} from "../Components/PackagesListing";

const GET_ORGANIZATION = gql`
  query Get($id: Long!) {
    entity(entityId: $id) {
      id
      name
      created
      updated
    }
   
  }
`;




export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === true)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()




    const { loading, error, data, refetch } = useQuery(GET_ORGANIZATION, {
        variables:{
            id: Number(params.entity_id)
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });


    if(error)
    {
        //need to redirect.
       // navigate('/client');
    }



    const [verticalActive, setVerticalActive] = useState('tab1');

    const handleVerticalClick = (value: string) => {
        if (value === verticalActive) {
            return;
        }

        setVerticalActive(value);
    };

    if(loading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <MDBContainer >
            <div className='p-5 bg-light mb-4 file-upload-wrapper' style={{ backgroundImage: `url(${instructions})`, backgroundRepeat:'no-repeat', backgroundSize: "cover" }} >
                <h4 style={{paddingTop:'5rem', paddingLeft:'6rem'}}>Entity: {data?.entity.name} </h4>
            </div>
            <MDBRow >
                <MDBCol xs={12}>
                    <Instructions />
                </MDBCol>

                <MDBCol xs={12}>
                    <Packages />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
