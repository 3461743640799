import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import MaterialReactTable from "material-react-table";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBDatatable,
    MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalHeader, MDBModalTitle,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";





export function Page({user}) {

    const [optSmModal, setOptSmModal] = useState(false);
    const [selectedTable, setSelectedTable] = useState(false);

    const toggleShow = () => setOptSmModal(!optSmModal);


    function showEdit(table)
    {
        setSelectedTable(table);
        setOptSmModal(true);
    }




    //optionally access the underlying virtualizer instance
    const rowVirtualizerInstanceRef = useRef(null);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState([]);



    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);


    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol className="text-center">
                    <h1>Opportunity Analysis Data Spec Requisition Details</h1>
                    <h6>This document outlines the data specification requirements for opportunity analysis. For all documents, please return in a CSV
                        format with header.</h6>
                </MDBCol>

            </MDBRow>

            <MDBRow>
                <MDBCol lg={10} offsetLg={1}  md={8} offsetSm={2} className=" pt-4" >
                    <MDBTable className="table-bordered">
                        <thead>
                        <tr>
                            <th>Requirement</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>File Format</td>
                            <td>CSV</td>
                        </tr>
                        <tr>
                            <td>Text Qualifier</td>
                            <td>Quotes</td>
                        </tr>
                        <tr>
                            <td>Column Headers</td>
                            <td>Required</td>
                        </tr>
                        <tr>
                            <td>Extraction Time Period</td>
                            <td>Previous 12 Months</td>
                        </tr>
                        <tr>
                            <td>Maximum File Size</td>
                            <td>2GB</td>
                        </tr>
                        </tbody>
                    </MDBTable>

                </MDBCol>

                <MDBCol lg={10} offsetLg={1}  md={8} offsetSm={2} className=" pt-4" >
                    <h2>Description of Required Data</h2>

                </MDBCol>

                <MDBCol lg={10} offsetLg={1}  md={8} offsetSm={2} className=" pt-4" >

                    <MDBTable className="table-bordered">
                        <thead>
                        <tr>
                            <th>Requested Data Types</th>
                            <th>Description & Source</th>
                            <th>Specification</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <td>
                                <h5>1. Electronic Medical Records (EMR) prescription orders</h5>
                            </td>
                            <td>
                                <ul>
                                    <li>All Electronic Medical Record systems for ambulatory clinics</li>
                                    <li>Detailed transactional medication prescription data from the medical records or
                                        vendor (e.g., Surescripts)
                                    </li>
                                    <li>Any medication that is authorized by a prescribing clinician via e-prescribe,
                                        fax, or verbal order to an internal or external pharmacy or written/printed and
                                        given directly to the patient
                                    </li>
                                    <li>YTD to end of previous month (Example: If today is Feb 4th, 2022, the request is
                                        for 2/1/2021 – 1/13/2022)
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Exhibit A
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h5>2. Prescription Claims (Revenue)</h5>
                            </td>
                            <td>
                                Out of Scope for CAT
                            </td>
                            <td>
                                Out of Scope for CAT
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h5>3. 340B Eligible Departments</h5>
                            </td>
                            <td>
                                Out of Scope for CAT
                            </td>
                            <td>
                                Out of Scope for CAT
                            </td>
                        </tr>

                        </tbody>
                    </MDBTable>

                </MDBCol>

                <MDBCol lg={10} offsetLg={1} md={8} offsetSm={2} className=" pt-4">
                    <h2>Exhibit A: EMR Prescription Orders</h2>

                    <MDBTable className="table-bordered m-5">
                        <thead>
                        <tr>
                            <th>Field Header</th>
                            <th>Required</th>
                            <th>Data Type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>patient_id</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Patient medical record number, account number, or randomized (de-identified) unique
                                patient identifier to calculate distinct patient counts, panel sizes, etc.
                            </td>
                        </tr>

                        <tr>
                            <td>patient_primary_insurance_id</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Primary insurance as defined in health record or financial systems</td>
                        </tr>
                        <tr>
                            <td>insurance_id</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>System identifier for the insurance</td>
                        </tr>
                        <tr>
                            <td>insurance_name</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Name of the insurance</td>
                        </tr>
                        <tr>
                            <td>insurance_type</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>The type of patient primary insurance (e.g., Medicare, Medicaid, Commercial, etc.)</td>
                        </tr>
                        <tr>
                            <td>prescription_id</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Prescription order identifier</td>
                        </tr>
                        <tr>
                            <td>drug_name</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Name of the prescribed drug (e.g., Tylenol Extra Strength 500 mg Tablets)</td>
                        </tr>
                        <tr>
                            <td>prescribed_form</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>The drug form ( e.g., Pen, capsule, tablet)</td>
                        </tr>
                        <tr>
                            <td>ndc</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>National Drug Code, a unique 11-digit 3-segment number can be used in place of GPI</td>
                        </tr>
                        <tr>
                            <td>quantity_prescribed</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Quantity of prescribed drug</td>
                        </tr>
                        <tr>
                            <td>refills_prescribed</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Number of refills prescription was authorized</td>
                        </tr>
                        <tr>
                            <td>prescriber_first_name</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>First name of authorizing prescriber of prescription</td>
                        </tr>
                        <tr>
                            <td>prescriber_last_name</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Last name of authorizing prescriber of prescription</td>
                        </tr>
                        <tr>
                            <td>prescriber_npi</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>National Provider Identifier, 10-digit numeric ID ( e.g., 1972813608)</td>
                        </tr>
                        <tr>
                            <td>department_id</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>System identifier for department</td>
                        </tr>
                        <tr>
                            <td>department_name</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Name of the department</td>
                        </tr>
                        <tr>
                            <td>department_address_1</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Address of the department</td>
                        </tr>
                        <tr>
                            <td>department_address_2</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Address of the department</td>
                        </tr>
                        <tr>
                            <td>department_city</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>City of the department</td>
                        </tr>
                        <tr>
                            <td>department_state</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>State of the department</td>
                        </tr>
                        <tr>
                            <td>department_zip</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Zip of the department</td>
                        </tr>
                        <tr>
                            <td>participating_340b_id</td>
                            <td>No</td>
                            <td>varchar</td>
                            <td>Covered entity’s unique number that manufacturers, wholesalers, and others will use to
                                verify your participation in the 340B Program (starts with CAH, CHC, DSH, PED, SCH,
                                etc.)
                            </td>
                        </tr>
                        <tr>
                            <td>participating_340b_start_date</td>
                            <td>No</td>
                            <td>varchar</td>
                            <td>The date that a department becomes “Active” under HRSA OPAIS’s registration website</td>
                        </tr>
                        <tr>
                            <td>ordering_class_method</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>E-prescribe, written, fax, verbal</td>
                        </tr>
                        <tr>
                            <td>ordering_status</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Sent, discontinued, error, printed</td>
                        </tr>
                        <tr>
                            <td>date_prescribed</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Date prescribed and sent to pharmacy</td>
                        </tr>
                        <tr>
                            <td>pharmacy_name</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Recipient pharmacy name of the electronic prescription order</td>
                        </tr>
                        <tr>
                            <td>pharmacy_id</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Internal identifier of the pharmacy</td>
                        </tr>
                        <tr>
                            <td>pharmacy_npi</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>National Provider Identifier, 10-digit numeric ID (e.g., 1972813608)</td>
                        </tr>
                        <tr>
                            <td>pharmacy_address_1</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Address of pharmacy</td>
                        </tr>
                        <tr>
                            <td>pharmacy_address_2</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Address (secondary line) of pharmacy</td>
                        </tr>
                        <tr>
                            <td>pharmacy_city</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>City of pharmacy</td>
                        </tr>
                        <tr>
                            <td>pharmacy_state</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>State of pharmacy</td>
                        </tr>
                        <tr>
                            <td>pharmacy_zip</td>
                            <td>Yes</td>
                            <td>varchar</td>
                            <td>Zip of pharmacy</td>
                        </tr>
                        </tbody>
                    </MDBTable>

                </MDBCol>


                <MDBCol lg={10} offsetLg={1} md={8} offsetSm={2} className=" pt-4">
                    <h2>Exhibit B: Prescription Claims (Revenue) </h2>
                    <h5>Outside the scope of CAT.</h5>
                </MDBCol>


                <MDBCol lg={10} offsetLg={1} md={8} offsetSm={2} className=" pt-4">
                    <h2>Exhibit C: 340B Eligible Departments</h2>
                    <h5>Outside the scope of CAT.</h5>
                </MDBCol>


            </MDBRow>



        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
