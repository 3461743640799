import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalFooter, MDBModalHeader, MDBModalTitle,
    MDBRow, MDBSpinner,
    MDBTypography, MDBModalDialog
} from "mdb-react-ui-kit";
import {gql, useMutation} from "@apollo/client";
import {NotificationManager} from "react-notifications";


const CREATE_ORGANIZATION = gql`
mutation Add($name: String!)
{
    createOrganization(name: $name) {
      id
      name
    }
}
`;




export function Page({user}) {

    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin ===false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }


    const [showReviewRequest, setShowReviewRequest] = useState(false);
    const toggleShow = () => setShowReviewRequest(!showReviewRequest);


    const [orgName, setOrgName] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [addOrganization, { data, loading, error }] = useMutation(CREATE_ORGANIZATION, {});



    function request()
    {
        setIsLoading(true);
        addOrganization({
            variables: {name: orgName,},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}
        })
            .then( (result) => {
                NotificationManager.success("Created Organization, Redirecting you.")
                let id = result.data.createOrganization.id;
                setTimeout( function() {navigate("/admin/organization/"+id);}, 1000);
                setIsLoading(false);
            })
            .catch((error) => {
                let details =JSON.parse(JSON.stringify(error));
                if(details.graphQLErrors) details.graphQLErrors.map( (row) => {NotificationManager.error(row.message)})
                setIsLoading(false);
            })

    }


    if(isLoading) return( <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>)

    return (

        <div >
            <MDBBtn onClick={toggleShow} size={"sm"}> Create Organization</MDBBtn>



            <MDBModal tabIndex='-1' open={showReviewRequest} onClose={()=>setShowReviewRequest(false)}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Organization</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}/>
                        </MDBModalHeader>
                        <MDBModalBody >
                            <MDBRow style={{padding:'5px'}}>
                                <MDBCol>
                                    <MDBInput id='name' label='Organization Name' type='text'  value={orgName} onChange={(e) => {

                                        let newname = e.target.value;
                                        setOrgName(newname);
                                    }} />
                                </MDBCol>
                            </MDBRow>


                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={()=>{toggleShow()}}>Cancel</MDBBtn>
                            <MDBBtn disabled={loading} onClick={request}>Create</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>





    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
