import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBCardFooter, MDBBtn, MDBSwitch,MDBSelect
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";



const GET_DATA = gql`
  query Get($id: Long!, $entityId: Long!) {
    classDepartment(id: $id, entityId: $entityId)
    {
       id
       name
       id_340b
       is_340b
       
    }
  }
`;



const SET_340_ID = gql`
mutation set( $entityId: Long!, $id: Long!, $id340b: String!)
{
    setClassDepartment340bId(entityId: $entityId, id: $id, id340b: $id340b) {
        successful
    }
}
`;

const SET_340_STATUS = gql`
mutation set( $entityId: Long!, $id: Long!, $is340b: Boolean!)
{
    setClassDepartmentIs340b(entityId: $entityId, id: $id, is340b: $is340b) {
        successful
    }
}
`;



export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }
    const params= useParams()
    const [id340b, setId340b] = useState("");
    const [deptData, setDeptData] = useState({});
    const [getDeptData,{error: getDrugDataError, loading: getDrugDataLoading}] = useLazyQuery(GET_DATA);

    const [drugData, setDrugData] = useState({});
    const [drugClassing, setDrugClassing] = useState([]);

    const [SetDept340bId ,{loading: id340bLoading}] = useMutation(SET_340_ID);
    const [SetDept340bStatus, {loading: is340bLoading}] = useMutation(SET_340_STATUS);





    function save340bId()
    {
        SetDept340bId({
            variables: {
                id340b: id340b,
                id: Number(params.department_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated");
                loadData();
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }


    function toggle340b()
    {
        SetDept340bStatus({
            variables: {
                is340b: !deptData.is_340b,
                id: Number(params.department_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");
                loadData();
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })
    }



    useEffect(()=> {
        loadData();
    }, [user]);

    function loadData()
    {
        getDeptData({
            variables: {
                id: Number(params.department_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }


                setDeptData(result.data.classDepartment);
            })
    }




    useEffect(()=> { setId340b(deptData.id_340b) }, [deptData]);


    if(getDrugDataLoading || id340bLoading || is340bLoading)
        return (
            <MDBContainer style={{marginLeft:'2rem', marginRight:'2rem'}}>
                <MDBBreadcrumb className='p-1 '>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin"}>Clearway</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity"}>Entity</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <>Classing</>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id + '/class/department'}>Department</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                        <>{params.department_id}</>
                    </MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>
            </MDBContainer>
        );
    else return (

        <MDBContainer style={{marginLeft:'2rem', marginRight:'2rem'}}>
            <MDBBreadcrumb className='p-1 '>
                <MDBBreadcrumbItem>
                    <Link to={"/admin"}>Clearway</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entity</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <>Classing</>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id + '/class/department'}>Department</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                    <>{params.department_id}</>
                </MDBBreadcrumbItem>


            </MDBBreadcrumb>
            <MDBRow>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>


                    <MDBCard>
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Department Information </MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <MDBTable>
                                <MDBTableBody>
                                    <tr>
                                        <th>ID</th>
                                        <td>{params.department_id}</td>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <td>{deptData?.name}</td>
                                    </tr>

                                </MDBTableBody>
                            </MDBTable>

                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>
                    <MDBCard>
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Department Classification</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <MDBSwitch checked={deptData.is_340b} id='flexSwitchCheckChecked' onClick={toggle340b} label='340B Eligible' />

                            <br />

                            <MDBInput  label='340B ID'  style={{width:'auto'}} value={id340b} onChange={(e)=>{setId340b(e.target.value); }} />

                            {deptData.id_340b!==id340b && <MDBBtn className="form-control mb-3 mt-1" onClick={() => save340bId()} >Save 340B</MDBBtn>}



                        </MDBCardBody>
                    </MDBCard>





                </MDBCol>
            </MDBRow>


        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
