import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBSpinner,
    MDBModalFooter, MDBCardBody, MDBCard
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import axios from "axios";
import {saveAs} from "file-saver";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";


const CREATE_REVIEW_REQUEST = gql`
  mutation request($entityId: Long!, $packageId: Long!,  $note: String!) {
    requestReview(entityId: $entityId, packageId: $packageId, note: $note) {
      successful
      message
    }
  }
`;




export function Page({user}) {
    const params= useParams()
    const [note, setNote] = useState("");

    const [showReviewRequest, setShowReviewRequest] = useState(false);
    const toggleShow = () => setShowReviewRequest(!showReviewRequest);


    const [requestReview, { rrData, rrLoading, rrError }] = useMutation(CREATE_REVIEW_REQUEST);



    function request()
    {
        requestReview({
            variables:{
                note: note,
                entityId: parseInt(params.entity_id),
                packageId: Number(params.package_id)
            },
            context:{
                headers:{
                    "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''
                }
            }
        }).then( (result) => {

            if(result.data.requestReview.successful)
            {
                NotificationManager.success("Review Requested");
                setShowReviewRequest(false);
                setNote("")
                window.location.reload(false);

            }
            else
                NotificationManager.warning("Something went wrong, please try again.")

        }).catch((error) => {
            NotificationManager.error("Api Error");
        })
    }



    if(false) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    else return (

        <div >

            <MDBCard>
                <MDBCardBody>
                    <h5>Please enter any additional comments or questions along with your review request. </h5>
                    <textarea className="form-control rounded-0"
                              id="exampleFormControlTextarea1" rows="5"
                              value={note}
                              onChange={(e) => {setNote(e.target.value)}}
                    />
                </MDBCardBody>
                <MDBBtn disabled={rrLoading} onClick={request}>Submit</MDBBtn>
            </MDBCard>


        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
