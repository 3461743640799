

export const LoadState = () => {
    try {
        const state = localStorage.getItem('state');
        if(state === null)
            return undefined;
        return JSON.parse(state);
    }
    catch (e) {
        return undefined;
    }
};



export const SaveState = (state) => {
    try{
        const sstate = JSON.stringify(state);
        localStorage.setItem('state', sstate);

    }
    catch (e) {
        //ignore
    }
};