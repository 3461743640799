import React, {useState} from 'react';
import {
    MDBBadge,
    MDBBtn,
    MDBCard, MDBCardBody, MDBCardFooter,
    MDBCol,
    MDBContainer, MDBIcon,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTypography
} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";
import {default as EntityListing} from "../../Components/EntityListing";
import {default as AddEntity} from "../../Components/AddEntityButton";



export default function Page() {
    let navigate = useNavigate();



    return (

        <MDBContainer >
            <MDBContainer >
                <MDBRow className=' text-center pt-4' >
                    <MDBCol>

                        <MDBTypography tag='h2'>Entities</MDBTypography>
                        <span className=""><AddEntity/></span>

                    </MDBCol>

                </MDBRow>

                <MDBRow className='justify-content-center pt-4' >
                    <MDBCol sm={10}>

                        <EntityListing />
                    </MDBCol>

                </MDBRow>
            </MDBContainer>

        </MDBContainer>
    );
}