import React, {useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBContainer,
    MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalFooter, MDBModalHeader, MDBModalTitle,
    MDBRow, MDBSpinner,
    MDBTypography, MDBModalDialog
} from "mdb-react-ui-kit";
import {gql, useMutation} from "@apollo/client";
import {NotificationManager} from "react-notifications";


const CREATE_ORGANIZATION = gql`
mutation run($entityId: Long!, $packageId: Long!)
{
    runDataQualityChecks(entityId: $entityId, packageId: $packageId) {
      successful
    }
}
`;




export function Page({user}) {
    const params= useParams()
    let navigate = useNavigate();


    const [isLoading, setIsLoading] = useState(false)
    const [addOrganization, { data, loading, error }] = useMutation(CREATE_ORGANIZATION, {});



    function request()
    {
        setIsLoading(true);
        addOrganization({
            variables: {
                entityId: Number(params.entity_id),
                packageId: Number(params.package_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}
        })
            .then( (result) => {

                if(result.data?.runDataQualityChecks?.successful) NotificationManager.success("Requested the run of the Data Quality Checks")
                else  NotificationManager.error("Unable to request data quality checks")

                setIsLoading(false);
            })
            .catch((error) => {
                NotificationManager.error("Unable to request data quality checks")

                setIsLoading(false);
            })

    }


    if(isLoading) return( <div className="text-center" ><MDBSpinner animation="grow"   variant="primary" /></div>)

    return (

        <center>
            <MDBBtn onClick={request} size={"sm"}>ReRun Checks</MDBBtn>

        </center>




    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
