import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';

import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import {gql, useLazyQuery} from "@apollo/client";
import Moment from "react-moment";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {Typography} from "@mui/material";


const GET_PHARMACIES = gql`
  query Get( $skip: Int!, $take: Int!, $search: String!) {
    pharmacies(search: $search, skip: $skip, take: $take) {
      totalCount
      items
      {
        npi
        name
        other_name
        address_1
        address_2
        city
        state
        zip_code
        type_id
        taxonomy_id
      }
      
    }
  }
`;



const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()
    const fetchSize = 25;
    const [totalRows, setTotalRows] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getPharmacies,{error, loading: drugLoading, data: drugData}] = useLazyQuery(GET_PHARMACIES);

    const columns = [
        {
            accessorKey: 'npi',
            header: 'NPI',
            // Cell: ({ cell }) => (
            //     <Link to={'/admin/pharmacy/'+cell.getValue()}>{cell?.getValue()}</Link>
            // ),
        },
        {
            accessorKey: 'name',
            header: 'Pharmacy Name',
        },

        {
            accessorKey: 'taxonomy_id',
            header: 'Taxonomy ID',
        },


    ];




    const { data, fetchNextPage, isError, isFetching, isLoading } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {


                let dd = await getPharmacies({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                       search: globalFilter ?? ""
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then()

                setTotalRows(dd.data.pharmacies.totalCount ?? 0)


                return dd.data.pharmacies.items;

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,

        });


    const flatData = useMemo(
        () => data?.pages?.flatMap((page) => page) ?? [],
        [data],
    );

    const totalDBRowCount = totalRows ?? 0;

    const totalFetched = flatData.length;



    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        async (containerRefElement) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ){
                    console.log('fetchMoreOnBottomReached: Fetching', true)

                    await fetchNextPage().then();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current).then(r => console.log('loaded'));
    }, [fetchMoreOnBottomReached]);



    return (
        <div >



            <MaterialReactTable
                columns={columns}
                data={flatData}
                enableColumnFilters={false}
                enablePagination={false}
                enableGlobalFilter={true}
                enableFilters={true}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {totalDBRowCount.toLocaleString("en-US")} total rows.
                    </Typography>
                )}
                renderTopToolbarCustomActions={() => <Typography variant="h5" className="m-3">Pharmacies</Typography>}
                enableDensityToggle={false}
                initialState={{ density: 'compact' }}


                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                //rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
                rowVirtualizerProps={{ overscan: 4 }}
            />
        </div>
    );
};






const queryClient = new QueryClient();


export function Page({user}) {


    return (
        <QueryClientProvider client={queryClient}>
            <DrugTable user={user} />
        </QueryClientProvider>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
