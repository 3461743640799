import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {connect, useDispatch} from "react-redux";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCol,
    MDBContainer, MDBIcon,
    MDBRow,
    MDBSpinner, MDBTypography
} from "mdb-react-ui-kit";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import {Table} from "@mui/material";



export function Page({cwuser}) {

    let navigate = useNavigate();
    const [metrics, setMetrics] = useState({})



    if(!cwuser.isAdmin)
    {
        NotificationManager.error("Not Authorized")
        navigate('/')
    }



    const GET_METRICS = gql`
          query Get {
            queueStatuses {
              file_rows
              department_processing
              drug_processing
              insurance_processing
              pharmacy_processing
            }
            
          }
        `;
    const [getMetrics, { loading, error, data }] = useLazyQuery(GET_METRICS);

    useEffect(()=> {
        getMetrics({ fetchPolicy:"cache-first", context:{headers:{"Authorization":cwuser.isAuthenticated ? 'Bearer ' +cwuser.token : ''}}})
            .then( (result) => {
                setMetrics(result.data);
            })

    }, [cwuser]);





    if(false) return (  <MDBContainer style={{padding:'20px'}} ><div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div></MDBContainer>);
    return (

        <MDBContainer >
            <MDBRow className=' text-center p-4' >
                <MDBCol>

                    <MDBTypography tag='h1'>Clearway Health System Dashboard</MDBTypography>

                </MDBCol>

            </MDBRow>
            <Table className="table table-bordered">
                <thead>
                <tr>
                    <td>Queue</td>
                    <td>Message Count</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>File Rows</td>
                    <td>{metrics?.queueStatuses?.file_rows.toLocaleString("en-US")}</td>
                </tr>
                <tr>
                    <td>Department</td>
                    <td>{metrics?.queueStatuses?.department_processing.toLocaleString("en-US")}</td>
                </tr>
                <tr>
                    <td>Drug</td>
                    <td>{metrics?.queueStatuses?.drug_processing.toLocaleString("en-US")}</td>
                </tr>
                <tr>
                    <td>Insurance</td>
                    <td>{metrics?.queueStatuses?.insurance_processing.toLocaleString("en-US")}</td>
                </tr>
                <tr>
                    <td>Pharmacy</td>
                    <td>{metrics?.queueStatuses?.pharmacy_processing.toLocaleString("en-US")}</td>
                </tr>
                </tbody>
            </Table>

        </MDBContainer>
    );
}


const mapStateToProps = state => {
    return {
        cwuser: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
