import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBBtn, MDBDatatable, MDBTable, MDBTableBody, MDBTableHead, MDBTypography} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import eventBus from "../../../Context/EventBus";
import MaterialReactTable from "material-react-table";
import {Typography} from "@mui/material";



export function Page({user}) {
    let navigate = useNavigate();
    const [datatable, setDatatable] = useState({
        columns:[
            {
                label:"Entity ID", field:"id",
                accessorKey: 'id',
                header: 'Entity ID',
            },
            {label:"Name", field: "name"} ,
            {label: "Created", field: "createdTsMoment", sort:false},
            {label:"Action", field: "action", sort: false}], rows:[]
    })
    const GET_ENTITIES = gql`
          query Get {
            entities {
              id
              name
              created
              updated
            }
          }
        `;
    const [getEntities, { loading, error, data }] = useLazyQuery(GET_ENTITIES);

    eventBus.on("entity.create", (data) =>
        loadData()
    );


    useEffect(()=> {

        loadData();
    }, [user]);



    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            size:10
        },
        {
            accessorKey: 'name',
            header: 'Name',

        },
        {
            accessorKey: 'createdTsMoment',
            header: 'Created',
            size: 20
        },
        {
            accessorKey: 'action',
            header: 'Action',
            size: 20
        },
    ];




    function loadData() {
        getEntities({ context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }
                setDatatable([].concat(result?.data?.entities)
                    .map((row) => {

                        return {
                            ...row,
                            createdTsMoment: (<Moment utc format={"MM/DD/YYYY"} local>{row.created}</Moment>),
                            action: (<Link to={"/admin/entity/" + row.id}><MDBBtn size={"sm"}>View</MDBBtn></Link>)
                        }
                    }));
            })
    }



    return (

        <div className="pb-5">

            <MaterialReactTable
                columns={columns}
                data={datatable}

                enableColumnFilters={false}
                enablePagination={false}
                // enableGlobalFilter={false}
                // enableFilters={false}
                //
                //
                enableHiding={false}

                renderTopToolbarCustomActions={() => <Typography variant="h5" className="m-3">Entities</Typography>}
                renderBottomToolbar={false}

                enableDensityToggle={false}
                initialState={{ density: 'compact', showGlobalFilter:true,  }}



            />

        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
