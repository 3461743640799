import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBBtn, MDBDatatable, MDBTable, MDBTableBody, MDBTableHead, MDBTypography} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";
import {gql, useLazyQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import Moment from "react-moment";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";



export function Page({user}) {
    let navigate = useNavigate();
    const [datatable, setDatatable] = useState({ columns:[{label:"Email", field:"email"},{label:"First Name", field: "first_name"} , {label: "Last Name", field: "last_name"}, {label:"Type", field: "type"}], rows:[]})
    const GET_ACCOUNTS = gql`
          query Get {
            accounts {
              email
              first_name
              last_name
              is_admin
              is_clearway
              is_client
            }
          }
        `;
    const [getAccounts, { loading, error, data }] = useLazyQuery(GET_ACCOUNTS);


    useEffect(()=> {
        getAccounts({ context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }
                setDatatable({
                    columns: datatable.columns,
                    rows: [].concat(result?.data?.accounts)
                        .map((row) => {

                            return {
                                ...row,
                                type: row.is_admin ? "Admin":   row.is_clearway ? "Clearway":  row.is_client ? "Client":"Client"
                                // action: (<Link to={"/admin/entity/" + row.id}><MDBBtn size={"sm"}>View</MDBBtn></Link>)
                            }
                        })
                });
            })

    }, [user]);


    return (

        <div >
            <MDBDatatable

                loading={loading}

                data={datatable}

                search={true}
                pagination={true}
                advancedData={true}
            />

        </div>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
