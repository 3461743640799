import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBContainer,
    MDBSpinner,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBCardFooter, MDBBtn, MDBSwitch, MDBSelect, MDBInput
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";


const GET_LOOKUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;

const GET_DATA = gql`
  query Get($id: Long!, $entityId: Long!) {
    classInsurance(id: $id, entityId: $entityId)
    {
      id
      name
      type_id
      type{
        value
      }
    
    }
  }
`;



const SET_TYPE_CLASSIFICATION = gql`
mutation set($entityId: Long!, $id: Long!, $typeId: Long!)
{
    setEntityInsuranceClassType(entityId: $entityId, id: $id, typeId: $typeId) {
        successful
    }
}
`;




export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()
    const [Data, setData] = useState({});

    const [pharmacyClassingOptions, setPharmacyClassingOptions] = useState([]);
    const [getData,{error: getDrugDataError, loading: getDrugDataLoading}] = useLazyQuery(GET_DATA);
    const [getLookup,{error: getLookupError, loading: getLookupLoading}] = useLazyQuery(GET_LOOKUP);
    const [setTypeClassification] = useMutation(SET_TYPE_CLASSIFICATION);

    const [dataClassificationSelect, setDataClassificationSelect] = useState([]);





    function updateTypeClassification(event)
    {
        setTypeClassification({
            variables: {
                id: Number(params.insurance_id),
                entityId: Number(params.entity_id),
                typeId: event.value,
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })

    }




    function loadData()
    {
        getLookup({
            variables: {
                id: 'insurance_classing',
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {

                let data = result.data.groupLookupValues.map( (row) => {
                    return {text:row.value, value:row.id}
                })

                setPharmacyClassingOptions(data)
            })

        getData({
            variables: {
                id: Number(params.insurance_id),
                entityId: Number(params.entity_id)
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }


                setData(result.data.classInsurance);
            })
    }


    useEffect(()=> {
       loadData();
    }, [user]);



    useEffect(() => {

        var selectOptions = pharmacyClassingOptions.map( (row) => {
            if(row.value === Data?.type_id )
                row.defaultSelected = true
            return row;
        })

        setDataClassificationSelect(selectOptions);
    }, [pharmacyClassingOptions, Data]);



    if(getDrugDataLoading || getLookupLoading)
        return (
            <MDBContainer >
                <MDBBreadcrumb className='p-1 '>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin"}>Clearway</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity"}>Entity</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <>Classing</>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/entity/" + params.entity_id + '/class/insurance'}>Insurance</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                        <>{params.insurance_id}</>
                    </MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>
            </MDBContainer>
        );
    else return (

        <MDBContainer>
            <MDBBreadcrumb className='p-1 '>
                <MDBBreadcrumbItem>
                    <Link to={"/admin"}>Clearway</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entity</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <>Classing</>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id + '/class/insurance'}>Insurance</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                    <>{params.insurance_id}</>
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBRow>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>


                    <MDBCard>
                        <MDBCardHeader>
                            {/*<span className="float-end"><> <MDBSwitch wrapperClass='mb-4'  value={drugEdit} label="Edit" onChange={()=>setDrugEdit(!drugEdit)}  /></></span>*/}
                            <MDBTypography tag='h2'>Insurance Information</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>

                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>
                    <MDBCard>
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Insurance Classification</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBInput  label='Insurance ID'   value={Data?.id} disabled />

                            <br/>
                            <MDBInput  label='Name'   value={Data?.name} disabled />

                            <br/>
                            <MDBSelect
                                label='Type Classification'
                                data={dataClassificationSelect}
                                onValueChange={(e) =>updateTypeClassification(e)}
                            />
                            <br/>











                        </MDBCardBody>
                    </MDBCard>


                </MDBCol>
            </MDBRow>


        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
