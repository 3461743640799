import React, { useState, useCallback, useEffect } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBBadge,
    MDBCollapse,
    MDBSpinner,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBCardFooter, MDBBtn, MDBSwitch,MDBSelect
} from "mdb-react-ui-kit";
import Moment from "react-moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";

import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";


const GET_LOOKUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;

const GET_DATA = gql`
  query Get($ndc: String!) {
    drug(ndc: $ndc)
    {
        ndc
        gpi
        name
        strength
        strength_unit_of_measure
        dosage_form
        clearwayDrug {
            ndc
            classification_id
            is_orphaned
            classification {
             value
            }
        }
    }
    drugPrice(ndc: $ndc){
        ndc
        price
    }
    
    
  }
`;



const SET_DRUG_CLASSIFICATION = gql`
mutation set($ndc: String!, $id: Long!)
{
    setDrugClassification(ndc: $ndc, id: $id) {
        successful
    }
}
`;


const SET_DRUG_ORPHAN = gql`
mutation set($ndc: String!, $status: Boolean!)
{
    setDrugOrphanStatus(ndc: $ndc, status: $status) {
        successful
    }
}
`;



const SET_DRUG_PRICE = gql`
mutation set($ndc: String!, $amount: Decimal!)
{
    setDrugPrice(ndc: $ndc, price: $amount) {
        successful
    }
}
`;



export function Page({user}) {
    let navigate = useNavigate();
    if(user.isAuthenticated === false || user.isAdmin === false)
    {
        NotificationManager.error("Unauthorized or session has expired!");
        setTimeout( function() {navigate('/logout'); }, 200);
    }

    const params= useParams()
    const [drugData, setDrugData] = useState({});
    const [drugPriceData, setDrugPriceData] = useState({});

    const [drugClassing, setDrugClassing] = useState([]);
    const [drugEdit, setDrugEdit] = useState(false);
    const [getDrugData,{error: getDrugDataError, loading: getDrugDataLoading}] = useLazyQuery(GET_DATA);

    const [drugPrice, setDrugPrice] = useState(0);


    const [getLookup,{error: getLookupError, loading: getLookupLoading}] = useLazyQuery(GET_LOOKUP);
    const [setDrugClassification] = useMutation(SET_DRUG_CLASSIFICATION);
    const [setDrugOrphanStatus] = useMutation(SET_DRUG_ORPHAN);
    const [apiSetDrugPrice] = useMutation(SET_DRUG_PRICE);

    const [dataClassificationSelect, setDataClassificationSelect] = useState([]);



    function toggleDrugOrphanStatus()
    {

        let data = drugData;

        data.clearwayDrug.is_orphaned = !data.clearwayDrug.is_orphaned ?? true

        setDrugOrphanStatus({
            variables: {
                status:  data.clearwayDrug.is_orphaned ,
                ndc: params.ndc
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Set Drug Orphan Status!");
                setDrugData(data);
            })
            .catch((error) => {
                NotificationManager.error("Unable to update Drug");
            })


    }


    function updateDrugClassification(event)
    {
        setDrugClassification({
            variables: {
                id: event.value,
                ndc: params.ndc
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Set Drug Classification!");
            })
            .catch((error) => {
                NotificationManager.error("Unable to update Drug");
            })


    }


    function updateDrugPrice(e)
    {
        e.preventDefault();
        apiSetDrugPrice({
            variables: {
                amount: Number(drugPrice),
                ndc: params.ndc
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Updated Drug Price");
                loadData();
            })
            .catch((error) => {
                NotificationManager.error("Unable to update Drug Price");
            })



    }


    useEffect(()=> {
        getLookup({
            variables: {
                id: 'drug_classing',
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {

                let data = result.data.groupLookupValues.map( (row) => {
                    return {text:row.value, value:row.id}
                })

                setDrugClassing(data)
            })
        loadData();

    }, [user]);


    function loadData()
    {
        getDrugData({
            variables: {
                ndc: params.ndc,
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                if(result.error?.networkError)
                {
                    let details = JSON.parse( JSON.stringify(result.error.networkError.result.errors));
                    details.map( (row) => {
                        if (row?.message?.includes('not authorized')) {
                            NotificationManager.error("Unauthorized or session has expired!");
                            setTimeout( function() {navigate('/logout'); }, 200);
                        }
                    });
                }


                setDrugData(result.data.drug);
                setDrugPriceData(result.data.drugPrice);
            })
    }

    useEffect(() => {

        var selectOptions = drugClassing.map( (row) => {
            if(row.value === drugData?.clearwayDrug?.classification_id )
                row.defaultSelected = true
            return row;
        })

        setDataClassificationSelect(selectOptions);
    }, [drugClassing, drugData]);


    //drugPrice
    useEffect(() => {
        setDrugPrice(drugPriceData?.price);
    }, [ drugPriceData]);



    if(getDrugDataLoading || getLookupLoading)
        return (
            <MDBContainer >
                <MDBBreadcrumb className='p-1 '>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin"}>Clearway</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <Link to={"/admin/drug"}>Drugs</Link>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                        <>{params.ndc}</>
                    </MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>
            </MDBContainer>
        );
    else return (

        <MDBContainer style={{marginLeft:'2rem', marginRight:'2rem'}}>
            <MDBBreadcrumb className='p-1 '>
                <MDBBreadcrumbItem>
                    <Link to={"/admin"}>Clearway</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                    <Link to={"/admin/drug"}>Drugs</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                    <>{params.ndc}</>
                </MDBBreadcrumbItem>


            </MDBBreadcrumb>
            <MDBRow>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>


                    <MDBCard>
                        <MDBCardHeader>
                            {/*<span className="float-end"><> <MDBSwitch wrapperClass='mb-4'  value={drugEdit} label="Edit" onChange={()=>setDrugEdit(!drugEdit)}  /></></span>*/}
                            <MDBTypography tag='h2'>Drug Information </MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>
                            {drugEdit && <div>
                                <form>
                                    <MDBInput wrapperClass='mb-4' id='form6Example1' label='NDC' value={params.ndc} />

                                    <MDBInput wrapperClass='mb-4'  label='GPI' value={drugData.gpi} />



                                    <MDBInput wrapperClass='mb-4' id='form6Example3' label='Company name' />
                                    <MDBInput wrapperClass='mb-4' id='form6Example4' label='Address' />
                                    <MDBInput wrapperClass='mb-4' type='email' id='form6Example5' label='Email' />
                                    <MDBInput wrapperClass='mb-4' type='tel' id='form6Example6' label='Phone' />

                                    <MDBInput wrapperClass='mb-4' textarea id='form6Example7' rows={4} label='Additional information' />

                                    <MDBBtn className='mb-4' type='submit' block>
                                        Save
                                    </MDBBtn>
                                </form>

                            </div>}
                            {!drugEdit && <div>
                                <MDBTable>
                                    <MDBTableBody>
                                        <tr>
                                            <th>NDC</th>
                                            <td>{params.ndc}</td>
                                        </tr>
                                        <tr>
                                            <th>Drug Name</th>
                                            <td>{drugData.name}</td>
                                        </tr>
                                        <tr>
                                            <th>GPI</th>
                                            <td>{drugData.gpi}</td>
                                        </tr>
                                        <tr>
                                            <th>Form</th>
                                            <td>{drugData.dosage_form}</td>
                                        </tr>
                                        <tr>
                                            <th>Strength</th>
                                            <td>{drugData.strength}</td>
                                        </tr>
                                        <tr>
                                            <th>Strength Units</th>
                                            <td>{drugData.strength_unit_of_measure}</td>
                                        </tr>
                                    </MDBTableBody>
                                </MDBTable>
                            </div>}


                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
                <MDBCol xs={12} sm={12} md={12} lg={6} style={{marginTop:'20px'}}>
                    <MDBCard>
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Clearway Drug Information</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <form>
                                <div>
                                <MDBTable>
                                    <MDBTableBody>
                                        <tr>
                                            <th>Orphan Drug</th>
                                            <td>{drugData?.clearwayDrug?.is_orphaned? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <th>Drug Classification</th>
                                            <td>{drugData?.clearwayDrug?.classification?.value}</td>
                                        </tr>
                                    </MDBTableBody>
                                </MDBTable>
                                </div>
                            </form>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="mt-5">
                        <MDBCardHeader>
                            <MDBTypography tag='h2'>Drug Price</MDBTypography>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <form>
                                <span>${drugPrice??0}</span>
                                {drugPriceData?.price !== drugPrice && <MDBBtn className="form-control mb-3 mt-1" onClick={updateDrugPrice} >Set Price</MDBBtn>}


                            </form>





                        </MDBCardBody>
                    </MDBCard>


                </MDBCol>
            </MDBRow>


        </MDBContainer>
    );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
