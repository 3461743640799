import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {
    MDBBadge, MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle,
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {
    Button,
    MenuItem,
    Typography
} from "@mui/material";
import eventBus from "../../../../Context/EventBus";



const GET_INSURANCE = gql`
  query Get( $skip: Int!, $take: Int!, $entityId: Long!) {
    classInsurances(entityId: $entityId skip: $skip, take: $take) {
      totalCount
      items
      {
        id
        name
        client_type
        type_id
        type{
            value
        }
        status {
            value
        }
        
      }
      
    }
  }
`;

const GET_LOOKUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;

const SET_TYPE_CLASSIFICATION = gql`
mutation set($entityId: Long!, $id: Long!, $typeId: Long!)
{
    setEntityInsuranceClassType(entityId: $entityId, id: $id, typeId: $typeId) {
        successful
    }
}
`;




const CLASSING_CLEANUP = gql`
mutation clean( $entityId: Long!)
{
    cleanInsuranceClass(entityId: $entityId)
  {
      successful
    
  }
} 
`;



const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()
    const fetchSize = 25;
    const [pagedata, setPagedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);


    const [TableData, setTableData] = useState([]);


    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getData,{error, loading: drugLoading, data: drugData}] = useLazyQuery(GET_INSURANCE);
    const [getLookup,{error: getLookupError, loading: getLookupLoading}] = useLazyQuery(GET_LOOKUP);
    const [setTypeClassification] = useMutation(SET_TYPE_CLASSIFICATION);

    const [classingOptions, setClassingOptions] = useState([]);
    const [modalCleanup, setModalCleanup] = useState(false);



    const [cleanupClassing] = useMutation(CLASSING_CLEANUP,{
        variables: {
            entityId: Number(params.entity_id)
        },
        context:{
            headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
        }
    })


    useEffect(()=> {
        getLookup({
            variables: {
                id: 'insurance_classing',
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {

                let data = result.data.groupLookupValues.map( (row) => {
                    return {text:row.value, value:row.id}
                })

                setClassingOptions(data)
            })
            .catch((error) => {
                console.log("Error loading classing options")
            })
    }, [user]);




    function updateInsuranceType(row, type, typeId)
    {
        console.log('update type', typeId)

        setTypeClassification({
            variables: {
                id: Number(row.original.id),
                entityId: Number(params.entity_id),
                typeId: Number(typeId),
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Classing Updated!");

                let values = row.original;
                values.type.value =type;

                eventBus.dispatch("insurance.class.update_row", values);
            })
            .catch( (error) => {
                NotificationManager.error("Api Error");
            })




    }






    const columns = [
        // {
        //     accessorKey: 'id',
        //     header: 'ID',
        //     size: 100,
        //     Cell: ({ cell }) => (
        //         <Link to={'/admin/entity/'+params.entity_id+'/class/insurance/'+cell.getValue()}>{cell?.getValue()}</Link>
        //     ),
        //     enableEditing: false,
        // },
        {
            accessorKey: 'name',
            header: 'Insurance Name',
            enableEditing: false,
        },

        {
            accessorKey: 'client_type',
            header: 'Client Type',
            enableEditing: false,
        },



        {
            accessorKey: 'type.value',
            header: 'Insurance Type',

            muiTableBodyCellEditTextFieldProps: {
                select: true, //change to select for a dropdown
                children: classingOptions.map((row, id) => (
                    <MenuItem key={id} value={row.text}>
                        {row.text}
                    </MenuItem>
                )),
            },
            size: 200,
            Cell: ({cell}) => (<span><MDBIcon far icon="edit" style={{cursor:"pointer"}}/> {cell.getValue() } </span>),

        },
        {
            accessorKey: 'status.value',
            header: 'Classing Status',
            enableEditing: false,
        },


    ];




    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {


                let dd = await getData({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        entityId: Number(params.entity_id)
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then()


                setTotalRows(dd.data.classInsurances.totalCount ?? 0)

                setTableData(TableData =>Array.from(new Set([...TableData, ...dd.data.classInsurances.items])));

                return [];






            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: false,
            refetchOnWindowFocus: false,

        });




    const totalDBRowCount = totalRows ?? 0;

    const totalFetched = TableData.length;



    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        async (containerRefElement) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;


                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ){
                    console.log('fetchMoreOnBottomReached: Fetching', true)

                    await fetchNextPage().then();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current).then();
    }, [fetchMoreOnBottomReached]);




    eventBus.on("insurance.class.update_row", (row) =>{



        let index = TableData.findIndex((line) => line.id === row.id);
        var tempTableData = [...TableData];
        tempTableData[index] = row;
        setTableData(tempTableData);
    });

    function confirmClassCleanup()
    {
        setModalCleanup(false);
        cleanupClassing().then(r => {
            NotificationManager.success("Classing Cleanup Submitted!");
        }).catch((error) =>{
            NotificationManager.error("Api Error");
        })

    }





    return (
        <div >
            <MaterialReactTable
                columns={columns}

                editingMode="cell"
                enableEditing
                muiTableBodyCellEditTextFieldProps={({ cell, row }) => ({
                    //onBlur is more efficient, but could use onChange instead
                    onBlur: (event) => {
                        // console.log(cell, event.target.value);
                        // console.log(row); //original

                        var newValue = event.target.value;


                        if(cell.column.id === "type.value")
                        {
                            let type = classingOptions.find((element) => {return element.text === newValue})
                            updateInsuranceType(row,event.target.value, type.value);

                            console.log('type.value selected', type);



                        }


                    },
                })}

                data={TableData}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableGlobalFilter={true}
                enableFilters={false}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={isError ? { color: 'error',  children: 'Error loading data',} : undefined}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {TableData.length.toLocaleString("en-US")} of {totalRows.toLocaleString("en-US")} total rows.
                    </Typography>
                )}


                renderTopToolbarCustomActions={() => <Typography variant="h5" className="m-3">Insurance Classing <span><Button color={"secondary"} size={"small"} onClick={()=> setModalCleanup(true)}>Cleanup Classing</Button></span></Typography>}


                enableDensityToggle={false}
                initialState={{ density: 'compact' }}



                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                rowVirtualizerProps={{ overscan: 4 }}
            />



            <MDBModal open={modalCleanup} tabIndex='-1' onClose={()=>setModalCleanup(false)}>
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Cleanup Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={()=>setModalCleanup(false)}/>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <Typography fontSize={20}>
                                Are you sure you want to clean up the classing records?
                            </Typography>
                            <p>
                                This will go through all the classing records and delete any that are no longer connected to raw data files.
                            </p>

                            <Button onClick={()=>confirmClassCleanup()}>Confirm Classing Cleanup</Button>
                        </MDBModalBody>

                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


        </div>
    );
};









const queryClient = new QueryClient();




export function Page({user}) {


    return (

        <QueryClientProvider client={queryClient}>
            <DrugTable user={user} />
        </QueryClientProvider>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
