import React, {useEffect, useState} from 'react';
import {
    Link, useParams
} from "react-router-dom";
import {
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBContainer,
    MDBIcon,
    MDBRow, MDBCol,
    MDBNavbarBrand,

    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse, MDBInput, MDBCheckbox, MDBCardHeader, MDBCardBody, MDBCard, MDBSpinner, MDBCardFooter
} from 'mdb-react-ui-kit';

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';

import {useSelector, useDispatch, connect} from 'react-redux'

import {resetUser, setUser} from '../../Context/reducers/User/actions'
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {NotificationManager} from "react-notifications";
import loginimg from "../../Images/login.png";

const REQUEST_TOKEN = gql`
query Request($email: String!, $otp: String!)
{
    authenticateOtp(email: $email, otp: $otp) {
      token
      successful
      message
    }
}
`;


export function Page({cwuser}) {
    const params= useParams()
    let email = params.email;
    const [otp, setOTP] = useState("");


    const dispatch = useDispatch()
    let navigate = useNavigate();


    const [requestToken, { loading, error, data }] = useLazyQuery(REQUEST_TOKEN);


    useEffect(()=>{
        if(cwuser.isAuthenticated)
        {
            navigate('/client');

        }

    }, []) // <-- empty dependency array


    function login()
    {

        requestToken({variables: {email: email, otp: otp}}).then( (result) => {

            if(result.error)
            {
                NotificationManager.error("Authentication Failed", 'Oh No!');
                navigate('/login');
            }
            else
            {

                let data = result.data.authenticateOtp;
                if(data.successful)
                {
                    NotificationManager.success("Authenticated","Success")
                    let token = result.data.authenticateOtp.token;

                    dispatch(setUser(token))
                    navigate('/client');
                }
                else
                {
                    //something went wrong
                    NotificationManager.error(data.message);
                }


            }



        })
        .catch( (error) => {
            NotificationManager.error("API Error", 'Oh No!');
        })




    }





    return (
        <MDBContainer fluid className="p-3 my-5 h-custom">

            <MDBRow>

                <MDBCol sm={6} offsetSm={3} md={4} offsetMd={2} >
                    <img src={loginimg} className="img-fluid" alt="login image"  style={{padding:'15px'}} />
                </MDBCol>

                <MDBCol md={4} center={true}>

                    <MDBCard>
                        <MDBCardHeader>
                            <h3>OTP Confirmation</h3>
                        </MDBCardHeader>
                        <MDBCardBody>

                            <MDBInput wrapperClass='mb-4' label='Email' id='email' type='email' size="lg" value={email} disabled/>

                            <MDBInput wrapperClass='mb-4' label='OTP' id='formControlLg' type='text' size="lg" value={otp} onChange={(e) => setOTP(e.target.value)}/>


                            <div className={"text-center text-md-start mt-4 pt-2"}>
                                <MDBBtn className="mb-0 px-5" size='lg' onClick={login} disabled={loading}>Login</MDBBtn>

                            </div>
                        </MDBCardBody>
                        <MDBCardFooter>
                            <p className={"small fw-bold mt-2 pt-1 mb-2"}>Please check your email for your one time use password.</p>
                        </MDBCardFooter>
                    </MDBCard>




                </MDBCol>

            </MDBRow>


        </MDBContainer>
    );

}

const mapStateToProps = state => {

    return {
        cwuser: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
