import React, {useEffect, useState} from 'react';
import {
    MDBAccordion, MDBAccordionItem,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBBtn, MDBCard, MDBCardBody, MDBCardHeader,
    MDBCol,
    MDBContainer, MDBDatatable, MDBIcon, MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog, MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBSpinner,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane,
    MDBTypography, MDBSelect
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {Button} from "@mui/material";
import {NotificationManager} from "react-notifications";
import eventBus from "../../../Context/EventBus";


const GET_LOOKUP_GROUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;


const GET_FIELD_MAPPINGS = gql`
  query Get($id: Long!) {
    fieldMappings(entityId: $id) {
      field_lookup_id
      custom_field
    }
  
  }
`;

const REMOVE_COLUMN_MAPPING = gql`
mutation Remove($entityId: Long!, $lookupId: Long!, $customField: String! )
{
    removeFieldMapping(entityId: $entityId, lookupId: $lookupId, customField: $customField) {
        successful
    }
}
`;
const Add_COLUMN_MAPPING = gql`
mutation Remove($entityId: Long!, $lookupId: Long!, $customField: String! )
{
    addFieldMapping(entityId: $entityId, lookupId: $lookupId, customField: $customField) {
        successful
    }
}
`;







export  function Page({user}) {
    let navigate = useNavigate();
    const params= useParams()
    const [datatable, setDatatable] = useState({ columns:[{label:"Email", field:"email"},{label:"Name", field: "name"} , {label: "Access", field: "canRead"}, {label:"Notifications", field:"canWrite"},{label:"Type", field:"type"}, {label:"Action", field: "action"}], rows:[]})
    const [fieldMappings, setFieldMapping] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [FieldDeleteStage, setFieldDeleteStage] = useState({});



    const [getFieldMapping, { loading, error, data }] = useLazyQuery(GET_FIELD_MAPPINGS);

    const [apiRemoveColumnMapping] = useMutation(REMOVE_COLUMN_MAPPING, {context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}});
    const [apiAddColumnMapping] = useMutation(Add_COLUMN_MAPPING, {context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}});

    //set up holding for mappings
    const [deptFieldMappings, setDeptFieldMappings] = useState([]);
    const [drugFieldMappings, setDrugFieldMappings] = useState([]);
    const [insuranceFieldMappings, setInsuranceFieldMappings] = useState([]);
    const [patientFieldMappings, setPatientFieldMappings] = useState([]);
    const [pharmacyFieldMappings, setPharmacyFieldMappings] = useState([]);
    const [prescriptionFieldMappings, setPrescriptionFieldMappings] = useState([]);
    const [providerFieldMappings, setProviderFieldMappings] = useState([]);

    //set up query for each area
    const [getDepartmentLookup] = useLazyQuery(GET_LOOKUP_GROUP);
    const [getDrugLookup] = useLazyQuery(GET_LOOKUP_GROUP);
    const [getInsuranceLookup] = useLazyQuery(GET_LOOKUP_GROUP);
    const [getPatientLookup] = useLazyQuery(GET_LOOKUP_GROUP);
    const [getPharmacyLookup] = useLazyQuery(GET_LOOKUP_GROUP);
    const [getPrescriptionLookup] = useLazyQuery(GET_LOOKUP_GROUP);
    const [getProviderLookup] = useLazyQuery(GET_LOOKUP_GROUP);

    //hold column name
    const [newDeptCustomName, setNewDeptCustomName] = useState("");
    const [newDrugCustomName, setNewDrugCustomName] = useState("");
    const [newInsuranceCustomName, setNewInsuranceCustomName] = useState("");
    const [newPatientCustomName, setNewPatientCustomName] = useState("");
    const [newPharmacyCustomName, setNewPharmacyCustomName] = useState("");
    const [newPrescriptionCustomName, setNewPrescriptionCustomName] = useState("");
    const [newProviderCustomName, setNewProviderCustomName] = useState("");

    //hold field id for internal schema
    const [departmentFieldId, setDepartmentFieldId] = useState(0);
    const [drugFieldId, setDrugFieldId] = useState(0);
    const [insuranceFieldId, setInsuranceFieldId] = useState(0);
    const [patientFieldId, setPatientFieldId] = useState(0);
    const [pharmacyFieldId, setPharmacyFieldId] = useState(0);
    const [prescriptionFieldId, setPrescriptionFieldId] = useState(0);
    const [providerFieldId, setProviderFieldId] = useState(0);



    // eventBus.on("entity.access", (data) =>
    //     loadData()
    // );


    useEffect(()=> {
        loadData();

    }, [user]);


    useEffect(()=> {
        getDepartmentLookup({
            variables: {id: 'department_staging',},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                let data = result.data.groupLookupValues.map( (row) => {return {text:row.value, value:row.id}})
                setDeptFieldMappings(data)
            }).catch((error) => {
            NotificationManager.error("Api Error, Please try again!");
        });

        getDrugLookup({
            variables: {id: 'drug_staging',},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                let data = result.data.groupLookupValues.map( (row) => {return {text:row.value, value:row.id}})
                setDrugFieldMappings(data)
            }).catch((error) => {
            NotificationManager.error("Api Error, Please try again!");
        });

        getInsuranceLookup({variables: {id: 'insurance_staging',}, context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                let data = result.data.groupLookupValues.map( (row) => {return {text:row.value, value:row.id}})
                setInsuranceFieldMappings(data)
            }).catch((error) => {
            NotificationManager.error("Api Error, Please try again!");
        });

        getPatientLookup({
            variables: {id: 'patient_staging',},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                let data = result.data.groupLookupValues.map( (row) => {return {text:row.value, value:row.id}})
                setPatientFieldMappings(data)
            }).catch((error) => {
            NotificationManager.error("Api Error, Please try again!");
        });

        getPharmacyLookup({
            variables: {id: 'pharmacy_staging',},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                let data = result.data.groupLookupValues.map( (row) => {return {text:row.value, value:row.id}})
                setPharmacyFieldMappings(data)
            }).catch((error) => {
            NotificationManager.error("Api Error, Please try again!");
        });

        getPrescriptionLookup({
            variables: {id: 'prescription_staging',},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                let data = result.data.groupLookupValues.map( (row) => {return {text:row.value, value:row.id}})
                setPrescriptionFieldMappings(data)
            }).catch((error) => {
            NotificationManager.error("Api Error, Please try again!");
        });

        getProviderLookup({
            variables: {id: 'provider_staging',},
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                let data = result.data.groupLookupValues.map( (row) => {return {text:row.value, value:row.id}})
                setProviderFieldMappings(data)
            }).catch((error) => {
            NotificationManager.error("Api Error, Please try again!");
        });

    }, [user]);

    function loadData()
    {
        getFieldMapping({ variables:{id: Number(params.entity_id)}, context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                setFieldMapping(result.data.fieldMappings);
            })
            .catch((error) => {NotificationManager.error("Api Error, Please try again!");});
    }


    function removeCustomColumnMappingConfirmation(id, column)
    {
        setFieldDeleteStage({id: id, column: column});
        setShowDeleteConfirmation(true);
    }
    function removeCustomColumnMapping()
    {

        apiRemoveColumnMapping({ variables: {
                entityId: Number(params.entity_id),
                lookupId: FieldDeleteStage.id,
                customField:FieldDeleteStage.column
            }})
            .then( (result) => {
                NotificationManager.success("Success", 'Removed Mapping');
                loadData();
            })
            .catch((error) => {
                NotificationManager.error("Error! Please try again");
            })
    }

    function addCustomColumnMapping(id, column)
    {

        apiAddColumnMapping({ variables: {
                entityId: Number(params.entity_id),
                lookupId: id,
                customField:column
            }})
            .then( (result) => {
                NotificationManager.success("Success", 'Added Mapping');
                loadData();
            })
            .catch((error) => {
                NotificationManager.error("Error! Please try again");
            })
    }


    //if(loading || pageLoading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);
    return (

        <div >
            <MDBRow>
                <MDBCol>
                    <MDBAccordion alwaysOpen initialActive={1} >
                        <MDBAccordionItem  collapseId={1} headerTitle='Field Mapping'>
                            Here you can provide a list of additional column names that will map to the internal schemas of data. This is useful
                            when a client does not conform to the prescribed data column names.
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={2} headerTitle='Department'>
                            <table className="table table-sm" style={{marginBottom:'100px'}}>
                                <thead>
                                <tr>
                                    <td>Field</td>
                                    <td colSpan={2}>Custom Name</td>
                                </tr>
                                </thead>
                                <tbody>
                                {fieldMappings.filter(x=>deptFieldMappings.some(item => item.value === x.field_lookup_id) ).map( (row,id) => {
                                    // return(<tr>{JSON.stringify(row)}</tr>)
                                    // console.log(fieldMappings, row)
                                    let lookup = deptFieldMappings.find( (element) => element.value === row.field_lookup_id)
                                    return(
                                        <tr key={id}>
                                            <td>{lookup?.text ?? "Unknown"}</td>
                                            <td colSpan={1}>{row?.custom_field}</td>
                                            <td className="text-end"><MDBBtn size={'sm'} color={'danger'} onClick={() => removeCustomColumnMappingConfirmation(row.field_lookup_id, row.custom_field)}><MDBIcon fas icon="trash" /></MDBBtn></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        <MDBSelect  data={deptFieldMappings} onValueChange={(e) => {if(e) setDepartmentFieldId(e.value)}}/>
                                    </td>
                                    <td>
                                        <MDBInput label='Custom Column Name'
                                                  id='dept_custom_column_new_name'
                                                  type='text'
                                                  wrapperClass={"w-75"}
                                                  value={newDeptCustomName}
                                                  onChange={(event => {
                                                       setNewDeptCustomName(event.target.value);
                                                  })}
                                        />
                                    </td>
                                    <td colSpan={1}><MDBBtn size={"sm"} onClick={() => {addCustomColumnMapping(departmentFieldId, newDeptCustomName); setNewDeptCustomName("") }}  color={'secondary'}>Add</MDBBtn></td>
                                </tr>
                                </tbody>
                            </table>

                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={3} headerTitle='Drug'>
                            <table className="table table-sm" style={{marginBottom:'100px'}}>
                                <thead>
                                <tr>
                                    <td>Field</td>
                                    <td colSpan={2}>Custom Name</td>
                                </tr>
                                </thead>
                                <tbody>
                                {fieldMappings.filter(x=>drugFieldMappings.some(item => item.value === x.field_lookup_id) ).map( (row,id) => {
                                    let lookup = drugFieldMappings.find( (element) => element.value === row.field_lookup_id)
                                    return(
                                        <tr key={id}>
                                            <td>{lookup?.text ?? "Unknown"}</td>
                                            <td colSpan={1}>{row?.custom_field}</td>
                                            <td className="text-end"><MDBBtn size={'sm'} color={'danger'} onClick={() => removeCustomColumnMappingConfirmation(row.field_lookup_id, row.custom_field)}><MDBIcon fas icon="trash" /></MDBBtn></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        <MDBSelect data={drugFieldMappings} onValueChange={(e) => {if(e) setDrugFieldId(e.value)}}/>
                                    </td>
                                    <td>
                                        <MDBInput label='Custom Column Name'
                                                  id='drug_custom_name'
                                                  type='text'
                                                  wrapperClass={"w-75"}
                                                  value={newDrugCustomName}
                                                  onChange={(event => {setNewDrugCustomName(event.target.value);})}
                                        />
                                    </td>
                                    <td colSpan={1}><MDBBtn size={"sm"} onClick={() => {addCustomColumnMapping(drugFieldId, newDrugCustomName); setNewDrugCustomName("") }}  color={'secondary'}>Add</MDBBtn></td>
                                </tr>
                                </tbody>
                            </table>

                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={4} headerTitle='Insurance'>
                            <table className="table table-sm" style={{marginBottom:'100px'}}>
                                <thead>
                                <tr>
                                    <td>Field</td>
                                    <td colSpan={2}>Custom Name</td>
                                </tr>
                                </thead>
                                <tbody>
                                {fieldMappings.filter(x=>insuranceFieldMappings.some(item => item.value === x.field_lookup_id) ).map( (row,id) => {
                                    // return(<tr>{JSON.stringify(row)}</tr>)
                                    // console.log(fieldMappings, row)
                                    let lookup = insuranceFieldMappings.find( (element) => element.value === row.field_lookup_id)
                                    return(
                                        <tr key={id}>
                                            <td>{lookup?.text ?? "Unknown"}</td>
                                            <td colSpan={1}>{row?.custom_field}</td>
                                            <td className="text-end"><MDBBtn size={'sm'} color={'danger'} onClick={() => removeCustomColumnMappingConfirmation(row.field_lookup_id, row.custom_field)}><MDBIcon fas icon="trash" /></MDBBtn></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        <MDBSelect data={insuranceFieldMappings} onValueChange={(e) => {if(e) setInsuranceFieldId(e.value)}}/>
                                    </td>
                                    <td>
                                        <MDBInput label='Custom Column Name'
                                                  id='insurance_custom_name'
                                                  type='text'
                                                  wrapperClass={"w-75"}
                                                  value={newInsuranceCustomName}
                                                  onChange={(event => {setNewInsuranceCustomName(event.target.value);})}
                                        />
                                    </td>
                                    <td colSpan={1}><MDBBtn size={"sm"} onClick={() => {addCustomColumnMapping(insuranceFieldId, newInsuranceCustomName); setNewInsuranceCustomName("") }}  color={'secondary'}>Add</MDBBtn></td>
                                </tr>
                                </tbody>
                            </table>

                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={5} headerTitle='Patient'>
                            <table className="table table-sm" style={{marginBottom:'100px'}}>
                                <thead>
                                <tr>
                                    <td>Field</td>
                                    <td colSpan={2}>Custom Name</td>
                                </tr>
                                </thead>
                                <tbody>
                                {fieldMappings.filter(x=>patientFieldMappings.some(item => item.value === x.field_lookup_id) ).map( (row,id) => {
                                    // return(<tr>{JSON.stringify(row)}</tr>)
                                    // console.log(fieldMappings, row)
                                    let lookup = patientFieldMappings.find( (element) => element.value === row.field_lookup_id)
                                    return(
                                        <tr key={id}>
                                            <td>{lookup?.text ?? "Unknown"}</td>
                                            <td colSpan={1}>{row?.custom_field}</td>
                                            <td className="text-end"><MDBBtn size={'sm'} color={'danger'} onClick={() => removeCustomColumnMappingConfirmation(row.field_lookup_id, row.custom_field)}><MDBIcon fas icon="trash" /></MDBBtn></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        <MDBSelect data={patientFieldMappings} onValueChange={(e) => {if(e) setPatientFieldId(e.value)}}/>
                                    </td>
                                    <td>
                                        <MDBInput label='Custom Column Name'
                                                  id='insurance_custom_name'
                                                  type='text'
                                                  wrapperClass={"w-75"}
                                                  value={newPatientCustomName}
                                                  onChange={(event => {setNewPatientCustomName(event.target.value);})}
                                        />
                                    </td>
                                    <td colSpan={1}><MDBBtn size={"sm"} onClick={() => {addCustomColumnMapping(patientFieldId, newPatientCustomName); setNewPatientCustomName("") }}  color={'secondary'}>Add</MDBBtn></td>
                                </tr>
                                </tbody>
                            </table>

                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={6} headerTitle='Pharmacy'>
                            <table className="table table-sm" style={{marginBottom:'100px'}}>
                                <thead>
                                <tr>
                                    <td>Field</td>
                                    <td colSpan={2}>Custom Name</td>
                                </tr>
                                </thead>
                                <tbody>
                                {fieldMappings.filter(x=>pharmacyFieldMappings.some(item => item.value === x.field_lookup_id) ).map( (row,id) => {
                                    let lookup = pharmacyFieldMappings.find( (element) => element.value === row.field_lookup_id)
                                    return(
                                        <tr key={id}>
                                            <td>{lookup?.text ?? "Unknown"}</td>
                                            <td colSpan={1}>{row?.custom_field}</td>
                                            <td className="text-end"><MDBBtn size={'sm'} color={'danger'} onClick={() => removeCustomColumnMappingConfirmation(row.field_lookup_id, row.custom_field)}><MDBIcon fas icon="trash" /></MDBBtn></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        <MDBSelect onValueChange={(e) => {if(e) setPharmacyFieldId(e.value)}} data={pharmacyFieldMappings}  />
                                    </td>
                                    <td>
                                        <MDBInput label='Custom Column Name'
                                                  id='dept_custom_name'
                                                  type='text'
                                                  wrapperClass={"w-75"}
                                                  value={newPharmacyCustomName}
                                                  onChange={(event => {setNewPharmacyCustomName(event.target.value);})}
                                        />
                                    </td>
                                    <td colSpan={1}><MDBBtn size={"sm"} onClick={() => {addCustomColumnMapping(pharmacyFieldId, newPharmacyCustomName); setNewPharmacyCustomName("") }}  color={'secondary'}>Add</MDBBtn></td>
                                </tr>
                                </tbody>
                            </table>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={7} headerTitle='Prescription'>
                            <table className="table table-sm" style={{marginBottom:'100px'}}>
                                <thead>
                                <tr>
                                    <td>Field</td>
                                    <td colSpan={2}>Custom Name</td>
                                </tr>
                                </thead>
                                <tbody>
                                {fieldMappings.filter(x=>prescriptionFieldMappings.some(item => item.value === x.field_lookup_id) ).map( (row,id) => {
                                    // return(<tr>{JSON.stringify(row)}</tr>)
                                    // console.log(fieldMappings, row)
                                    let lookup = prescriptionFieldMappings.find( (element) => element.value === row.field_lookup_id)
                                    return(
                                        <tr key={id}>
                                            <td>{lookup?.text ?? "Unknown"}</td>
                                            <td colSpan={1}>{row?.custom_field}</td>
                                            <td className="text-end"><MDBBtn size={'sm'} color={'danger'} onClick={() => removeCustomColumnMappingConfirmation(row.field_lookup_id, row.custom_field)}><MDBIcon fas icon="trash" /></MDBBtn></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        <MDBSelect data={prescriptionFieldMappings} onValueChange={(e) => {if(e) setPrescriptionFieldId(e.value)}}/>
                                    </td>
                                    <td>
                                        <MDBInput label='Custom Column Name'
                                                  id='prescription_custom_name'
                                                  type='text'
                                                  wrapperClass={"w-75"}
                                                  value={newPrescriptionCustomName}
                                                  onChange={(event => {setNewPrescriptionCustomName(event.target.value);})}
                                        />
                                    </td>
                                    <td colSpan={1}><MDBBtn size={"sm"} onClick={() => {addCustomColumnMapping(prescriptionFieldId, newPrescriptionCustomName); setNewPrescriptionCustomName("") }}  color={'secondary'}>Add</MDBBtn></td>
                                </tr>
                                </tbody>
                            </table>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId={8} headerTitle='Provider'>
                            <table className="table table-sm" style={{marginBottom:'100px'}}>
                                <thead>
                                <tr>
                                    <td>Field</td>
                                    <td colSpan={2}>Custom Name</td>
                                </tr>
                                </thead>
                                <tbody>
                                {fieldMappings.filter(x=>providerFieldMappings.some(item => item.value === x.field_lookup_id) ).map( (row,id) => {
                                    // return(<tr>{JSON.stringify(row)}</tr>)
                                    // console.log(fieldMappings, row)
                                    let lookup = providerFieldMappings.find( (element) => element.value === row.field_lookup_id)
                                    return(
                                        <tr key={id}>
                                            <td>{lookup?.text ?? "Unknown"}</td>
                                            <td colSpan={1}>{row?.custom_field}</td>
                                            <td className="text-end"><MDBBtn size={'sm'} color={'danger'} onClick={() => removeCustomColumnMappingConfirmation(row.field_lookup_id, row.custom_field)}><MDBIcon fas icon="trash" /></MDBBtn></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>
                                        <MDBSelect  data={providerFieldMappings} onValueChange={(e) => {if(e) setProviderFieldId(e.value)}}/>
                                    </td>
                                    <td>
                                        <MDBInput label='Custom Column Name'
                                                  id='provider_custom_name'
                                                  type='text'
                                                  wrapperClass={"w-75"}
                                                  value={newProviderCustomName}
                                                  onChange={(event => {setNewProviderCustomName(event.target.value);})}
                                        />
                                    </td>
                                    <td colSpan={1}><MDBBtn size={"sm"} onClick={() => {addCustomColumnMapping(providerFieldId, newProviderCustomName); setNewProviderCustomName("") }}  color={'secondary'}>Add</MDBBtn></td>
                                </tr>
                                </tbody>
                            </table>

                        </MDBAccordionItem>
                    </MDBAccordion>
                </MDBCol>
            </MDBRow>



            <MDBModal tabIndex='-1' open={showDeleteConfirmation} onClose={()=>setShowDeleteConfirmation(false)}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Delete Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={()=> setShowDeleteConfirmation(false)}/>
                        </MDBModalHeader>
                        <MDBModalBody >
                            Are you sure you want to delete '{FieldDeleteStage.column}' from the custom field mapper?



                        </MDBModalBody>

                        <MDBModalFooter>

                            <MDBBtn color={"danger"} onClick={() => {
                                removeCustomColumnMapping();
                                setShowDeleteConfirmation(false)
                            }}>Delete</MDBBtn>
                            <MDBBtn color='primary' onClick={()=>{setShowDeleteConfirmation(false)}}>Cancel</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>




        </div>
    );
}

const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
